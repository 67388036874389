import { runInAction } from 'mobx'
import BaseStore from './BaseStore'

import { error, storage, http, timer } from 'utils'
import { config } from 'config'

let state
export class Consent extends BaseStore {
  constructor() {
    super()
    this.observable({
      cookie: {},
      privacy: {},
      terms: {},
      howToOrder: {},
      contactUs: {},
      current: {
        cookie: {},
      },
    })

    state = this
  }

  async getConsent(consent_type) {
    const url = `${config.api}/v1/consent/${consent_type}`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return {}

    return resp.body.data
  }

  async getCookie() {
    if (this.toJS().cookie.consent_id) {
      return
    }

    const data = await this.getConsent('cookie')

    runInAction(() => {
      state.cookie = data
    })
  }

  async getPrivacy() {
    if (this.toJS().privacy.consent_id) {
      return
    }

    const data = await this.getConsent('privacy')

    runInAction(() => {
      state.privacy = data
    })
  }

  async getTerms() {
    if (this.toJS().terms.consent_id) {
      return
    }

    const data = await this.getConsent('terms')

    runInAction(() => {
      state.terms = data
    })
  }

  async getContactUs() {
    if (this.toJS().contactUs.consent_id) {
      return
    }

    const data = await this.getConsent('contact-us')

    runInAction(() => {
      state.contactUs = data
    })
  }

  async getHowToOrder() {
    if (this.toJS().howToOrder.consent_id) {
      return
    }

    const data = await this.getConsent('how-to-order')

    runInAction(() => {
      state.howToOrder = data
    })
  }

  async loadCookie() {
    const data = storage.load('cookie') || {}
    const { consent_id, status } = data

    let updated_at
    if (status === 'close' && data.updated_at) {
      const now = timer.get()
      updated_at = timer.get(data.updated_at)

      const days = now.diff(updated_at, 'days')
      if (days >= 1) {
        return
      }
    }

    runInAction(() => {
      state.current.cookie = { consent_id, status }
    })
  }

  async acceptCookie(consent_id = 0, status) {
    const updated_at = timer.get().toISOString()
    const data = { consent_id, status, updated_at }
    await storage.save('cookie', data)

    runInAction(() => {
      state.current.cookie = data
    })
  }
}

export default new Consent()
