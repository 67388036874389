const config = {
  mode: 'testing',
  firebase: {
    apiKey: "AIzaSyBkNojpuI0vTEd8UzBN0nniyTrxdtS5ofs",
    authDomain: "test.prathan.co.th",
    projectId: "prathan-web-test",
    storageBucket: "prathan-web-test.appspot.com",
    messagingSenderId: "270872665040",
    appId: "1:270872665040:web:20c37a3664940b050b37d5",
    measurementId: "G-0NT72K7RW4",
  },
  api: 'https://test-api.prathan.co.th/api',
  host: 'https://prathan-web-test.web.app/',
  version: '1.0',
}

module.exports = config
