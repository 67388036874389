import { DownloadOutlined, FileImageOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useNavigate } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message, ga } from 'utils'
import { Btn } from 'components/button'
import { ConfirmDialog } from 'dialog'

import Header  from './DownloadFile/pdf/Header'
import Footer  from './DownloadFile/pdf/Footer'
import Body    from './DownloadFile/pdf/Body'
import DownloadBtn from './DownloadBtn'

const HeaderOrder = (props) => {
  const [loading, setLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [loadingImg, setLoadingImg] = useState(false)

  const navigate = useNavigate()

  const order = props.order.getHistoryOrder()

  const onHistory = () => navigate('/user/order')
  const onClose = () => setIsConfirm(false)
  const confirmReorder = () => {
    const current = props.order.getCurrentOrder()

    const len = current.product_list.length
    if (len > 0) {
      setIsConfirm(true)
    } else {
      reOrder()
    }
  }

  const reOrder = async () => {
    try {
      onClose()
      setLoading(true)
      await props.order.reOrder()
      ga.click('create reorder')
      navigate('/order')
    } catch (e) {
      message.error({
        title: 'สร้าง คำสั่งซื้ออีกครั้ง',
        message: e.message,
      })
    }
    setLoading(false)
  }

  const openInNewTab = (url) => {
    setTimeout(() => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }, 500)
  }

  const getParams = (size) => {
    const product_list = order.product_list || []
    let height = 460 + product_list.length * 34
    const params = {
      headerTemplate: renderToString(<Header order={order} size={size} />),
      footerTemplate: renderToString(<Footer />),
      margin: { top: 240, bottom: 60 },
      width: 1440,
      height,
      html: renderToString(<Body order={order} />),
      code: order.order_code,
    }

    return params
  }
  const onDownloadPdf = async () => {
    try {
      setLoadingPdf(true)
      const params = getParams()

      const { pdf_url } = await props.order.generatePdf({ code: order.order_code, json: params })
      ga.click('download order pdf file')
      if (pdf_url === undefined || pdf_url === '') {
        message.error({ title: 'สร้างรายงานไม่สำเร็จ', message: message })
      } else {
        openInNewTab(pdf_url)
      }
    } catch (e) {
      message.error({
        title: 'Download คำสั่งซื้อ',
        message: e.message,
      })
    }
    setLoadingPdf(false)
  }

  const onDownloadImg = async () => {
    try {
      setLoadingImg(true)
      const params = getParams('xl')

      const { image_url } = await props.order.generateImage({ code: order.order_code, json: params })
      ga.click('download order image file')
      if (image_url === undefined || image_url === '') {
        message.error({ title: 'สร้างรายงานรูปไม่สำเร็จ', message: message })
      } else {
        openInNewTab(image_url)
      }
    } catch (e) {
      message.error({
        title: 'Download คำสั่งซื้อ',
        message: e.message,
      })
    }
    setLoadingImg(false)
  }

  return (
    <PageView>
      <Button>
        <Btn text="ประวัติการสั่งซื้อ" onClick={onHistory} />
        <Btn loading={loading} text="สั่งรายการนี้อีกครั้ง" onClick={confirmReorder} />
        <DownloadBtn
          loading={loadingPdf}
          icon={<DownloadOutlined />}
          text="ดาวน์โหลด ใบเสนอราคา PDF"
          onClick={onDownloadPdf} />
        <DownloadBtn
          loading={loadingImg}
          icon={<FileImageOutlined />}
          text="ดาวน์โหลด ใบเสนอราคา รูปภาพ"
          onClick={onDownloadImg} />
      </Button>
      <ConfirmDialog
        visible={isConfirm}
        title="หากคุณต้องการสั่งสินค้าใหม่จากรายการนี้"
        message="รายการเดิมในตะกร้าตอนนี้จะถูกลบออกทั้งหมด ดำเนินการต่อหรือไม่ ?"
        onClose={onClose}
        onConfirm={reOrder} />
    </PageView>
  )
}


const PageView = styled.div`
  margin-bottom: 16px;
`

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;

  ${(p) => p.theme.media.mobile} {
    flex-direction: column;
  }
`


export default inject('order')(observer(HeaderOrder))
