const Footer = () => {

  return (
    <div
      className='footer-pdf'
      style={{
        fontFamily: 'Helvetica Neue, Helvetica, Arial, Verdana, sans-serif',
        fontSize: '11px',
        color: '#d3d3d3',
        margin: '0 30px 0 30px',
        display: 'block',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <center>
        <span className='pageNumber' /> of <span className='totalPages' />
      </center>
    </div>
  )
}

export default Footer
