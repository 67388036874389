import styled from 'styled-components'

const DetailItem = (props) => {
  const { name, value, color } = props

  return (
    <Text className={color}>
      <Label>{name}</Label>
      <Value>{value}</Value>
    </Text>
  )
}

const Text = styled.div`
  padding: 6px 16px;
  border-radius: 2px;
  display: flex;
  width: 90%;
  background-color: transparent;

  &.white {
    background-color: white;
  }
`

const Label = styled.div`
  margin-right: 6px;
  color: #454545;
  width: 40%;
`

const Value = styled.div`
  margin-right: 16px;
  color: black;
`

export default DetailItem
