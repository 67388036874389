import { useState } from 'react'
import styled from 'styled-components'

import { KeyBtn } from 'components/button'
import { Checker } from 'components/input'
import EditUser from 'pages/user/information/user/EditUser'
import { helper } from 'utils'

const EditUserInfo = (props) => {
  const [isAccept, setIsAccept] = useState(false)
  const [validate, setValidate] = useState({})
  const [isInvalidate, setIsInvalidate] = useState(false)
  const { loading, doc = {}, onNext, onTerms, onPrivacy, onChange } = props

  const onAccept = (val) => setIsAccept(val)
  const onOk = () => {
    const valid_list = [
      'name',
      'surname',
      'mobile',
    ]

    const { invalid, isInvalid } = helper.checkValue(doc, valid_list)
    if (isInvalid) {
      setValidate(invalid)
      setIsInvalidate(!isInvalid)
      return
    }

    onNext()
  }

  return (
    <PageView>
      <EditUser doc={doc} validate={validate} onChange={onChange} />

      <Space />
      <Consent>
        <Checker label="ยอมรับ" value={isAccept} onChange={onAccept} />
        <ConsentBtn onClick={onTerms}>
          เงื่อนไขและข้อกำหนด
        </ConsentBtn>
        และ
        <ConsentBtn onClick={onPrivacy}>
          นโยบายความเป็นส่วนตัว
        </ConsentBtn>
      </Consent>

      <Button>
        <KeyBtn
          text="ดำเนิดการต่อ"
          loading={loading}
          disabled={!isAccept || isInvalidate}
          onClick={onOk} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
`

const Space = styled.div`
  padding-top: 24px;
`

const Consent = styled.div`
  padding-top: 12px;
  display: flex;
  justify-content: center;
`

const Button = styled.div`
  width: 100%;
  padding-top: 24px;
  max-width: 214px;
  margin: 0 auto;
`

const ConsentBtn = styled.div`
  padding: 0px 12px;
  color: ${(p) => p.theme.color.green};
  cursor: pointer;
`

export default EditUserInfo
