import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Input, InputPassword } from 'components/input'
import { KeyBtn, GoogleBtn } from 'components/button'

const ContentView = (props) => {
  const {
    email,
    password,
    validate = {},
    loading = {},
    btn,
    onChange,
    onEmail,
    onGmail,
  } = props

  const disabled = loading.email || loading.google

  return (
    <PageView>
      <Row>
        <Col { ...col.email}>
          <InputSpace>
            <Input
              label="ระบุ อีเมล"
              name="email"
              value={email}
              disabled={disabled}
              onChange={onChange}
              invalid={validate.email} />
          </InputSpace>
        </Col>

        <Col { ...col.password}>
          <InputSpace>
            <InputPassword
              label="ระบุ รหัสผ่าน"
              name="password"
              value={password}
              disabled={disabled}
              onChange={onChange}
              invalid={validate.password} />
          </InputSpace>
        </Col>

        <Col { ...col.btn_email} >
          <Button>
            <KeyBtn text={btn} loading={loading.email} onClick={onEmail} />
            <Note>หรือ{btn}ด้วยโซเชียล</Note>
          </Button>
        </Col>

        <Col { ...col.btn_google} >
          <Button>
            <GoogleBtn text={`Google`} loading={loading.google} onClick={onGmail} />
          </Button>
        </Col>
      </Row>
    </PageView>
  )
}

const col = {
  email: {
    lg: { span: 15, order: 1 },
    md: { span: 15, order: 1 },
    xs: { span: 24, order: 1 },
  },
  password: {
    lg: { span: 15, order: 3 },
    md: { span: 15, order: 3 },
    xs: { span: 24, order: 2 },
  },
  btn_email: {
    lg: { span: 9, order: 2 },
    md: { span: 9, order: 2 },
    xs: { span: 24, order: 3 },
  },
  btn_google: {
    lg: { span: 9, order: 4 },
    md: { span: 9, order: 4 },
    xs: { span: 24, order: 4 },
  },
}
const PageView = styled.div``

const InputSpace = styled.div`
  ${(p) => p.theme.media.mobile} {
    padding-bottom: 16px;
  }
`

const Button = styled.div`
  padding-left: 16px;

  ${(p) => p.theme.media.mobile} {
    padding-left: 0px;
  }
`

const Note = styled.div`
  padding: 8px 0px;
  font-size: 16px;
`

export default ContentView
