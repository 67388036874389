import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Link } from 'components/link'

const Menu = (props) => {
  const { selected } = props

  const { user } = props.member.toJS()

  const content = menu.map((it, i) => {
    const { name, value, link } = it
    const css = value === selected ? 'selected' : ''
    return (
      <Link to={link} key={i}>
        <Text className={css}>{name}</Text>
      </Link>
    )
  })

  const text = `${user.name} ${user.surname}`
  return (
    <PageView>
      <Title>
        {text}
      </Title>
      <ContentMenu>
        {content}
      </ContentMenu>
    </PageView>
  )
}

const menu = [
  { name: 'ข้อมูลผู้ใช้ระบบ', value: 'information', link: '/user/information' },
  { name: 'สมุดที่อยู่', value: 'address', link: '/user/address' },
  { name: 'บัญชีของฉัน', value: 'account', link: '/user/account' },
  { name: 'ประวัติสั่งซื้อ', value: 'order', link: '/user/order' },
]

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
`

const ContentMenu = styled.div`
  padding: 10px 0px;
`

const Text = styled.div`
  padding: 10px 0px;

  &.selected {
    font-weight: 700;
    color: ${(p) => p.theme.color.green};
  }
`

export default inject('member')(observer(Menu))
