import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'antd'

import { Meta } from 'components/display'
import Loading from 'components/loading/Loading'
import BreadcrumbView from 'pages/Breadcrumb'
import { message } from 'utils'

import ProductImg from './ProductImg'
import Detail from './Detail'
import Specification from './specification'
import HistoryList from './history'
import Suggestion from './suggestion'
import AddCart from './AddCart'

const Product = (props) => {
  const [loading, setLoading] = useState(true)
  const [categoryList, setCategoryList] = useState([])

  const { code, brand } = useParams()

  const { doc = {}, relate = {} } = props.product.toJS()
  const cateStore = props.category.toJS()

  const onGetDoc = useCallback(async (c) => {
    try {
      setLoading(true)

      await Promise.all([
        props.product.getProduct({ code: c }),
        props.product.getRelateProduct({ code: c }),
      ])
    } catch (e) {
      if (e.message !== 'product not found') {
        message.error({
          title: 'แสดงสินค้า',
          message: e.message,
        })
      }
    }

    setLoading(false)
  }, [props.product])

  useEffect(() => {
    onGetDoc(code)
  }, [onGetDoc, code])

  useEffect(() => {
    const {
      all_list = [],
    } = props.category.toJS()

    const find_category = props.category.findCategoryList(doc.category_code, all_list)

    const { original_list = [] } = find_category
    setCategoryList(original_list)
  }, [props.category, doc.category_code, cateStore.loading])

  const isBrand = brand !== undefined
  const breadcrumb = isBrand
  ? [
    { name: doc.brand_name, code: doc.brand_code },
    { name: doc.category_name, code: doc.category_code },
  ]
  : categoryList

  if (!doc.product_id) {
    return (
      <PageView>
        <NotFound>
          <i className="far fa-paper-plane" />
          <div>ไม่พบรายการสินค้า</div>
        </NotFound>
      </PageView>
    )
  }

  return (
    <PageView>
      <Meta loading={loading} title={doc.name} description={doc.category_name} />
      <BreadcrumbView
        category_list={breadcrumb}
        product={doc}
        by_brand={isBrand} />

      <Loading loading={ loading || cateStore.loading } />
      <Line />
      <ProductName>{ doc.name }</ProductName>
      <Row>
        <Col { ...col.content }>
          <Row>
            <Col { ...col.img }>
              <ProductImg product={doc} />
            </Col>
            <Col { ...col.detail }>
              <MobileCart>
                <AddCart doc={doc} />
              </MobileCart>
              <Detail product={doc} />
            </Col>
          </Row>

          <Specification product={doc} />
          <Suggestion data={relate} />
        </Col>

        <Col { ...col.cart }>
          <PcCart>
            <AddCart doc={doc} />
          </PcCart>
          <HistoryList product_id={doc.product_id} />
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
  color: #333333;
`

const Line = styled.div`
  padding: 5px 0px;
`

const ProductName = styled.div`
  padding: 5px 0px;
  font-size: 20px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const NotFound = styled.div`
  padding-top: 45px;
  text-align: center;
  font-size: 20px;

  i {
    font-size: 35px;
    margin-bottom: 24px;
  }
`

const MobileCart = styled.div`
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    padding-top: 24px;
  }
`

const PcCart = styled.div`
  display: block;

  @media screen and (max-width: 500px) {
    display: none;
  }
`

const col = {
  content: {
    xl: 19, lg: 18, md: 16, xs: 24,
  },

  img: {
    xl: 8, lg: 8, md: 8, xs: 24,
  },

  detail: {
    xl: 16, lg: 16, md: 16, xs: 24,
  },

  cart: {
    xl: 5, lg: 6, md: 8, xs: 24,
  },
}

export default inject('brand', 'category', 'product')(observer(Product))
