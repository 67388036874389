import styled from 'styled-components'

import {
  color,
} from 'definition'

import { Check } from 'icon'
import Loading from 'components/loading/Loading'

const FilterBlock = (props) => {
  const {
    title = '',
    text = '',
    list = [],
    tag,
    loading = false,
    onSelect = () => {},
  } = props

  const filter_list = text !== ''
  ? list.filter(it => {
    const { item = {} } = it
    const name = item.name || ''
    return name.toLowerCase().includes(text)
  })
  : list

  const content = filter_list.map((it, i) => {
    const { item = {}, counter = 0, selected } = it

    return (
      <Item className={selected ? 'active' : ''} key={i} onClick={() => onSelect(item, tag)}>
        <Check color={selected ? undefined : 'white'} />

        <label>
          {item.name || '-'}
        </label>
        <span>
          ({counter})
        </span>
      </Item>
    )
  })

  const selected = list.filter(it => it.selected).length

  return (
    <PageView>
      <Title>
        {title} {selected > 0 ? ` (${selected})` : ''}
      </Title>
      <Loading loading={loading} />

      {content}
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px;
  min-height: 180px;
  max-height: 280px;
  overflow: scroll;

  border: 1px solid ${color.border};
  border-top-width: 0px;
  background-color: white;
`

const Title = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
`

const Item = styled.div`
  padding: 4px 0px;
  cursor: pointer;

  label {
    margin: 0px 4px;
    cursor: pointer;
    font-size: 14px;
  }

  svg {
    border: 1px solid #AAAAAA;
    border-radius: 6px;
    position: relative;
    top: 6px;
  }

  &.active svg {
    border: 0px solid #AAAAAA;
  }
`

export default FilterBlock
