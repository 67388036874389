import React from 'react'
import styled from 'styled-components'

const LineBtn = (props) => {
  return (
    <PageView>
      {props.children}
    </PageView>
  )
}

const PageView = styled.div`
  span {
    margin-right: 4px;
  }
`

export default LineBtn
