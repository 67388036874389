import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { Quantity } from 'components/input'
import { Link } from 'components/link'
import { Delete, Minus } from 'icon'
import { format } from 'utils'
import { ConfirmDialog } from 'dialog'

import col from './col'

const Product = (props) => {
  const [isConfirm, setIsConfirm] = useState(false)

  const { item } = props
  const { product, quantity } = item

  const confirmClear = () => setIsConfirm(true)
  const onClose = () => setIsConfirm(false)
  const onRemove = () => {
    onClose()
    props.order.removeProduct(product, quantity)
  }

  const onChange = (val) => {
    props.order.setProduct(product, val)
  }

  const { img_url, name, product_code, barcode, discount_price, unit_type, discount } = product
  const link = `/product/${product_code}?name=${name}`
  const img = img_url ? <Link to={link}><img src={img_url} alt={name} /></Link> : undefined

  return (
    <PageView>
      <Row>
        <Col { ...col.name}>
          <Info>
            <Image>
              {img}
            </Image>
            <Detail>
              <Link to={link}>
                <Name>{name}</Name>
                <ProductCode>{barcode}</ProductCode>
              </Link>
            </Detail>
          </Info>
        </Col>

        <Col { ...col.quantity} position="center">
          <Quantity
            min={1}
            max={50000}
            value={quantity}
            onChange={onChange} />
        </Col>

        <Col { ...col.unit}>
          {unit_type}
        </Col>

        <Col { ...col.price}>
          <Space>
            {format.toMoney(discount_price)} บาท
          </Space>
        </Col>

        <Col { ...col.discount} position="center">
          <Space>
            {discount !== '' ? discount : <Minus />}
          </Space>
        </Col>

        <Col { ...col.total_price}>
          <Space>
            {format.toMoney(discount_price * quantity)} บาท
          </Space>
          <Btn onClick={confirmClear}>
            <Delete color="#6C6C6C" />
          </Btn>
        </Col>
      </Row>
      <ConfirmDialog
        visible={isConfirm}
        message="คุณต้องการที่จะลบสินค้ารายการนี้ออก ใช่หรือไม่?"
        onClose={onClose}
        onConfirm={onRemove} />
    </PageView>
  )
}

const PageView = styled.div`
  padding: 8px 0px;
  font-size: 14px;
`

const Info = styled.div`
  display: flex;
  column-gap: 8px;
`

const Image = styled.div`
  img {
    width: 50px;
  }
`
const Detail = styled.div``

const Btn = styled.div`
  cursor: pointer;
`

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
`

const ProductCode = styled.div`
  font-weight: 400;
  font-size: 14px;
`

const Space = styled.div`
  word-break: break-all;
  overflow-wrap: break-word;
`

export default inject('order')(observer(Product))
