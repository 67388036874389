import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Policy = (props) => {
  const navigate = useNavigate()

  const goTo = (url) => {
    const src = window.location.pathname
    const search = window.location.search || ''

    if (src !== url) {
      navigate(`${url}?source=${src}${search}`)
    }
  }

  const onTerms = () => goTo(`/consent/terms`)
  const onPrivacy = () => goTo(`/consent/privacy`)
  const onCookie = () => goTo(`/consent/cookie`)

  return (
    <Page>
      <Title>นโยบาย</Title>
      <Btn onClick={onTerms}>ข้อกำหนดและเงื่อนไข</Btn>
      <Btn onClick={onPrivacy}>นโยบายความเป็นส่วนตัว</Btn>
      <Btn onClick={onCookie}>นโยบายการใช้คุกกี้</Btn>
    </Page>
  )
}

const Page = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
`

const Btn = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
`

export default Policy
