if (typeof window === 'undefined') global.window = { location: { host: 'testhost' } }

const maps = {
  'prathan.co.th': 'production',
  'prathan-web.web.app': 'production',

  'prathan-web-test.web.app': 'production', // 'testing',
  'test.prathan.co.th': 'production', // 'testing',
}

const host = window.location.host
let found = maps[host]

const env = !!found ? found : 'production' // 'localhost'

export const config = require(`./config.${env}`)
