import { Popover } from 'antd'
import styled from 'styled-components'

import { Down } from 'icon'

const PopupMenu = (props) => {
  const {
    label,
    children,
    visible,
    onVisible,
    placement = 'bottom',
  } = props

  return (
    <Popover
      content={children}
      placement={placement}
      trigger="click"
      visible={visible}
      onVisibleChange={onVisible}
    >
      <Text>
        {label}
        <Down />
      </Text>
    </Popover>
  )
}

const Text = styled.div`
  color: white;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  svg {
    margin-left: 4px;
    margin-top: 2px;
  }
`

export default PopupMenu
