import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import logo from 'assets/PrathanA.png'
import { Link } from 'components/link'

import MobileMenu from './MobileMenu'
import AccountMenu from './AccountMenu'
import Cart from './Cart'

const HeaderMenu = (props) => {
  const isLogin = props.member.isLogin()

  const getMenu = () => {
    return (
      <Button>
        <AccountMenu />
      </Button>
    )
  }
  // <PopupMenu label="บัญชีของฉัน" />
  const menu = isLogin ? getMenu() : (
    <Button>
      <Text>
        <Link to="/register">สมัครสมาชิก</Link>
      </Text>
      <Btn>
        <Link to="/login">เข้าสู่ระบบ</Link>
      </Btn>
    </Button>
  )
  return (
    <MenuArea>
      <Content>
        <Row align="middle">
          <Col { ...col.logo}>
            <Link to="/">
              <LogoSection>
                <img src={logo} alt="ประธานการไฟฟ้า" />
              </LogoSection>
            </Link>
          </Col>

          <Col { ...col.menu }>
            <MobileMenu />
          </Col>

          <Col { ...col.order }>
            <Cart />
          </Col>

          <Col { ...col.register }>
            {menu}
          </Col>
        </Row>
      </Content>
    </MenuArea>
  )
}

const MenuArea = styled.div`
  color: white;
  background-color: ${(p) => p.theme.color.green};
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  margin: 0 auto;
  padding: 0px 24px;
`

const LogoSection = styled.div`
  margin-top: 2px;

  img {
    width: 160px;
    height: auto;
    margin: 0 auto;
  }

  ${(p) => p.theme.media.mobile} {
    text-align: center;
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 24px;
`

const Text = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Btn = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color.green};
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
`

const col = {
  logo: {
    lg: { span: 4, order: 1 },
    md: { span: 5, order: 1 },
    sm: { span: 14, order: 2 },
    xs: { span: 12, order: 2 },
  },

  menu: {
    lg: { span: 0, order: 2 },
    md: { span: 0, order: 2 },
    sm: { span: 4, order: 1 },
    xs: { span: 4, order: 1 },
  },

  order: {
    lg: { span: 0, order: 3 },
    md: { span: 0, order: 3 },
    sm: { span: 6, order: 3 },
    xs: { span: 8, order: 3 },
  },

  register: {
    lg: { span: 20, order: 2 },
    md: { span: 19, order: 2 },
    sm: { span: 0, order: 2 },
    xs: { span: 0, order: 2 },
  },
}

export default inject('member')(observer(HeaderMenu))
