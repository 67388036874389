import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Meta, HtmlContent } from 'components/display'
import Loading from 'components/loading/Loading'
import ContentView from 'pages/consent/ContentView'

const Terms = (props) => {
  const [loading, setLoading] = useState(false)

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.consent.getTerms()
    } catch (e) {
      console.log('e', e.message)
    }
    setLoading(false)
  }, [props.consent])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const data = props.consent.toJS().terms
  const { title } = data
  return (
    <PageView>
      <Meta loading={loading} title={title} />
      <Loading loading={loading} />
      <ContentView>
        <Title>{title}</Title>
        <HtmlContent content={data.content} />
      </ContentView>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
`

export default inject('consent')(observer(Terms))
