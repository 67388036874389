import styled from 'styled-components'

import { KeyBtn } from 'components/button'

import BaseDialog from './BaseDialog'

const Dialog = (props) => {
  const { visible, content = '', onClose } = props

  return (
    <BaseDialog isHeader={false} visible={visible} onClose={onClose}>
      <Content>
        <Body>
          {content}
        </Body>

        <Inner>
          <KeyBtn text="ตกลง" onClick={onClose} />
        </Inner>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 0px;

  ${(p) => p.theme.media.mobile_xs} {
    min-width: 240px;
  }
`

const Body = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`

const Inner = styled.div`
  padding-top: 10px;
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
`

export default Dialog
