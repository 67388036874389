import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

const Message = (props) => {
  const {
    size,
    icon,
    text,
  } = props
  let col

  if (size === 's') col = small
  else col = medium

  const icon_content = icon
  ? <Icon className={icon} />
  : undefined

  return (
    <Row>
      <Col xl={col.xl} lg={col.lg} md={col.md} sm={col.sm} xs={col.sm}>
        <Section>
          {icon_content}
          <Body>
            {text}
          </Body>
        </Section>
      </Col>
    </Row>
  )
}

const Section = styled.div`
  margin: 20px;
  padding: 40px;
  border-radius: 5px;
  background-color: white;

  @media screen and (max-width: 425px) {
    padding: 20px;
  }
`

const Body = styled.div`
  padding: 20px;
`

const Icon = styled.i`
  font-size: 32px;
  color: grey;
`

const small = {
  xl: { span: 8, offset: 8 },
  lg: { span: 12, offset: 6 },
  md: { span: 12, offset: 6 },
  sm: { span: 24, offset: 0 },
}

const medium = {
  xl: { span: 16, offset: 4 },
  lg: { span: 16, offset: 4 },
  md: { span: 20, offset: 2 },
  sm: { span: 24, offset: 0 },
}

export default Message
