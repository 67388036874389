import React from 'react'
import styled from 'styled-components'

const Loading = (props) => {
  const {
    loading = false,
    size = 50,
    children,
  } = props

  if (loading === false) return children || <div />

  const style = {
    width: `${size}px`,
    height: `${size}px`,
  }
  return size < 30 ? <Icon2 style={style} /> : <Icon6 style={style} />
}

const Original = styled.div`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-top: 10px;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const Icon2 = styled(Original)`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0B4F04;
`

const Icon6 = styled(Original)`
  border: 6px solid #f3f3f3;
  border-top: 6px solid #0B4F04;
`

export default Loading
