import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'

import { KeyBtn } from 'components/button'
import { MessageBox } from 'components/display'

const CheckEmail = (props) => {
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const { email } = props.member.toJS()

  const onResend = async () => {
    try {
      setErr(undefined)
      setLoading(true)

      await props.member.resendRegister(email)
    } catch (e) {
      setErr(e.message)
    }

    setLoading(false)
  }

  const onHome = () => navigate('/')

  const err_text = err ? (
    <ErrText>{err}</ErrText>
  ) : undefined

  const content = email === ''
  ? (
    <Text>
      <h3>
        ไม่พบรายการของท่าน
      </h3>
    </Text>
  ) : (
    <>
      <Text>
        <h3>
          ระบบได้ทำการส่งลิ้ง เพื่อตั้งรหัสผ่านไปยังอีเมลของท่านแล้ว
          <br/>
          กรุณาตรวจสอบกล่องข้อความ หรือ Junk mail เพื่อดำเนินการต่อ
        </h3>
        <span>
          {email}
        </span>
        {err_text}
      </Text>

      <Row>
        <Col { ...col.btn }>
          <KeyBtn
            text={'ส่ง email อีกครั้ง'}
            loading={loading}
            onClick={onResend} />
        </Col>
      </Row>
    </>
  )

  return (
    <MessageBox size="m">
      {content}

      <Link onClick={onHome}>
        กลับไปที่หน้าหลัก
      </Link>
    </MessageBox>
  )

}

const Text = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const Link = styled.div`
  color: #07B9CB;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;

  :hover {
    cursor: pointer;
  }
`

const ErrText = styled.div`
  color: #FF3A33;
  font-size: 14px;
  margin-top: 10px;
`

const col = {
  btn: {
    xl: { span: 12, offset: 6 },
    lg: { span: 14, offset: 5 },
    md: { span: 18, offset: 3 },
    xs: { span: 24, offset: 0 },
  },
}

export default inject('member')(observer(CheckEmail))
