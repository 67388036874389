import { runInAction } from 'mobx'
import BaseStore from './BaseStore'

import { error, http } from '../utils'
import { config } from '../config'

let state
export class Category extends BaseStore {
  constructor() {
    super()
    this.observable({
      loading: false,
      all_list: [],
      leaf_list: [],
    })

    state = this
  }

  async getList(params = {}) {
    const obj = this.toJS()
    if (obj.all_list.length > 0) {
      return obj
    }

    runInAction(() => {
      state.loading = true
    })
    const url = `${config.api}/v1/category`
    const resp = await http.get(url)
    runInAction(() => {
      state.loading = false
    })

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isArray(data, { message: 'something incorrect' })

    const leaf_list = this.getLeafNode(data)
    runInAction(() => {
      state.all_list = data
      state.leaf_list = leaf_list
    })

    return { all_list: data, leaf_list }
  }

  findCategoryList(find_code, list) {
    const len = list.length
    let category
    for (let i = 0; i < len; i++) {
      category = this.findCategory(find_code, list[i])
      if (category) break
    }

    return category || {}
  }

  findCategory(find_code, category) {
    if (category.code === find_code) {
      return {
        category,
        original_list: [category],
      }
    }

    const { sub_list = [] } = category
    const sub_len = sub_list.length

    for (let i = 0; i < sub_len; i++) {
      const found = this.findCategory(find_code, sub_list[i])
      if (found) {
        const original_list = [category, ...found.original_list]
        return {
          category: found.category,
          original_list,
        }
      }
    }

    return undefined
  }

  addLeafNode(category = {}, list = []) {
    const {
      sub_list = []
    } = category

    if (sub_list.length === 0) {
      list.push(category)
    } else {
      const len = sub_list.length
      for (let i = 0; i < len; i++) {
        const cate = sub_list[i]
        this.addLeafNode(cate, list)
      }
    }
  }

  getLeafNode(category_list = []) {
    const list = []
    const len = category_list.length

    for (let i = 0; i < len; i++) {
      const cate = category_list[i]
      const {
        sub_list = []
      } = cate

      if (sub_list.length === 0) {
        list.push(cate)
      } else {
        this.addLeafNode(cate, list)
      }
    }

    return list
  }
}

export default new Category()
