import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Product from './Product'

const ProductHistory = (props) => {
  const { product_id } = props

  const history_list = props.product.toJS().history_list || []
  const list = history_list.filter((it) => it.product_id !== product_id)

  const content = list.map((item, index) => {
    return (
      <Product key={index} item={item} isFirst={index === 0} />
    )
  })

  return (
    <PageView>
      <Title>สินค้าที่ลูกค้าดูบ่อย</Title>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  padding-top: 24px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
`

export default inject('product')(observer(ProductHistory))
