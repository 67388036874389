import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { PopupMenu } from 'components/forms'
import Loading from 'components/loading/Loading'
import { Link } from 'components/link'

const Brand = (props) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState('All')
  const {
    loading = false,
    all_list = [],
  } = props.brand.toJS()

  const onVisible = (val) => setVisible(val)
  const onClose = () => setVisible(false)

  const processFilter = (list = [], isChar = false) => {
    return list.filter(it => {
      const { name } = it
      const c = name.length > 0 ? name[0] : '0'

      const result = c.toLowerCase().match(/[a-z]/i)
      return result === null
    })
  }

  const getFilter = () => {
    if (selected === 'All') {
      return all_list
    } else if (selected === '#') {
      return processFilter(all_list, false)
    }

    const ch = selected.toLowerCase()
    return all_list.filter(it => {
      const { name } = it
      const c = name.length > 0 ? name[0] : '0'

      return c.toLowerCase() === ch
    })
  }

  const content = ch_list.map((it, i) => {
    const css = selected === it ? 'active' : ''
    const click = () => setSelected(it)
    return (
      <Col span={8}>
        <ItemName className={css} key={i} onClick={click}>{it}</ItemName>
      </Col>
    )
  })

  const list = getFilter()
  const menu = list.map((it, i) => {
    const { code, name } = it
    return (
      <Col lg={6} md={8} key={i}>
        <Link to={`/brand.category/${code}?name=${name}`}>
          <Text onClick={onClose}>{name}</Text>
        </Link>
      </Col>
    )
  })
  return (
    <PageView>
      <Loading loading={loading} />
      <PopupMenu label="ยี่ห้อทั้งหมด" placement="bottomLeft" visible={visible} onVisible={onVisible}>
        <Menu>
          <MainMenu>
            <Row>
              {content}
            </Row>
          </MainMenu>
          <ContentMenu>
            <Row>
              {menu}
            </Row>
          </ContentMenu>
        </Menu>
      </PopupMenu>
    </PageView>
  )
}


const ch_list = [
  'All',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'Y',
  'Z',
  '#',
]

const PageView = styled.div`
  width: 100%;
`

const Menu = styled.div`
  display: flex;
`

const MainMenu = styled.div`
  color: black;
  background-color: #EDFBF6;
  padding: 16px 16px 16px 32px;
  width: 165px;
  max-height: 500px;
  overflow: auto;
`

const ItemName = styled.div`
  padding: 8px 0px;
  cursor: pointer;

  &.active {
    color: ${(p) => p.theme.color.green};
    font-weight: 700;
  }
`

const ContentMenu = styled.div`
  color: black;
  background-color: white;
  padding: 16px;
  width: 650px;
  max-height: 500px;
  overflow: auto;

  ${(p) => p.theme.media.tablet} {
    width: 400px;
  }
`

const Text = styled.div`
  color: ${(p) => p.theme.color.grey};
  padding: 4px 0px;
  cursor: pointer;
`

export default inject('brand')(observer(Brand))
