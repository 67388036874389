import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

const HtmlContent = (props) => {
  const { content } = props

  return (
    <Body>
      {parse(content || '')}
    </Body>
  )
}

const Body = styled.div`
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`


export default HtmlContent
