import { useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'utils'

import TitleMenu from './TitleMenu'
import FindMenu from './FindMenu'

const HeaderMenu = (props) => {
  const onGetList = useCallback(async () => {
    try {
      await Promise.all([
        props.brand.getList(),
        props.category.getList(),
      ])
    } catch (e) {
      message.error({
        title: 'แสดงข้อมูลเมนู',
        message: e.message,
      })
    }

  }, [props.brand, props.category])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  return (
    <TopBar>
      <TitleMenu />
      <FindMenu />
    </TopBar>
  )
}

const TopBar = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 20;
  background-color: ${(p) => p.theme.color.green};
`

export default inject('category', 'brand')(observer(HeaderMenu))
