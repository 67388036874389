import { Link as RLink } from 'react-router-dom'
import styled from 'styled-components'

const Link = (props) => {
  let { to, style, className, children, isUnderline } = props
  const css = {
    color: 'inherit',
    ...style
  }

  const val = children || 'Click'
  const text = isUnderline ? <Text>{val}</Text> : val
  return (
    <RLink
      to={to}
      style={css}
      className={className}>
      {text}
    </RLink>
  )
}

const Text = styled.span`
  &:hover {
    text-decoration: underline;
  }
`
export default Link
