/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#6C6C6C' } = props

  const width = `${Math.ceil(15 * size)}`
  const height = `${Math.ceil(15 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.333336" y1="7" x2="15" y2="7" stroke={color} strokeWidth="2"/>
      <line x1="8" y1="4.37114e-08" x2="8" y2="14.6667" stroke={color} strokeWidth="2"/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
