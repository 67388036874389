import styled from 'styled-components'

const Item = (props) => {
  const { name, value, position } = props

  return (
    <Text className={`position-${position}`}>
      <Label>{name}</Label>
      <Value>{value}</Value>
    </Text>
  )
}

const Text = styled.div`
  padding: 6px 16px;
  border-radius: 2px;
  display: flex;
  width: 90%;

  &.position-0, &.position-1 {
    background-color: white;
  }

  @media screen and (max-width: 500px) {
    &.position-0, &.position-2 {
      background-color: white;
    }

    &.position-1 {
      background-color: transparent;
    }
  }
`

const Label = styled.div`
  margin-right: 6px;
  color: #454545;
  width: 40%;
`

const Value = styled.div`
  margin-right: 16px;
  color: black;
`

export default Item
