import React from 'react'
import styled from 'styled-components'

import Message from './Message'

const BadMessage = (props) => {
  const {
    size,
    text,
  } = props

  const msg = (<h3>{text}</h3>)
  return (
    <Section>
      <Message
        size={size}
        icon="far fa-sad-tear"
        text={msg} />
    </Section>
  )
}

const Section = styled.div`
  text-align: center;
`

export default BadMessage
