import styled from 'styled-components'

import ProductCard from './ProductCard'

const ProductGroup = (props) => {
  const {
    item,
    prefix_url,
  } = props

  const product_list = item.list.map((it, i) => {
    return (
      <ProductCard
        key={i}
        item={it}
        prefix_url={prefix_url} />
    )
  })

  return (
    <Content>
      <Detail>
        {item.group_code}
      </Detail>

      <ProductLine>
        {product_list}
      </ProductLine>
    </Content>
  )
}

const Content = styled.div`
  padding-bottom: 10px;
  margin-bottom: 36px;
`

const Detail = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
`

const ProductLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1px;
  column-gap: 1px;
`

export default ProductGroup
