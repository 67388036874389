
export class Product {
  isProductMatch(product, filter_list) {
    for (const item of filter_list) {
      const val = product[item.name]
      const isMatch = item.list.includes(val)
      if (!isMatch) {
        return isMatch
      }
    }

    return true
  }

  filterProduct(params = {}) {
    const {
      original_list = [],

      brand_code_list = [],
      category_code_list = [],
      group_name_list = [],
      filter_name_list = [],
    } = params

    const tmp_list = []
    const check_brand = brand_code_list.length > 0
    const check_category = category_code_list.length > 0
    const check_group = group_name_list.length > 0
    const check_filter = filter_name_list.length > 0

    if ((check_brand || check_group || check_category || check_filter) === false) {
      return original_list
    }

    const group_len = original_list.length

    for (let i = 0; i < group_len; i++) {
      const group = original_list[i]
      const { list = [] } = group
      const len = list.length

      const product_list = []
      for (let j = 0; j < len; j++) {
        const product = list[j]
        const isMatchBrand = check_brand
          ? brand_code_list.includes(product.brand_code)
          : true

        const isMatchCategory = check_category
          ? category_code_list.includes(product.category_code)
          : true

        const isMatch = check_filter
          ? this.isProductMatch(product, filter_name_list)
          : true

        if (isMatchBrand && isMatchCategory && isMatch) {
          product_list.push(product)
        }
      }

      if (product_list.length === 0) continue

      const { group_code } = group
      const nGroup = {
        group_code,
        list: product_list,
      }

      const isMatchGroup = check_group
      ? group_name_list.includes(group_code)
      : true

      if (isMatchGroup) {
        tmp_list.push(nGroup)
      }
    }

    return tmp_list
  }

  getGroupList(group_list = []) {
    const tmp_list = []
    const group_len = group_list.length
    for (let i = 0; i < group_len; i++) {
      const group = group_list[i]
      tmp_list.push({
        item: { name: group.group_code },
        counter: group.list.length,
        selected: false,
      })
    }

    return tmp_list
  }

  countProduct(code, group_list, name = 'brand_code') {
    let counter = 0
    const group_len = group_list.length
    for (let i = 0; i < group_len; i++) {
      const group = group_list[i]
      const { list = [] } = group
      const len = list.length

      for (let j = 0; j < len; j++) {
        const product = list[j]
        if (product[name] === code) {
          counter++
        }
      }
    }

    return counter
  }

  getFilterList(group_list = [], filter_list = [], name = 'brand_code') {
    const tmp_list = []
    const len = filter_list.length
    for (let i = 0; i < len; i++) {
      const item = filter_list[i]

      const counter = this.countProduct(item.code, group_list, name)
      if (counter === 0) continue

      tmp_list.push({
        item,
        counter,
        selected: false,
      })
    }

    return tmp_list
  }

  selectFilter(display, filter, name_list, name) {
    display = this.setFilter(display, filter, name_list, name)
    return this.setFilterProduct(display)
  }

  setFilter(display, filter, name_list, name) {
    const list = display[name_list]

    const index = list.findIndex(it => it.item[name] === filter[name])
    if (index === -1) return display

    const item = list[index]
    item.selected = !item.selected

    display[name_list] = list
    return display
  }

  setFilterProduct(display = {}) {
    const {
      brand_list = [],
      category_list = [],
      group_list = [],
      filter_list = [],
      original_list = [],
    } = display

    const brand_code_list = brand_list.filter(it => it.selected).map(it => it.item.code)
    const category_code_list = category_list.filter(it => it.selected).map(it => it.item.code)
    const group_name_list = group_list.filter(it => it.selected).map(it => it.item.name)

    const filter_name_list = []
    for (const it of filter_list) {
      const list = it.list.filter(it => it.selected).map(it => it.item.name)
      if (list.length > 0) {
        filter_name_list.push({ name: it.name, list })
      }
    }

    display.list = this.filterProduct({
      original_list,
      brand_code_list,
      category_code_list,
      group_name_list,
      filter_name_list,
    })

    return display
  }

  selectedByIndex(data_list = [], select_list = []) {
    const len = data_list.length
    for (const i of select_list) {
      const index = +i
      if (index > -1 && index < len) {
        data_list[index].selected = true
      }
    }

    return data_list
  }

  selectedFilterByIndex(data_list = [], query = {}) {
    for (const group of data_list) {
      const tag = query[group.name]
      const selected_list = tag ? tag.split(',') : []

      const len = group.list.length
      if (selected_list.length > 0) {
        for (const i of selected_list) {
          const index = +i
          if (index > -1 && index < len) {
            group.list[index].selected = true
          }
        }
      }
    }

    return data_list
  }

  buildQuery(list = []) {
    const result_list = []
    let index = 0
    for (const data of list) {
      const { selected } = data
      if (selected) {
        result_list.push(index)
      }
      ++index
    }

    return result_list.join()
  }

  buildFilterQuery(list = []) {
    let q = ''
    for (const group of list) {
      const result_list = []
      let index = 0
      for (const item of group.list) {
        if (item.selected) {
          result_list.push(index)
        }
        ++index
      }

      if (result_list.length > 0) {
        const value = result_list.join()
        q += `&${group.name}=${value}`
      }
    }

    return q
  }

  isDisabled(status) {
    return ['out_of_stock', 'changed', 'cancelled', 'discontinued'].includes(status)
  }

  getStatusNote(status) {
    switch (status) {
      case 'delay':
        return 'เบิกของ 1-2 วัน'
      case 'pre_order':
        return 'สั่งพิเศษเฉพาะงาน'
      case 'check_stock':
        return 'สั่งผลิต (เช็คโรงงาน)'
      case 'out_of_stock':
        return 'สินค้าขาดตลาดชั่วคราว'
      case 'changed':
        return 'เปลี่ยนรุ่น'
      case 'cancelled':
        return 'ยกเลิกขาย'
      case 'discontinued':
        return 'เลิกผลิต'
      default:
        return ''
    }
  }
}

export const product = new Product()
export default product
