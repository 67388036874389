/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#6C6C6C' } = props

  const width = `${Math.ceil(14 * size)}`
  const height = `${Math.ceil(2 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.333332" y1="1" x2="13.6667" y2="0.999999" stroke={color} strokeWidth="2"/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
