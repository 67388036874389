import styled from 'styled-components'

import { Layout } from 'components/display'
import Cookie from 'components/cookie'
import Footer from 'footer'
import OrderPopup from 'pages/order.popup'

import HeaderMenu from './HeaderMenu'

const MainLayout = (props) => {
  const { children } = props

  return (
    <PageView>
      <HeaderMenu />
      <Layout>
        <Body>
          {children}
        </Body>
      </Layout>
      <Footer />
      <Cookie />
      <OrderPopup />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Body = styled.div`
  margin: 0 auto;
  min-height: 70vh;
  margin-top: 145px;
  padding: 0px 20px;
  width: 1440px;

  @media screen and (max-width: 1440px) {
    padding: 0px 20px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 0px 60px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }

  @media screen and (max-width: 430px) {
    padding: 0px 10px;
  }

  @media print {
    min-height: 90vh;
    margin-top: 0px;
    padding: 0px;
  }
`

export default MainLayout
