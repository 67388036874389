import styled from 'styled-components'

import { Input } from 'components/input'
import EditLine from 'pages/user/information/EditLine'
import { helper } from 'utils'

const EditUser = (props) => {
  const { doc = {}, validate = {}, onChange } = props

  return (
    <PageView>
      <EditLine label="ชื่อ">
        <Input
          name="name"
          value={doc.name}
          onChange={onChange}
          invalid={helper.getError(validate.name, 'กรุณากรอกชื่อ')} />
      </EditLine>
      <EditLine label="นามสกุล">
        <Input
          name="surname"
          value={doc.surname}
          onChange={onChange}
          invalid={helper.getError(validate.surname, 'กรุณากรอกนามสกุล')} />
      </EditLine>
      <EditLine label="เบอร์โทร">
        <Input
          name="mobile"
          value={doc.mobile}
          onChange={onChange}
          isNumber={true}
          pattern="xxx-xxx-xxxx"
          invalid={helper.getError(validate.mobile, 'กรุณากรอกเบอร์โทร')} />
      </EditLine>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default EditUser
