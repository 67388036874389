import styled from 'styled-components'

import { format } from 'utils'
import { Checker } from 'components/input'

import DisplayInfo from '../DisplayInfo'
import Line from '../Line'

const UserInfo = (props) => {
  const { doc = {} } = props

  const getCompany = () => {
    const branch = doc.branch_type === 'main' ? 'สำนักงานใหญ่' : doc.branch_code
    return (
      <>
        <Line label="ชื่อบริษัท" value={doc.company_name} />
        <Line label="สาขา" value={branch} />
      </>
    )
  }

  const getPersonal = () => {
    return (
      <Line label="ชื่อ-นามสกุล / ชื่อร้านค้า" value={doc.name} />
    )
  }
  const isPersonal = doc.tax_type === 'personal'
  const content = isPersonal ? getPersonal() : getCompany()
  const label = isPersonal ? 'บุคคลธรรมดา' : 'นิติบุคคล'
  return (
    <PageView>
      <DisplayInfo>
        <Checker label={label} value={true} />
        <Space />
        <Line label="เลขประจำตัวผู้เสียภาษี" value={format.toPattern(doc.social_no, 'x-xxxx-xxxxx-xx-x')} />
        {content}
        <Line label="ที่อยู่ / ถนน / ซอย" value={doc.address} />
        <Line label="จังหวัด" value={doc.province} />
        <Line label="เขต / อำเภอ" value={doc.district} />
        <Line label="แขวน / ตำบล" value={doc.tumbon} />
        <Line label="รหัสไปรษณีย์" value={doc.postcode} />
        <Line label="เบอร์โทรศัพท์" value={format.toMobile(doc.mobile)} />
      </DisplayInfo>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  padding-top: 24px;
`

export default UserInfo
