import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { KeyBtn, Btn } from 'components/button'
import BaseDialog from 'dialog/BaseDialog'

import Warning from './Warning'

const RemoveDialog = (props) => {
  const { visible, onClose, onConfirm } = props

  return (
    <BaseDialog isHeader={false} visible={visible} onClose={onClose}>
      <Content>
        <Body>
          <Warning />
          <Title>ลบบัญชีผู้ใช้งาน</Title>
          <Detail>
            <div>เมื่อดำเนินการลบบัญชีแล้ว</div>
            <div>คุณสามารถกลับเข้ามาใช้งานได้ภายใน 7 วัน</div>
            <div>หลังจากนั้นโปรดทราบว่าเมื่อบัญชีถูกลบแล้ว</div>
            <div>จะไม่สามารถกู้คืนข้อมูลได้อีก</div>
            <div>คุณยืนยันที่จะลบบัญชีของคุณหรือไม่?</div>
          </Detail>
        </Body>

        <Row>
          <Col span={12} position="left">
            <Btn text="ย้อนกลับ" onClick={onClose} />
          </Col>
          <Col span={12} position="right">
            <KeyBtn text="ยืนยัน" onClick={onConfirm} />
          </Col>
        </Row>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 0px;

  ${(p) => p.theme.media.mobile_xs} {
    min-width: 240px;
  }
`

const Body = styled.div`
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 24px;
`

const Title = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-weight: 700;
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 10px;
`

const Detail = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 16px;
`

export default RemoveDialog
