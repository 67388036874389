import styled from 'styled-components'
import { Row } from 'antd'

import { KeyBtn, Btn } from 'components/button'
import { Input, InputPassword } from 'components/input'
import { Col } from 'components/display'

import DisplayInfo from '../DisplayInfo'
import EditLine from '../EditLine'

const EditUserInfo = (props) => {
  const { user = {}, password, onEdit, onClose, onChange } = props

  return (
    <PageView>
      <DisplayInfo>
        <EditLine label="อีเมล">
          <Input disabled={true} value={user.email} onChange={onChange} />
        </EditLine>
        <EditLine label="รหัสผ่าน">
          <InputPassword id="user-password" value={password} onChange={onChange} />
        </EditLine>

        <Space />
        <Row>
          <Col lg={6} md={8} xs={24} position="left-responsive">
            <KeyBtn text="บันทึก" onClick={onEdit} />
          </Col>
          <Col lg={6} md={8} xs={24} position="right-responsive">
            <Btn text="ยกเลิก" onClick={onClose} />
          </Col>
        </Row>
      </DisplayInfo>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  padding-top: 24px;
`

export default EditUserInfo
