import { useState } from 'react'
import styled from 'styled-components'
import { Modal, Row, Col } from 'antd'

import { Btn } from 'components/button'
import { TextArea } from 'components/forms'

const TextDialog = (props) => {
  const [text, setText] = useState('')

  const onOk = () => {
    if (props.onOk) props.onOk(text)
  }

  const onCancel = () => {
    if (props.onCancel) props.onCancel()
  }

  const onChange = (value) => {
    setText(value)
  }

  const { title = 'Confirm', visible } = props
  return (
    <Modal
      title={title}
      visible={visible}
      footer={null}
      onCancel={onCancel}>

      <TextArea
        autofocus={true}
        row={4}
        value={text}
        onChange={onChange} />
      <Inner>
        <Row>
          <Col {...col.close}>
            <Btn
              text="Close"
              onClick={onCancel} />
          </Col>
          <Col {...col.confirm}>
            <Btn
              disabled={text.length === 0}
              text="OK"
              onClick={onOk} />
          </Col>
        </Row>
      </Inner>
    </Modal>
  )
}

const Inner = styled.div`
  margin-top: 10px;
`

const col = {
  close: {
    lg: { span: 6, offset: 12 },
    md: { span: 8, offset: 8 },
    xs: 12,
  },
  confirm: {
    lg: 6,
    md: 8,
    xs: 12,
  }
}

export default TextDialog
