import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { PopupMenu } from 'components/forms'
import { Link } from 'components/link'

const AccountMenu = (props) => {
  const [visible, setVisible] = useState(false)

  const { user } = props.member.toJS()

  const onVisible = (val) => setVisible(val)
  const onClose = () => setVisible(false)

  const onLogout = async () => {
    await props.member.logout()
    onClose()
  }

  const name = user.name || ''
  const surname = user.surname || ''
  const fullname = `${name} ${surname}`.trim()

  return (
    <PopupMenu label={fullname} placement="bottomRight" visible={visible} onVisible={onVisible}>
      <Menu>
        <Name>{fullname}</Name>
        <Text onClick={onClose}>
          <Link to="/user/information">
            ข้อมูลส่วนตัว
          </Link>
        </Text>
        <Text onClick={onClose}>
          <Link to="/user/address">
            สมุดที่อยู่
          </Link>
        </Text>
        <Text onClick={onClose}>
          <Link to="/user/account">
            บัญชีของฉัน
          </Link>
        </Text>
        <Text onClick={onClose}>
          <Link to="/user/order">
            ประวัติสั่งซื้อ
          </Link>
        </Text>
        <Logout onClick={onLogout}>ออกจากระบบ</Logout>
      </Menu>
    </PopupMenu>
  )
}

const Menu = styled.div`
  width: 100%;
  min-width: 200px;
  padding: 24px;
`

const Name = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
`

const Logout = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${(p) => p.theme.color.green};
  cursor: pointer;
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 16px;
`

export default inject('member')(observer(AccountMenu))
