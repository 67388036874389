import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Quantity } from 'components/input'
import { Delete } from 'icon'
import { format } from 'utils'
import { ConfirmDialog } from 'dialog'

import col from './col'

const Product = (props) => {
  const [isConfirm, setIsConfirm] = useState(false)

  const navigate = useNavigate()

  const { item, isLast } = props
  const { product, quantity } = item
  const { img_url, name, product_code, barcode, discount_price, unit_type, discount } = product

  const confirmClear = () => setIsConfirm(true)
  const onClose = () => setIsConfirm(false)
  const onRemove = () => {
    onClose()
    props.order.removeProduct(product, quantity)
  }

  const onLink = () => {
    props.order.setVisible(false)
    const link = `/product/${product_code}?name=${name}`
    navigate(link)
  }

  const onChange = (val) => {
    props.order.setProduct(product, val)
  }


  const img = img_url ? <img src={img_url} alt={name} /> : undefined
  const total = format.precision(quantity * discount_price)

  const borderBottomWidth = isLast ? '0px' : '1px'
  return (
    <PageView style={{ borderBottomWidth }}>
      <Row>
        <Col { ...col.name}>
          <Info>
            <Image onClick={onLink}>
              {img}
            </Image>
            <Detail>
              <Name onClick={onLink}>{name}</Name>
              <ProductCode onClick={onLink}>{barcode}</ProductCode>
            </Detail>
          </Info>
        </Col>

        <Col { ...col.quantity}>
          <QuantityArea>
            <Quantity
              min={1}
              max={50000}
              value={quantity}
              onChange={onChange} />
          </QuantityArea>
        </Col>

        <Col { ...col.unit}>
          {unit_type}
        </Col>

        <Col { ...col.price}>
          <Space>
            {format.toMoney(discount_price)} บาท
          </Space>
        </Col>

        <Col { ...col.discount}>
          {discount}
        </Col>

        <Col { ...col.total_price}>
          <Space>
            {format.toMoney(total)} บาท
          </Space>
          <Btn onClick={confirmClear}>
            <Delete color="#6C6C6C" />
          </Btn>
        </Col>
      </Row>

      <ConfirmDialog
        visible={isConfirm}
        message="คุณต้องการที่จะลบสินค้ารายการนี้ออก ใช่หรือไม่?"
        onClose={onClose}
        onConfirm={onRemove} />
    </PageView>
  )
}

const PageView = styled.div`
  padding: 16px 0px;
  font-size: 14px;
  border: 0px solid #AAAAAA;
  border-bottom-width: 1px;
`

const Info = styled.div`
  display: flex;
  column-gap: 8px;
`

const QuantityArea = styled.div`
  padding-right: 8px;
`

const Image = styled.div`
  img {
    width: 50px;
    cursor: pointer;
  }
`
const Detail = styled.div``

const Btn = styled.div`
  cursor: pointer;
`

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
`

const ProductCode = styled.div`
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
`

const Space = styled.div`
  word-break: break-all;
  overflow-wrap: break-word;

  ${(p) => p.theme.media.mobile} {
    margin-top: 10px;
  }
`

export default inject('order')(observer(Product))
