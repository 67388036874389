import styled from 'styled-components'

import { format } from 'utils'

import HeaderInfo from './Header'
import Product from './Product'
import Summary from './Summary'

const OrderInfo = (props) => {
  const { order = {} } = props

  const { order_code, product_list = [], created_at } = order

  const content = product_list.map((it, index) => {
    return (
      <Product index={index} item={it} key={index} />
    )
  })

  return (
    <PageView>
      <Line>
        <Text><span>เลขที่ใบเสนอราคา:</span>{order_code}</Text>
        <Text><span>วันที่:</span>{format.toFullDT(created_at)}</Text>
      </Line>
      <Remark>
        <span>บันทึกช่วยจำ:</span>
        {order.remark}
      </Remark>
      <HeaderInfo />

      <ProductList>
        {content}
      </ProductList>
      <Summary order={order} />
    </PageView>
  )
}

const PageView = styled.div``

const ProductList = styled.div`
  width: 100%;
  padding: 0px 6px;
  padding-bottom: 10px;
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
`

const Text = styled.div`
  font-size: 16px;
  span {
    margin-right: 8px;
    font-weight: 700;
  }
`

const Remark = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 24px;
  span {
    margin-right: 8px;
  }
`

export default OrderInfo
