const col = {
  img: { xl: 2, lg: 2, md: 3, xs: 6 },
  img_name: { xl: 11, lg: 11, md: 6, xs: 10 },
  name:   { xl: 13, lg: 13, md: 9, xs: 16 },
  brand:  { xl: 4, lg: 3, md: 5, xs: 8 },
  price:  { xl: 4, lg: 4, md: 5, xs: 14 },
  btn:    { xl: 3, lg: 4, md: 5, xs: 10 },
}

export default col
