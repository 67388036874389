import React from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'antd'

import { Link } from '../../components/link'

const BreadcrumbView = (props) => {
  return (
    <PageView>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          ค้นหาสินค้า
        </Breadcrumb.Item>
      </Breadcrumb>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 5px 0px;
  @media print {
    display: none;
  }
`

export default BreadcrumbView
