import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { KeyBtn } from 'components/button'
import { Meta, MessageBox } from 'components/display'
import { Input } from 'components/input'

const ResetPassword = (props) => {
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState({ email: undefined })
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const navigate = useNavigate()

  const onChange = (value, name) => {
    setEmail(value)
    setValid({ email: undefined })
  }

  const onReset = async () => {
    setCompleted(false)

    try {
      if (email === '') {
        setValid({ email: 'กรุณาใส่ email' })
        return
      } else {
        setLoading(true)

        await props.member.resetPassword(email)
        setCompleted(true)
      }
    } catch (e) {
      let m = e.message
      if (m.includes('There is no user record')) {
        m = 'ไม่พบ email ในระบบ'
      } else if (m.includes('badly formatted')) {
        m = 'รูปแบบ Email ไม่ถูกต้อง'
      } else {
        m = 'กรุณาลองใหม่อีกครั้ง'
        console.log('msg:', e.message)
      }

      setValid({ email: m })
    }

    setLoading(false)
  }

  const onHome = () => navigate('/')

  const text = completed
  ? <>
      เราได้ทำการส่งลิ้งสำหรับรีเซ็ทรหัสผ่านไปยังอีเมลของท่านแล้ว
      <br/>
      กรุณาตรวจสอบกล่องข้อความ หรือ Junk mail เพื่อดำเนินการต่อ
    </>
  : ''

  return (
    <MessageBox size="s">
      <Meta title="รีเซ็ตรหัสผ่าน" />
      <Title>รีเซ็ตรหัสผ่าน</Title>

      <Input
        label="อีเมลที่ต้องการตั้งรหัสใหม่"
        name="email"
        disabled={loading}
        value={email}
        onChange={onChange}
        invalid={valid.email} />
      <Space />
      <KeyBtn
        text={'ตั้งรหัสผ่านใหม่'}
        loading={loading}
        onClick={onReset} />

      <Link onClick={onHome}>
        กลับไปที่หน้าหลัก
      </Link>

      <TextCompleted>
        {text}
      </TextCompleted>
    </MessageBox>
  )
}

const Title = styled.h2`
  text-align: center;
`

const Space = styled.div`
  margin-top: 16px;
`

const TextCompleted = styled.div`
  color: #50B836;
  font-size: 14px;
  margin-top: 20px;
`

const Link = styled.div`
  color: #07B9CB;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;

  :hover {
    cursor: pointer;
  }
`

export default inject('member')(observer(ResetPassword))
