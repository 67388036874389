import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { Col } from 'components/display'
import { KeyBtn, Btn } from 'components/button'

import Dialog from './Dialog'
import OrderInfo from './orderInfo'
import Note from './Note'
import Summary from './Summary'

const OrderList = (props) => {
  const navigate = useNavigate()

  const { visible, current } = props.order.toJS()
  const order = current

  const onClose = () => props.order.setVisible(false)
  const onCheckout = () => {
    onClose()
    navigate('/order')
  }

  const onChange = (val, name) => {
    order[name] = val
    props.order.setCurrentOrder(order)
  }

  const onRemark = (remark) => {
    order.remark = remark
    props.order.setCurrentOrder(order)
  }

  return (
    <Dialog visible={visible} onClose={onClose}>
      <PageView>
        <OrderInfo order={order} onChange={onChange} />

        <Row>
          <Col { ...col.info }>
            <Note order={order} onRemark={onRemark} />
          </Col>
          <Col { ...col.summary }>
            <SummaryArea>
              <Summary order={order} />
            </SummaryArea>
          </Col>
        </Row>

        <Button>
          <Btn text="เลือกชมสินค้าต่อ" onClick={onClose} />
          <KeyBtn text="ไปที่ตะกร้าของฉัน" onClick={onCheckout} />
        </Button>
      </PageView>
    </Dialog>
  )
}

const col = {
  info: {
    lg: { span: 14, order: 1 },
    md: { span: 14, order: 1 },
    xs: { span: 24, order: 1 },
  },
  summary: {
    lg: { span: 10, order: 2 },
    md: { span: 10, order: 2 },
    xs: { span: 24, order: 2 },
  },
}

const PageView = styled.div`
  width: 100%;
  background-color: white;
  padding: 24px;
`

const SummaryArea = styled.div`
  margin-left: 24px;

  ${(p) => p.theme.media.mobile} {
    margin-left: 0px;
  }
`

const Button = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: end;
  column-gap: 16px;

  ${(p) => p.theme.media.mobile} {
    justify-content: space-around;
  }
`

export default inject('order')(observer(OrderList))
