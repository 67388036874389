import { useState, useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { helper } from 'utils'
import { Checker } from 'components/input'
import { Input, InputArea, SelectList } from 'components/input'
import { Col } from 'components/display'
import EditLine from 'pages/user/information/EditLine'

const func = () => {}

const EditAddress = (props) => {
  const [provinceMenu, setProvinceMenu] = useState([])
  const [districtMenu, setDistrictMenu] = useState([])
  const [tumbonMenu, setTumbonMenu] = useState([])

  const {
    doc = {},
    address = {},
    validate = {},
    onChange = func,
    onAddress = func,
    onValidate = func
  } = props

  useEffect(() => {
    const {
      province_list,
    } = props.master.toJS()

    const province = province_list.find(it => it.name === address.province)
    if (provinceMenu.length === 0) {
      setProvinceMenu(province_list)
    }

    if (!province) {
      setDistrictMenu([])
      setTumbonMenu([])
      return
    }

    const {
      district_list = [],
      tumbon_list = []
    } = province.tag

    const tumbon = tumbon_list.find(it => it.district_name === address.district)
    setDistrictMenu(district_list)
    setTumbonMenu(tumbon ? tumbon.tumbon_list : [])
  }, [address.province, address.district])

  const onChangeItem = (val, name) => {
    doc[name] = val
    onChange(doc)

    delete validate[name]
    onValidate(validate)
  }

  const onPersonal = (val) => {
    doc.tax_type = val ? 'personal' : 'company'
    onChange(doc)
  }

  const onCompany = (val) => {
    doc.tax_type = !val ? 'personal' : 'company'
    onChange(doc)
  }

  const onBranch = (val) => {
    doc.branch_type = val ? 'main' : 'sub'
    onChange(doc, 'branch_type')
  }

  const onProvince = (val) => {
    address.province = val
    address.district = ''
    address.tumbon = ''

    onAddress(address)

    delete validate.province
    delete validate.district
    delete validate.tumbon
    onValidate(validate)
  }

  const onDistrict = (val, name) => {
    address.district = val
    address.tumbon = ''
    onAddress(address)

    delete validate.district
    delete validate.tumbon
    onValidate(validate)
  }

  const onTumbon = (val, name, tag = {}) => {
    address.tumbon = val
    address.postcode = tag.postcode ? tag.postcode : ''
    onAddress(address)

    delete validate.tumbon
    delete validate.postcode
    onValidate(validate)
  }

  const onAddressItem = (val, name) => {
    address[name] = val
    onAddress(address)

    delete validate[name]
    onValidate(validate)
  }

  const getPersonal = () => {
    return (
      <EditLine label="ชื่อ-นามสกุล / ชื่อร้านค้า">
        <Input
          name="name"
          value={doc.name}
          onChange={onChangeItem}
          invalid={helper.getError(validate.name, 'กรุณากรอกชื่อ-นามสกุล / ชื่อร้านค้า')} />
      </EditLine>
    )
  }

  const getCompany = () => {
    const isMain = doc.branch_type === 'main'
    return (
      <>
        <EditLine label="ชื่อบริษัท">
          <Input
            name="company_name"
            value={doc.company_name}
            onChange={onChangeItem}
            invalid={helper.getError(validate.company_name, 'กรุณากรอกชื่อบริษัท')} />
        </EditLine>
        <Checker
          label="สำนักงานใหญ่"
          value={isMain}
          onChange={onBranch} />
        <LineSpace />
        <EditLine label="สาขา">
          <Input
            name="branch_code"
            disabled={isMain}
            value={doc.branch_code}
            onChange={onChangeItem}
            invalid={helper.getError(validate.branch_code, 'กรุณากรอกสาขา')} />
        </EditLine>
      </>
    )
  }

  const isPersonal = doc.tax_type === 'personal'
  const content = isPersonal ? getPersonal() : getCompany()

  const input_province = useMemo(() => {
    return (
      <EditLine label="จังหวัด">
        <SelectList
          menu={provinceMenu}
          name="province"
          value={address.province}
          onChange={onProvince}
          filter={true}
          invalid={helper.getError(validate.province, 'กรุณากรอกจังหวัด')}
        />
      </EditLine>
    )
  }, [address.province, validate.province, provinceMenu.length])

  const input_district = useMemo(() => {
    return (
      <EditLine label="เขต / อำเภอ">
        <SelectList
          menu={districtMenu}
          name="district"
          value={address.district}
          onChange={onDistrict}
          filter={true}
          disabled={districtMenu.length === 0}
          invalid={helper.getError(validate.district, 'กรุณากรอกเขต / อำเภอ')}
        />
      </EditLine>
    )
  }, [address.district, validate.district, districtMenu])

  const input_tumbon = useMemo(() => {
    return (
      <EditLine label="แขวน / ตำบล">
        <SelectList
          menu={tumbonMenu}
          name="tumbon"
          value={address.tumbon}
          onChange={onTumbon}
          filter={true}
          disabled={tumbonMenu.length === 0}
          invalid={helper.getError(validate.tumbon, 'กรุณากรอกแขวน / ตำบล')}
        />
      </EditLine>
    )
  }, [address.tumbon, validate.tumbon, tumbonMenu])

  const tax_no_content = doc.tax_type === 'personal'
    ? { title: 'เลขประจำตัวผู้เสียภาษี (ไม่จำเป็น)' }
    : { title: 'เลขประจำตัวผู้เสียภาษี' }

  return (
    <PageView>
      <Row>
        <Col lg={10} md={10} xs={12}>
          <Checker label="บุคคลธรรมดา" value={isPersonal} onChange={onPersonal} />
        </Col>
        <Col lg={10} md={10} xs={12}>
          <Checker label="นิติบุคคล" value={!isPersonal} onChange={onCompany} />
        </Col>
      </Row>

      <Space />
      <EditLine label={tax_no_content.title}>
        <Input
          name="social_no"
          value={doc.social_no}
          onChange={onChangeItem}
          isNumber={true}
          invalid={helper.getError(validate.social_no, 'กรุณากรอกเลขประจำตัวผู้เสียภาษี')} />
      </EditLine>

      {content}

      <EditLine label="ที่อยู่ / ถนน / ซอย">
        <InputArea
          name="address"
          value={doc.address}
          onChange={onChangeItem}
          invalid={helper.getError(validate.address, 'กรุณากรอกที่อยู่ / ถนน / ซอย')} />
      </EditLine>

      {input_province}
      {input_district}
      {input_tumbon}

      <EditLine label="รหัสไปรษณีย์">
        <Input
          name="postcode"
          value={address.postcode}
          onChange={onAddressItem}
          invalid={helper.getError(validate.postcode, 'กรุณากรอกรหัสไปรษณีย์')} />
      </EditLine>

      <EditLine label="เบอร์โทรศัพท์">
        <Input
          name="mobile"
          value={doc.mobile}
          isNumber={true}
          onChange={onChangeItem}
          pattern="xxx-xxx-xxxx"
          invalid={helper.getError(validate.mobile, 'กรุณากรอกเบอร์โทรศัพท์')} />
      </EditLine>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
`

const LineSpace = styled.div`
  padding-top: 16px;
`

const Space = styled.div`
  padding-top: 24px;
`

export default inject('master')(observer(EditAddress))
