import React from 'react'
import { Select } from 'antd'
import styled from 'styled-components'

const DropdownList = (props) => {
  let {
    menu = [],
    placeHolder = 'Please Select',
    value,
    name,
    clear = true,
    disabled = false,
    readonly = false,
    showSearch = false,
    style = {},
  } = props

  const onSelected = (selected) => {
    if (props.onChange && value !== selected) {
      const item = menu.find(item => item.value === selected) || {}
      props.onChange(selected, name, item.tag)
    }
  }

  const onFilter = (input = '', option) => {
    const text = option.children || ''
    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const css = readonly ? 'select-readonly' : ''

  const choice = menu.map((item) => {
    return (
      <Select.Option
        key={item.value}
        disabled={item.disabled || false}>
        {item.name}
      </Select.Option>
    )
  })

  return (
    <Section>
      <Select
        showSearch={showSearch}
        disabled={disabled || readonly}
        style={{ width: '100%', ...style }}
        className={css}
        placeholder={placeHolder}
        value={value}
        allowClear={clear}
        showArrow={true}
        onChange={onSelected}
        filterOption={onFilter}>
        {choice}
      </Select>
    </Section>
  )
}

const Section = styled.div`

`

export default DropdownList
