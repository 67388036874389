import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Link } from 'components/link'
import { Btn } from 'components/button'
import { Input } from 'components/input'
import { format, product, ga } from 'utils'

const Product = (props) => {
  const [quantity, setQuantity] = useState(1)

  const { item = {}, isFirst } = props

  const onChange = (val) => {
    setQuantity(val)
  }

  const onAddOrder = () => {
    props.order.addProduct(item, quantity)
    props.order.setVisible(true)
    ga.click(`add product by history info ${quantity}`)
  }

  const { name, product_code, unit_type, img_list = [] } = item
  const price = format.toMoney(item.price)
  const link = `/product/${product_code}?name=${name}`
  const img = img_list.length > 0 ? (
    <Link to={link}>
      <Image src={img_list[0]} />
    </Link>
  ) : undefined

  const css = isFirst ? { borderTopWidth: '1px' } : undefined

  const isDisabled = product.isDisabled(item.status)
  const text = isDisabled ? 'สินค้าหมด' : 'เพิ่มลงรถเข็น'
  return (
    <Item style={css}>
      <Row>
        <Col span={10}>
          {img}
        </Col>
        <Col span={14}>
          <Link to={link} isUnderline={true}>
            <Name>{name}</Name>
          </Link>
        </Col>
      </Row>

      <Space />
      <Row>
        <Col span={8}>
          <Label>ราคา</Label>
        </Col>
        <Col span={16}>
          <Price>{price} บาท / {unit_type}</Price>
        </Col>
      </Row>
      <Space />
      <Row align="bottom">
        <Col span={8}>
          <Label>จำนวน</Label>
          <Quantity>
            <Input value={quantity} onChange={onChange} isNumber={true} />
          </Quantity>
        </Col>
        <Col span={16}>
          <Btn text={text} disabled={isDisabled} onClick={onAddOrder} />
        </Col>
      </Row>
    </Item>
  )
}

const Item = styled.div`
  width: 100%;
  background-color: white;
  padding: 16px;
  border: 1px solid #aaaaaa;
  border-top-width: 0px;
`

const Image = styled.img`
  width: 90%;
`

const Name = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
`

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  color: #6C6C6C;
`

const Space = styled.div`
  width: 100%;
  height: 8px;
`

const Quantity = styled.div`
  width: 100%;
  padding-right: 16px;
  padding-top: 8px;
`

const Price = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #003e31;
`

export default inject('order')(observer(Product))
