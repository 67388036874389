import { DownloadOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { color } from 'definition'
import { HtmlContent } from 'components/display'
import { Link } from 'components/link'

import DetailItem from './DetailItem'

const Detail = (props) => {
  const { product = {} } = props
  const { file_list = [] } = product

  const getName = (url, i = 0) => {
    const index = url.lastIndexOf('/')
    const name = decodeURI(index === -1
    ? `File (${i})`
    : url.substr(index + 1))

    return name
  }

  const file_content = file_list.map((url, index) => {
    return (
      <FileBlock key={ index }>
        <File href={ url } target="_blank">
          <span><DownloadOutlined /></span>
          {getName(url, index + 1)}
        </File>
      </FileBlock>
    )
  })
  const { brand_code, brand_name } = product
  const link = `/brand.category/${brand_code}?name=${brand_name}`
  const value = (
    <Link to={link} isUnderline={true}>
      {brand_name}
    </Link>
  )
  return (
    <PageView>
      <Name>
        {product.name}
      </Name>

      <DetailItem color="white" name="ยี่ห้อ" value={value} />
      <DetailItem
        name="ประเภทสินค้า"
        value={ product.category_name } />
      <DetailItem color="white" name="barcode" value={product.barcode} />

      <Section>
        รายละเอียดสินค้า
      </Section>
      <HtmlContent content={product.detail} />

      <Section>
        เอกสารสินค้า
      </Section>
      <FileList>
        { file_content.length === 0 ? '-' : file_content }
      </FileList>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 0px 30px;

  @media screen and (max-width: 500px) {
    padding: 20px 10px;
  }
`

const Name = styled.h2`
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Section = styled.div`
  margin: 10px 0px;
  color: #454545;
`

const FileList = styled.div`
  margin-top: 5px;
`

const FileBlock = styled.div`
  margin-bottom: 10px;
`

const File = styled.a`
  color: ${color.bg};
  span {
    font-size: 28px;
    margin-right: 8px;
  }
`

export default Detail
