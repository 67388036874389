import styled from 'styled-components'

import ProductHeader from './ProductHeader'
import Product from './Product'

const ProductGroup = (props) => {
  const {
    item,
    isList,
    prefix_url,
  } = props

  const img_list = item.list.length === 0
  ? []
  : item.list[0].img_list

  const img_url = img_list.length === 0
  ? undefined
  : img_list[0]

  const product_list = item.list.map((it, i) => {
    return (
      <Product
        key={i}
        item={it}
        isFirst={i === 0}
        isList={isList}
        prefix_url={prefix_url} />
    )
  })

  const img = isList === 'table' ? <Img src={img_url} /> : undefined
  return (
    <Content>
      <Detail>
        {item.group_code}
      </Detail>

      {img}

      <Line />
      <ProductHeader />
      {product_list}
    </Content>
  )
}

const Content = styled.div`
  padding-bottom: 10px;
  margin-bottom: 36px;
`

const Detail = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
`

const Line = styled.div`
  margin-bottom: 20px;
`

const Img = styled.img`
  width: 100%;
  max-width: 180px;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export default ProductGroup
