import styled from 'styled-components'
import { Row, Col } from 'antd'

import col from './col'

const HeaderInfo = (props) => {
  return (
    <Header>
      <Row>
        <Col { ...col.name }>
          สินค้า
        </Col>

        <Col { ...col.quantity }>
          จำนวน
        </Col>

        <Col { ...col.unit }>
          หน่วยนับ
        </Col>

        <Col { ...col.price }>
          ราคา
        </Col>

        <Col { ...col.discount }>
          ส่วนลด
        </Col>

        <Col { ...col.total_price }>
          ราคารวม
        </Col>
      </Row>
    </Header>
  )
}

const Header = styled.div`
  padding: 0px 16px;
  color: #6C6C6C;
`

export default HeaderInfo
