import { Helmet } from 'react-helmet'

const Meta = (props) => {
  const {
    loading = false,
    title = 'ประธานการไฟฟ้า',
    description = 'ประธานการไฟฟ้า์',
    url,
  } = props

  if (loading) return <div />

  const img = url || '/prathan-link.png'
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="title" content={title} />
      <meta name="description" content={description} />
      <meta property="image" content={img} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={window.location.href} />

      <meta charset="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />

      <meta content="image/*" property="og:image:type" />
    </Helmet>
  )
}

export default Meta
