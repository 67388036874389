import styled from 'styled-components'

import { Check } from 'icon'

import Invalid from './Invalid'

const CheckerInput = (props) => {
  const { name, label, value, tag, onChange, invalid } = props

  const onClick = () => {
    onChange(!value, name, tag)
  }

  const title = label ? <Text onClick={onClick}>{label}</Text> : undefined
  const color = value ? undefined : 'white'
  return (
    <>
      <PageView>
        <Btn onClick={onClick}>
          <Check color={color} />
        </Btn>
        {title}
      </PageView>
      <Invalid invalid={invalid} />
    </>
  )
}

const PageView = styled.div`
  display: flex;
  align-items: center;
`

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
`

const Text = styled.span`
  margin-left: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default CheckerInput
