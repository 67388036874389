import styled from 'styled-components'
import { Row } from 'antd'

import { color } from 'definition'

const RowItem = (props) => {
  return (
    <PageView>
      <Row>
        {props.children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 15px 10px;
  border-radius: 10px;
  border: 1px solid ${color.border};
  background-color: white;
  margin-bottom: 4px;
`

export default RowItem
