import styled from 'styled-components'
import { Breadcrumb } from 'antd'

import { Link } from 'components/link'

const BreadcrumbView = (props) => {
  const {
    by_brand = false,
    category_list = [],
    product,
  } = props

  let { brand_code } = props
  if (brand_code === undefined && product !== undefined)
    brand_code = product.brand_code

  const len = category_list.length - 1
  const url = by_brand ? {
    product_url: `/product.list/brand/${brand_code}`,
    back_url: '/brand.category'
  } : {
    product_url: '/product.list',
    back_url: '/sub.category'
  }

  const menu = category_list.map((it, index) => {
    const { code, name } = it
    const link = len === index
      ? product === undefined
        ? undefined
        : `${url.product_url}/${code}?name=${name}`
      : `${url.back_url}/${code}?name=${name}`

    return link
      ? (
        <Breadcrumb.Item key={index}>
          <Link to={link}>
            <Text>{name}</Text>
          </Link>
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item key={index}>
          {name}
        </Breadcrumb.Item>
      )
  })

  const pro_content = product
  ? <Breadcrumb.Item>
      <Label>{product.name}</Label>
    </Breadcrumb.Item>
  : undefined

  return (
    <PageView>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
            <Text>Home</Text>
          </Link>
        </Breadcrumb.Item>
        {menu}
        {pro_content}
      </Breadcrumb>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 5px 0px;
`

const Text = styled.span`
  color: #003e31;
`

const Label = styled.span`
  color: #003e31;
`

export default BreadcrumbView
