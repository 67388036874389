import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format } from 'utils'
import { Link } from 'components/link'

import col from './col'

const Product = (props) => {
  const {
    index = 0,
    item,
  } = props
  const { product, quantity } = item

  const { img_url, name, barcode, product_code } = product
  const i = index < 13 ? index : index - 12
  const pages = index < 13 ? 12 : 14

  const pageBreakBefore = index === 0 || i % pages !== 0 ? 'avoid' : 'always'
  const total = format.precision(quantity * product.discount_price)
  const link = `/product/${product_code}?name=${name}`
  const img = img_url ? <Link to={link}><img src={img_url} alt={name} /></Link> : undefined
  return (
    <PageView style={{ pageBreakBefore }}>
      <Row>
        <Col { ...col.seq}>
          {index + 1}
        </Col>
        <Col { ...col.name}>
          <Info>
            <Image>
              {img}
            </Image>
            <Detail>
              <Link to={link}>
                <Name>{name}</Name>
                <ProductCode>{barcode}</ProductCode>
              </Link>
            </Detail>
          </Info>
        </Col>

        <Col { ...col.quantity}>
          <Qty>
            {format.toDigi(quantity)}
          </Qty>
        </Col>

        <Col { ...col.unit}>
          <Space>
            {product.unit_type}
          </Space>
        </Col>

        <Col { ...col.price}>
          <Space>
            {format.toMoney(product.original_price)} บาท
          </Space>
        </Col>

        <Col { ...col.discount}>
          <Space>
            {product.discount}
          </Space>
        </Col>

        <Col { ...col.total_price }>
          <TotalPrice>
            {format.toMoney(total)} บาท
          </TotalPrice>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 6px 0px;
  border: 0px solid #C3C3C3;
  border-bottom-width: 1px;
`

const Info = styled.div`
  display: flex;
  column-gap: 8px;
`

const Image = styled.div`
  img {
    width: 50px;
  }
`
const Detail = styled.div``

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
`

const ProductCode = styled.div`
  font-weight: 400;
  font-size: 14px;
`

const Qty = styled.div`
  padding-right: 5px;
`

const Space = styled.div`
  word-break: break-all;
  overflow-wrap: break-word;
  @media screen and (max-width: 430px) {
    margin-top: 10px;
  }
`

const TotalPrice = styled.div`
  font-weight: bold;
  color: ${(p) => p.theme.color.green};
  word-break: break-all;
  overflow-wrap: break-word;
  @media screen and (max-width: 430px) {
    margin-top: 10px;
  }
`

export default inject('order')(observer(Product))
