import { runInAction } from 'mobx'
import BaseStore from './BaseStore'

import {
  error,
  http,
  product,
} from '../utils'
import { config } from '../config'

let state
export class Find extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        page: {
          index: 1,
          per_page: 100,
          total: 0,
        },
        group_list: [],
        brand_list: [],
        category_list: [],
        list: [],
        original_list: [],
      },
    })

    state = this
  }

  async getProductList(params = {}) {
    const {
      index = 1,
      keyword,
      group_list,
      category_list,
      brand_list,
    } = params

    const { display = {} } = this.toJS()
    const { page = {} } = display

    const url = `${config.api}/v1/product/find?index=${index - 1}&limit=${page.per_page}`
    const json = {
      keyword,
      group_list,
      category_list,
      brand_list,
    }
    const resp = await http.post(url, { json })

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const { data } = resp.body
    error.isArray(data, { message: 'get product list incorrect' })

    page.index = index

    runInAction(() => {
      display.page = page
      display.list = data
      state.display = display
    })
  }

  async getProductCount(params = {}) {
    const { keyword } = params
    const url = `${config.api}/v1/product/find.count`
    const resp = await http.post(url, { json: { keyword }})

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const { data } = resp.body

    runInAction(() => {
      state.display.page.total = +data || 0
    })
  }

  getMenuCounter(name = '', code = '', list = []) {
    return list.map(item => {
      return {
        item: {
          name: item[name],
          code: item[code],
        },
        counter: item.counter,
        selected: false,
      }
    })
  }

  async getMenuProductCount(params = {}) {
    const { keyword } = params
    const url = `${config.api}/v1/product/find.menu`
    const resp = await http.post(url, { json: { keyword }})

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const { data = {} } = resp.body

    const group_list = this.getMenuCounter('group_code', 'group_code', data.group_list)
    const category_list = this.getMenuCounter('category_name', 'category_code', data.category_list)
    const brand_list = this.getMenuCounter('brand_name', 'brand_code', data.brand_list)
    runInAction(() => {
      state.display.group_list = group_list
      state.display.category_list = category_list
      state.display.brand_list = brand_list
    })
  }

  setPage({ index = 0, per_page = 50 }) {
    const { display = {} } = this.toJS()
    const { page = {} } = display

    page.index = 0
    page.per_page = per_page
    runInAction(() => {
      state.display.page = page
    })
  }

  selectBrand(brand) {
    return this.selectFilter(brand, 'brand_list', 'code')
  }

  selectCategory(category) {
    return this.selectFilter(category, 'category_list', 'code')
  }

  selectGroup(group) {
    return this.selectFilter(group, 'group_list', 'name')
  }

  selectFilter(filter, name_list, name) {
    const display = this.toJS().display
    const {
      group_list,
      category_list,
      brand_list,
    } = product.selectFilter(display, filter, name_list, name)

    runInAction(() => {
      state.display.group_list = group_list
      state.display.category_list = category_list
      state.display.brand_list = brand_list
    })

    return {
      group_list,
      category_list,
      brand_list,
    }
  }
}

export default new Find()
