import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { KeyBtn } from 'components/button'

const NoMatch = (props) => {
  const navigate = useNavigate()

  const onHome = () => navigate('/')

  return (
    <PageView>
      <Title>ขออภัยในความไม่สะดวก ไม่พบรายการ</Title>
      <Button>
        <KeyBtn text="กลับไปหน้าหลัก" onClick={onHome} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  padding-top: 40px;
`

const Title = styled.div`
  text-align: center;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
  padding-top: 24px;
`

export default NoMatch
