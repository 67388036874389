import * as admin from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

import { config } from '../config'

export const firebase = admin.initializeApp(config.firebase, 'app')
const analytics = firebase.analytics()

const view = (options = {}) => {
  analytics.logEvent('page_view', options)
}

const click = (name, options = {}) => {
  analytics.logEvent(name, options)
}

export const ga = {
  view,
  click
}

export default firebase
