import { GoogleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Button } from 'antd'

const Btn = (props) => {
  const onClick = () => {
    let { name, onClick } = props
    if (onClick) onClick(name)
  }

  let { disabled, children, text, loading } = props

  let click
  if (!loading && !disabled) {
    click = onClick
  }
  return (
    <PageView>
      <Button disabled={disabled} loading={loading} onClick={click}>
        <Icon><GoogleOutlined /></Icon> {children || text}
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  button {
    color: white;
    background-color: #D83E29;
    width: 100%;
    height: 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 0px;
    font-size: 16px;
  }

  button:hover,
  button:focus {
    color: white;
    background-color: #D83E29;
  }
`

const Icon = styled.i`
  margin-right: 4px;
`

export default Btn
