import { Button } from 'antd'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'

const CloseBtn = (props) => {
  let {
    loading = false,
    onClick,
    disabled = false,
  } = props

  let content
  let click
  if (loading === false) {
    content = <CloseOutlined />
    click = onClick
  }
  return (
    <Page>
      <Button
        shape="circle" disabled={disabled}
        loading={loading} onClick={click}>
        {content}
      </Button>
    </Page>
  )
}

const Page = styled.div`
  button {
    color: #414141;
    background-color: white;
    border: 2px solid #414141;
    height: 35px;
    width: 35px;
  }

  button > i {
    font-size: 20px;
  }

  button:hover {
    color: black;
    background-color: white;
    border-color: #414141;
  }
`

export default CloseBtn
