import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format } from 'utils'

const Summary = (props) => {
  const { order = {} } = props

  const { total_price = 0 } = order
  const tax = total_price * 0.07

  const getLine = (label, value) => {
    return (
      <Row>
        <Col { ...col.text }>
          <TextSummary>
            {label}
          </TextSummary>
        </Col>
        <Col { ...col.value }>
          <ValueSummary>
            {format.toMoney(value)} บาท
          </ValueSummary>
        </Col>
      </Row>
    )
  }

  return (
    <PageView>
      {getLine('รวมราคาสินค้า', total_price)}
      {getLine('ภาษีมูลค่าเพิ่ม',  tax)}
      {getLine('เงินรวมทั้งสิ้น', total_price + tax)}
    </PageView>
  )
}

const col = {
  text: { lg: 10, md: 10, xs: 10 },
  value: { lg: 14, md: 14, xs: 14 },
}

const PageView = styled.div`
  background-color: white;
`

const TextSummary = styled.div`
  width: 100%;
`

const ValueSummary = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${(p) => p.theme.color.green};
  text-align: right;
`

export default Summary
