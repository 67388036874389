import { useState, useCallback, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import MainLayout from './menu'

import Loading from './components/loading'
import Home from './pages/home'
import SubCategory from './pages/sub.category'
import CategoryProductList from './pages/product.list/category'
import BrandProductList from './pages/product.list/brand'

import BrandCategory from './pages/brand.category'
import Product from './pages/product'
import FindProduct from './pages/find.product'

import ResetPassword from './pages/reset.password'
import LoginPage from './pages/access/login'
import RegisterPage from './pages/access/register'

import OrderList from './pages/order.list'
import Blog from './pages/blog'
import BlogList from './pages/blog.list'

import CheckEmail from './pages/message/check.email'
import WebAction from './pages/action'

import Cookie from './pages/consent/cookie'
import Terms from './pages/consent/terms'
import Privacy from './pages/consent/privacy'
import ContactUs from './pages/consent/contactUs'
import HowToOrder from './pages/consent/howToOrder'
import RegisterCompleted from './pages/user/registerCompleted'
import UserRegister from './pages/user/register'
import OrderView from './pages/order.view'
import NoMatch from './pages/noMatch'

import AccountApp from './AccountApp'

const App = (props) => {
  const [loading, setLoading] = useState(true)

  const { user } = props.member.toJS()

  const onCheck = useCallback(async () => {
    try {
      setLoading(true)
      //await props.member.checkLoginUser()
      //await props.order.checkOrder()
      await Promise.all([
        props.member.checkLoginUser(),
        props.order.checkOrder(),
        props.product.loadHistory(),
      ])
    } catch (e) {

    }
    setLoading(false)
  }, [props.member])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="/*" element={<Loading />} />
      </Routes>
    )
  }

  if (user.uid !== '' && !user.tax_address_id) {
    return (
      <MainLayout>
        <Routes>
          <Route path="/*" element={<UserRegister />} />
        </Routes>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sub.category/:code" element={<SubCategory />} />
        <Route path="/product.list/:code" element={<CategoryProductList />} />
        <Route path="/product/:code" element={<Product />} />

        <Route path="/brand.category/:code" element={<BrandCategory />} />
        <Route path="/product.list/brand/:brandCode/:code" element={<BrandProductList />} />
        <Route path="/product/:brand/:code" element={<Product />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:id" element={<Blog />} />

        <Route path="/find" element={<FindProduct />} />
        <Route path="/password" element={<ResetPassword />} />
        <Route path="/check.email" element={<CheckEmail />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/order" element={<OrderList />} />
        <Route path="/order/:code" element={<OrderView />} />
        <Route path="/consent/cookie" element={<Cookie />} />
        <Route path="/consent/terms" element={<Terms />} />
        <Route path="/consent/privacy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/how-to-order" element={<HowToOrder />} />
        <Route path="/register-completed" element={<RegisterCompleted />} />

        <Route path="/auth/action" element={<WebAction />} />

        <Route path="/user/*" element={<AccountApp />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </MainLayout>
  )
}

export default inject('member', 'order', 'product')(observer(App))
