import React, { Component } from 'react'
import { Form as Antd } from 'antd'

export default class Form extends Component {
  onChange = (evt) => {
    let { name, onChange } = this.props
    if (onChange) {
      onChange(evt.target.value, name)
    }
  }

  render() {
    let { children } = this.props

    let config = {
      colon: false,
      ...this.props,
    }

    return (
      <Antd {...config }>
        {children}
      </Antd>
    )
  }
}
