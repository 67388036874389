import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { message } from 'utils'
import { Meta, Col } from 'components/display'

import col from '../col'
import Menu from '../Menu'
import Title from '../Title'
import EditInfo from './EditInfo'
import Info from './Info'

const UserPassword = (props) => {
  const [saving, setSaving] = useState(false)
  const [password, setPassword] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const { user } = props.member.toJS()

  const onOpen = () => setIsEdit(true)
  const onClose = () => setIsEdit(false)
  const onEdit = async () => {
    try {
      await props.member.uppdatePassword({ password })
      message.success({ message: 'บันทึกสำเร็จ' })
    } catch (e) {
      message.error({
        title: 'เปลี่ยนรหัสผ่าน',
        message: e.message,
      })
    }
  }

  const onChange = (val) => setPassword(val)

  const content = isEdit ? (
    <EditInfo user={user} loading={saving} password={password} onEdit={onEdit} onClose={onClose} onChange={onChange} />
  ) : <Info user={user} />

  const btn = isEdit ? undefined : 'เปลี่ยนรหัสผ่าน'
  return (
    <PageView>
      <Meta title="บัญชีของฉัน" />
      <Row>
        <Col { ...col.menu}>
          <Menu selected="account" />
        </Col>
        <Col { ...col.content}>
          <Title title="บัญชีของฉัน" btn={btn} onClick={onOpen} />
          {content}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0px;
`

export default inject('member')(observer(UserPassword))
