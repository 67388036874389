import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { message } from 'utils'
import { Meta, Col } from 'components/display'

import col from '../col'
import Menu from '../Menu'
import Title from '../Title'
import Info from './Info'
import EditInfo from './EditInfo'

const UserInfo = (props) => {
  const [saving, setSaving] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({})

  const { user } = props.member.toJS()

  const onOpen = () => {
    setIsEdit(true)
    setData(user)
  }
  const onClose = () => setIsEdit(false)
  const onEdit = async () => {
    try {
      setSaving(true)
      await props.member.uppdateInfo(data)
      onClose()
    } catch (e) {
      message.error({
        title: 'บันทึกที่อยู่ผู้ใช้งาน',
        message: e.message,
      })
    }
    setSaving(false)
  }

  const onChange = (val, name) => {
    data[name] = val
    setData({ ...data })
  }

  const content = isEdit ? (
    <EditInfo user={data} loading={saving} onEdit={onEdit} onClose={onClose} onChange={onChange} />
  ) : <Info user={user} />

  const btn = isEdit ? undefined : 'แก้ไขข้อมูลส่วนตัว'
  return (
    <PageView>
      <Meta title="ข้อมูลผู้ใช้ระบบ" />
      <Row>
        <Col { ...col.menu}>
          <Menu selected="information" />
        </Col>
        <Col { ...col.content}>
          <Title title="ข้อมูลผู้ใช้ระบบ" btn={btn} onClick={onOpen} />
          {content}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0px;
`

export default inject('member')(observer(UserInfo))
