import { useState } from 'react'
import styled from 'styled-components'
import { LeftCircleOutlined, RightCircleOutlined, SearchOutlined } from '@ant-design/icons'

import { color } from 'definition'

import ImgDialog from './ImgDialog'

const ProductImg = (props) => {
  const [selected, setSelected] = useState(0)
  const [visible, setVisible] = useState(false)
  const { product } = props
  const { img_list = [] } = product

  const onPrevious = () => {
    const previous = selected - 1
    if (previous >= 0) {
      setSelected(previous)
    }
  }

  const onNext = () => {
    const next = selected + 1
    if (next < img_list.length) {
      setSelected(next)
    }
  }

  const content = img_list.map((url, index) => {
    const css = { display: selected === index ? undefined : 'none' }
    return (
      <Slider style={css} key={index}>
        <img src={url} alt="" />
      </Slider>
    )
  })

  const menus = img_list.map((url, index) => {
    return selected === index ? (
      <MenuActiveItem src={url} key={index} />
    ) : (
      <MenuItem src={url} key={index} onClick={() => setSelected(index)} />
    )
  })

  let nextBtn
  let backBtn
  if (img_list.length > 1) {
    backBtn = (
      <PreviousBtn onClick={onPrevious}>
        <LeftCircleOutlined style={{ color: color.green }}  />
      </PreviousBtn>
    )

    nextBtn = (
      <NextBtn onClick={onNext}>
        <RightCircleOutlined style={{ color: color.green }}  />
      </NextBtn>
    )
  }
  return (
    <PageView>
      {backBtn}
      <SlideShow>
        <Find onClick={() => setVisible(true)}>
          <SearchOutlined style={{ color: color.green }}  />
        </Find>

        {content}
      </SlideShow>
      <MenuList>
        {menus}
      </MenuList>

      {nextBtn}

      <ImgDialog
        index={selected}
        visible={visible}
        onClose={() => setVisible(false)}
        img_list={img_list} />
    </PageView>
  )
}

const PageView = styled.div`
  position: relative;
`

const PreviousBtn = styled.span`
  font-size: 28px;
  position: absolute;
  left: 8px;
  top: 38%;
  z-index: 5;
  cursor: pointer;
`

const NextBtn = styled.span`
  font-size: 28px;
  position: absolute;
  right: 8px;
  top: 38%;
  z-index: 5;
  cursor: pointer;
`

const MenuList = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  img {
    margin: 0px 4px;
  }
`

const MenuActiveItem = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: solid 2px ${color.bg};
  object-fit: cover;
  margin: 0px;
  cursor: pointer;
`

const MenuItem = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: solid 2px transparent;
  object-fit: cover;
  margin: 0px;
  filter: brightness(0.5);
  cursor: pointer;
`

const Slider = styled.div`
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border: 1px solid #C4C3C3;
    border-radius: 4px;
  }

  @-webkit-keyframes {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`

const SlideShow = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;

  i {
    font-size: 24px;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 3px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    i {
      font-size: 20px;
      top: 5px;
      right: 5px;
    }
  }

  @media screen and (max-width: 430px) {
    i {
      font-size: 24px;
    }
  }
`

const Find = styled.span`
  font-size: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 3px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  

  @media screen and (max-width: 768px) {
    top: 5px;
    right: 5px;
  }

  @media screen and (max-width: 430px) {
    font-size: 24px;
  }
`

export default ProductImg
