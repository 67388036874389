/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = 'white' } = props

  const width = `${Math.ceil(16 * size)}`
  const height = `${Math.ceil(17 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.46967 5.96967C2.76256 5.67678 3.23744 5.67678 3.53033 5.96967L8 10.4393L12.4697 5.96967C12.7626 5.67678 13.2374 5.67678 13.5303 5.96967C13.8232 6.26256 13.8232 6.73744 13.5303 7.03033L8.53033 12.0303C8.38968 12.171 8.19891 12.25 8 12.25C7.80109 12.25 7.61032 12.171 7.46967 12.0303L2.46967 7.03033C2.17678 6.73744 2.17678 6.26256 2.46967 5.96967Z" fill={color}/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
