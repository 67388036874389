import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { Meta } from 'components/display'
import { message } from 'utils'
import Loading from 'components/loading/Loading'

import HeaderOrder from './HeaderOrder'
import Information from './Information'

const OrderView = (props) => {
  const [loading, setLoading] = useState(false)
  const { code } = useParams()

  const getOrder = useCallback(async () => {
    try {
      setLoading(true)
      await props.order.getOrder({ code })

    } catch (e) {
      message.error({
        title: 'แสดงรายการคำสั่งซื้อ',
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.order, code])

  useEffect(() => {
    getOrder()
  }, [getOrder, code])

  const order = props.order.getHistoryOrder()
  return (
    <PageView>
      <Meta title="รายละเอียดการสั่งซื้อ" />
      <Loading loading={loading}>
        <HeaderOrder />

        <OrderContent>
          <Title>ใบเสนอราคา Quotation</Title>
          <Information order={order} />

          <Note>หมายเหตุ</Note>
          <Remark>
            ** ใบเสนอราคานี้ยังไม่สามารถอ้างอิงราคาได้โปรด นำเลขที่ใบเสนอราคานี้มาติดต่อที่ไลน์: @PRATHAN เพื่อทำใบเสนอราคาพร้อมเช็คสต็อกก่อนทำการสั่งซื้อ
          </Remark>
        </OrderContent>
      </Loading>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 24px 0px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: ${(p) => p.theme.color.green};
`

const OrderContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 4px;
`

const Note = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 16px;
  font-weight: 700;
`

const Remark = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 8px;
`

export default inject('order')(observer(OrderView))
