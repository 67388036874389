import { runInAction } from 'mobx'
import BaseStore from './BaseStore'

import { error, http } from '../utils'
import { config } from '../config'

let state
export class Blog extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
        list: [],
      },
      doc: {},
    })

    state = this
  }

  async getList(params = {}) {
    const { index = 1 } = params

    const display = this.toJS().display
    const per_page = display.page.per_page
    let q = `?index=${index - 1}&limit=${per_page}`

    const url = `${config.api}/v1/blog${q}`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isArray(data)

    display.page.index = index
    display.page.per_page = per_page
    display.list = data

    runInAction(() => {
      state.display = display
    })
  }

  async getCountList() {
    const url = `${config.api}/v1/blog/count`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    runInAction(() => {
      state.display.page.total = +resp.body.data || 0
    })
  }

  async getBlog(id) {
    const url = `${config.api}/v1/blog/${id}/information`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    runInAction(() => {
      state.doc = resp.body.data
    })
  }
}

export default new Blog()
