import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { KeyBtn, Btn } from 'components/button'
import { Col } from 'components/display'

import { helper } from 'utils'

import DisplayInfo from '../DisplayInfo'
import EditUser from './EditUser'

const func = () => {}

const EditUserInfo = (props) => {
  const [validate, setValidate] = useState({})
  const [isInvalidate, setIsInvalidate] = useState(false)

  const {
    user = {},
    loading,
    onEdit = func,
    onClose = func,
    onChange = func,
  } = props

  const onChangeItem = (val, name) => {
    onChange(val, name)

    delete validate[name]
    setValidate({ ...validate })
  }

  const onSave = () => {
    const valid_list = [
      'name',
      'surname',
      'mobile',
    ]

    const { invalid, isInvalid } = helper.checkValue(user, valid_list)
    if (isInvalid) {
      setValidate(invalid)
      setIsInvalidate(!isInvalid)
      return
    }

    setValidate({})
    setIsInvalidate(false)
    onEdit()
  }

  return (
    <PageView>
      <DisplayInfo title="ข้อมูลระบบ">
        <EditUser doc={user} validate={validate} onChange={onChangeItem} />

        <Space />
        <Row>
          <Col lg={6} md={8} xs={24} position="left-responsive">
            <KeyBtn loading={loading} text="บันทึก" onClick={onSave} />
          </Col>
          <Col lg={6} md={8} xs={24} position="right-responsive">
            <Btn text="ยกเลิก" onClick={onClose} />
          </Col>
        </Row>
      </DisplayInfo>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  padding-top: 24px;
`

export default EditUserInfo
