import member from './Member'
import category from './Category'
import brand from './Brand'
import product from './Product'
import order from './Order'
import user from './User'
import master from './Master'
import find from './Find'
import consent from './Consent'
import blog from './Blog'

export default {
  member,
  category,
  brand,
  product,
  order,
  user,
  master,
  find,
  consent,
  blog,
}
