import styled from 'styled-components'
import { Input as InputAntd } from 'antd'

const Input = (props) => {
  const {
    placeholder,
    readonly = false,
    clear = true,
    disabled = false,
    name,
    value = '',
    onPressEnter,
  } = props

  const onChange = (evt) => {
    if (props.onChange) {
      props.onChange(evt.target.value, name)
    }
  }

  return (
    <PageView>
      <InputAntd
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        allowClear={clear} />
    </PageView>
  )
}

const PageView = styled.div`
  .ant-input-affix-wrapper {
    height: 48px;
  }
  input {
    width: 100%;
    height: 48px;
    text-indent: 4px;
  }
`


export default Input
