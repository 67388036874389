import styled from 'styled-components'
import { Row, Col } from 'antd'

import Item from './Item'

const Detail = (props) => {
  const { product = {} } = props

  const product_list = product.product_list || []
  const content_list = []
  let index = 0
  for (const it of product_list) {
    const v = product[it.name] || ''
    if (v !== '') {
      const position = index % 4
      content_list.push(
        <Col { ...col } key={ index }>
          <Item name={it.value} value={v} position={position} />
        </Col>
      )
      index++
    }
  }

  return (
    <PageView>
      <Name>คุณสมบัติสินค้า</Name>

      <Row>
        {content_list}
      </Row>
    </PageView>
  )
}

const col = {
  xl: 12, lg: 12, md: 12, xs: 24,
}

const PageView = styled.div`
  padding-top: 24px;
`

const Name = styled.h2`
  font-size: 20px;
`

export default Detail
