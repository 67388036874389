import { RightCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Link } from 'components/link'
import { Row } from 'antd'

import { Col } from 'components/display'
import { format } from 'utils'

import col from './col'

const OrderItem = (props) => {
  const {
    item = {},
  } = props

  const tax = item.total_price * 0.07
  return (
    <Link to={`/user/order/${item.order_code}`}>
      <Line>
        <Row>
          <Col { ...col.code }>
            <Code>
              {item.order_code}
            </Code>
          </Col>
          <Col { ...col.date }>
           {format.toFullDT(item.created_at)}
          </Col>
          <Col { ...col.quantity }>
            {format.toDigi(item.quantity)} ชิ้น
          </Col>
          <Col { ...col.remark }>
            <CellContent>
              {item.remark}
            </CellContent>
          </Col>
          <Col { ...col.price }>
            <Price>
              {format.toMoney(item.total_price + tax)} บาท
            </Price>
          </Col>

          <Col { ...col.next }>
            <NextIcon>
              <RightCircleOutlined style={{ color: '#003e31' }} />
            </NextIcon>
          </Col>
        </Row>
      </Line>
    </Link>
  )
}

const Line = styled.div`
  padding: 16px 0px;
  border: 0px solid #C8C8C8;
  border-bottom-width: 1px;
  font-size: 14px;
`

const NextIcon = styled.span`
  color: ${(p) => p.theme.color.green};
`

const CellContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Code = styled.div`
  font-weight: 700;
`

const Price = styled.div`
  color: ${(p) => p.theme.color.green};
  font-weight: 700;
`

export default OrderItem
