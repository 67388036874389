import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Link } from 'components/link'
import { format } from 'utils'
import { Cart } from 'icon'

const CartView = (props) => {
  const {
    current = {},
  } = props.order.toJS()

  return (
    <Link to="/order">
      <Bag>
        <Num>{format.toDigi(current.quantity)}</Num>
        <Cart />
      </Bag>
    </Link>
  )
}

const Bag = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 120px;
  display: flex;
  justify-content: flex-end;
  column-gap: 6px;
`

const Num = styled.span`
  color: white;
  font-size: 16px;
  border-radius: 40px;
  width: 60px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: end;
`

export default inject('order')(observer(CartView))
