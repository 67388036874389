import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { helper } from 'utils'
import { KeyBtn, Btn } from 'components/button'
import { Col } from 'components/display'
import EditAddress from 'pages/user/information/address/EditAddress'

const EditAddressInfo = (props) => {
  const [validate, setValidate] = useState({})

  const { loading, doc = {}, address = {}, onBack, onNext, onChange, onAddress } = props

  const onSave = () => {
    const valid_list = [
      'address',
      'province',
      'district',
      'tumbon',
      'postcode',
    ]

    if (doc.tax_type === 'company') {
      valid_list.push('company_name')
      valid_list.push('social_no')
    } else if (doc.tax_type === 'personal') {
      valid_list.push('name')
    }

    if (doc.branch_type === 'sub') {
      valid_list.push('branch_code')
    }

    doc.province = address.province
    doc.district = address.district
    doc.tumbon = address.tumbon
    doc.postcode = address.postcode

    const { invalid, isInvalid } = helper.checkValue(doc, valid_list)
    if (isInvalid) {
      setValidate(invalid)
      return
    }

    setValidate({})
    onNext()
  }

  return (
    <PageView>
      <EditAddress
        loading={loading}
        doc={doc}
        address={address}
        validate={validate}
        onChange={onChange}
        onAddress={onAddress} />

      <Button>
        <Row>
          <Col lg={12} md={12} xs={24} position="left-responsive">
            <Btn text="กลับ" onClick={onBack} />
          </Col>
          <Col lg={12} md={12} xs={24} position="right-responsive">
            <KeyBtn loading={loading} text="ยืนยันการสมัคร" onClick={onSave} />
          </Col>
        </Row>
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
`

const Button = styled.div`
  width: 100%;
  padding-top: 24px;
  max-width: 430px;
  margin: 0 auto;
`

export default EditAddressInfo
