const config = {
  mode: 'production',
  firebase: {
    apiKey: 'AIzaSyC3hfrQ2HHuiotbg31uqs4s8dLyjdaCv3Y',
    authDomain: 'prathan.co.th',//'prathan-web.firebaseapp.com',
    projectId: 'prathan-web',
    storageBucket: 'prathan-web.appspot.com',
    messagingSenderId: '588940452328',
    appId: '1:588940452328:web:51d892425d2afa8e77445d',
    measurementId: 'G-PBFXERPQNP'
  },
  api: 'https://api.prathan.co.th/api',
  host: 'https://prathan.co.th',
  version: '1.0',
}

module.exports = config
