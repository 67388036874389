import { Modal, message as msg } from 'antd'

export class Message {
  async error({ title, message = 'The operation incomplete.' }) {
    Modal.error({
      title,
      content: message,
    })
  }

  async warning({ title, message }) {
    Modal.warning({
      title,
      content: message,
    })
  }

  async success({ message }) {
    msg.success(message)
  }
}

export const message = new Message()
export default message
