import { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Link } from 'components/link'
import { format } from 'utils'

import Up from './Up'
import Down from './Down'
import col from './col'
import Info from './Info'

const Product = (props) => {
  const [open, setOpen] = useState(false)
  const { item, isFirst, isList, prefix_url } = props

  const onToggle = () => setOpen(!open)
  const {
    name,
    img_list = [],
    product_code,
    unit_type,
    brand_name,
  } = item

  const link = `${prefix_url}/${product_code}?name=${name}`
  const price = format.toMoney(item.price)

  const content = open ? <Info item={item} prefix_url={prefix_url} /> : undefined
  const cssFirst = isFirst ? 'first' : ''
  const css = open ? `active ${cssFirst}` : cssFirst
  const icon = open ? <Up /> : <Down />
  const product_name = (
    <Link to={link} isUnderline={true}>
      <Name className={css}>
        {name}
      </Name>
    </Link>
  )

  const isListView = isList === 'list'
  const img = isListView && !open ? (
    <Col { ...col.img}>
      <Link to={link}>
        <Image src={img_list.length > 0 ? img_list[0] : undefined} />
      </Link>
    </Col>
  ) : undefined

  const col_name = isListView && !open ? col.img_name : col.name
  return (
    <PageView>
      <ContentView className={css}>
        <Row align="middle">
          {img}
          <Col { ...col_name}>
            {product_name}
          </Col>

          <Col { ...col.brand}>
           {brand_name}
          </Col>

          <Col { ...col.price}>
            <Price className={css}>
              {price} บาท / {unit_type}
            </Price>
          </Col>

          <Col { ...col.btn}>
            <BtnView className={css} onClick={onToggle}>
              รายละเอียด {icon}
            </BtnView>
          </Col>
        </Row>
      </ContentView>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  color: #333333;
  background-color: white;
  font-size: 14px;
  border: 0px solid #EBEBEB;
`

const ContentView = styled.div`
  padding: 4px 0px;
  background-color: white;
  border: 0px solid #EBEBEB;
  border-top-width: 1px;

  &.active {
    color: white;
    background-color: #003e31;
  }

  &.first {
    border-top-width: 0px;
  }
`

const BtnView = styled.div`
  text-align: right;
  padding-right: 16px;
  color: #003e31;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 4px;
  }

  &.active {
    color: white;
    background-color: #003e31;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Price = styled.div`
  color: #003e31;

  &.active {
    color: white;
    background-color: #003e31;
  }

  @media screen and (max-width: 500px) {
    padding-left: 12px;
  }
`

const Name = styled.div`
  padding-left: 12px;
  padding-right: 4px;

  &.active {
    color: white;
  }
`

const Image = styled.img`
  width: 100%;
  padding-left: 8px;
`

export default Product
