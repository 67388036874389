import styled from 'styled-components'

const DisplayInfo = (props) => {
  const { title, children } = props

  const content = title ? <Title>{title}</Title> : undefined
  return (
    <PageView>
      {content}
      {children}
    </PageView>
  )
}

const PageView = styled.div`
  padding: 24px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  background-color: white;
  border: 0px solid #C8C8C8;
  border-bottom-width: 1px;
  padding-bottom: 16px;
  margin-bottom: 16px;
`

export default DisplayInfo
