import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'

import Loading from './Loading'

const LoadingDialog = (props) => {
  const {
    loading = false,
  } = props

  return (
    <Modal
      title=""
      footer=""
      visible={loading}
      className="loading-view"
      centered={false}
      closable={false}
      width="100%" >
      <PageView>
        <Content>
          <Loading loading={loading} />
          <Text>กรุณารอสักครู่</Text>
        </Content>
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  height: 100vh;
  padding-top: 18%;
`

const Content = styled.div`
  text-align: center;
  color: black;
  background-color: white;
  width: 250px;
  height: auto;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
`

const Text = styled.div`
  margin-top: 15px;
`

export default LoadingDialog
