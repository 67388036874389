import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import BaseStore from './BaseStore'

import {
  error,
  http,
  product,
  storage,
} from 'utils'
import { config } from '../config'

const sortName = (a, b) => {
  if (a < b) return -1
  if (b > b) return 1
  return 0
}
const toFilterItem = (item = {}, list = []) => {
  const { name } = item
  const data = {}
  for (const group of list) {
    for (const product of group.list) {
      const val = product[name] || ''
      data[val] = data[val] ? data[val] + 1 : 1
    }
  }

  delete data['']
  const names = Object.keys(data)
  names.sort(sortName)

  const result_list = names.map(name => {
    return {
      item: { name },
      counter: data[name],
      selected: false,
    }
  })

  return result_list
}

const toFilterList = (filters, list) => {
  const filter_list = []
  for (const item of filters) {
    const data_list = toFilterItem(item, list)
    if (data_list.length > 0) {
      filter_list.push({
        name: item.name,
        title: item.value,
        list: data_list,
      })
    }
  }

  return filter_list
}

const original = {
  category: {},
  group_list: [],
  brand_list: [],
  filter_list: [],
  list: [],
  original_list: [],
}

let state
export class Product extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: cloneDeep(original),
      doc: {},
      history_list: [],
      relate: {
        suggestion_list: [],
        replacement_list: [],
        pair_list: [],
      }
    })

    state = this
  }

  async getListByCategory(params = {}, brand_list = []) {
    runInAction(() => {
      state.display = cloneDeep(original)
    })

    const { code } = params
    const url = `${config.api}/v1/product/${code}/category`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    const { category, list = [] } = data || {}
    error.isArray(list, { message: 'get category list incorrect' })

    const filters = category.filter_list || []
    const filter_list = toFilterList(filters, list)

    runInAction(() => {
      state.display = {
        category,
        group_list: product.getGroupList(list),
        brand_list: product.getFilterList(list, brand_list, 'brand_code'),
        filter_list,
        list,
        original_list: list
      }
    })
  }

  async getListByBrandAndCategory(params = {}, brand_list = []) {
    runInAction(() => {
      state.display = cloneDeep(original)
    })
    const { b_code = '', c_code = '' } = params

    const url = `${config.api}/v1/product/${b_code}/${c_code}/find`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    const { category, list = [] } = data || {}
    error.isArray(list, { message: 'get category list incorrect' })

    const filters = category.filter_list || []
    const filter_list = toFilterList(filters, list)

    runInAction(() => {
      state.display = {
        category,
        group_list: product.getGroupList(list),
        brand_list: product.getFilterList(list, brand_list, 'brand_code'),
        filter_list,
        list,
        original_list: list
      }
    })
  }

  async getProduct(params = {}) {
    const { code } = params
    runInAction(() => {
      state.doc = {}
    })

    const url = `${config.api}/v1/product/${code}`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isNull(data.product_id, { message: 'get product incorrect' })

    runInAction(() => {
      state.doc = data
    })

    this.addHistory(data)
  }

  async getRelateProduct(params = {}) {
    runInAction(() => {
      state.relate = {
        suggestion_list: [],
        replacement_list: [],
        pair_list: [],
      }
    })

    const { code } = params
    const url = `${config.api}/v1/product-group/${code}`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    const {
      suggestion_list = [],
      replacement_list = [],
      pair_list = [],
    } = data || {}

    runInAction(() => {
      state.relate = {
        suggestion_list,
        replacement_list,
        pair_list,
      }
    })
  }

  initSelection(query = {}) {
    const { group, brand } = query
    const brand_list = brand ? brand.split(',') : []
    const group_list = group ? group.split(',') : []

    let display = this.toJS().display
    display.brand_list = product.selectedByIndex(display.brand_list, brand_list)
    display.group_list = product.selectedByIndex(display.group_list, group_list)
    display.filter_list = product.selectedFilterByIndex(display.filter_list, query)

    const nDisplay = product.setFilterProduct(display)
    runInAction(() => {
      state.display = nDisplay
    })
  }

  select(item, name) {
    const display = this.toJS().display
    const { filter_list } = display

    const index = filter_list.findIndex(it => it.name === name)
    if (index === -1) {
      return filter_list
    }

    const { list } = filter_list[index]
    const value = item.name
    const i = list.findIndex(it => it.item.name === value)
    if (i === -1) {
      return filter_list
    }

    const selected = list[i].selected
    list[i].selected = !selected
    filter_list[index].list = list

    display.filter_list = filter_list
    const nDisplay = product.setFilterProduct(display)
    runInAction(() => {
      state.display = nDisplay
    })

    return filter_list
  }

  selectBrand(brand) {
    const display = this.selectFilter(brand, 'brand_list', 'code')

    return display.brand_list
  }

  selectGroup(group) {
    const display = this.selectFilter(group, 'group_list', 'name')

    return display.group_list
  }

  selectFilter(filter, name_list, name) {
    const display = this.toJS().display
    const nDisplay = product.selectFilter(display, filter, name_list, name)

    runInAction(() => {
      state.display = nDisplay
    })

    return nDisplay
  }

  addHistory(product = {}, current_list) {
    const history_list = current_list ? current_list : this.toJS().history_list

    const doc = history_list.find((it => it.product_id === product.product_id))
    if (doc) {
      return doc
    }

    history_list.splice(0, 0, product)
    const list = history_list.slice(0, 5)

    const ids = list.map((it) => it.product_id)

    storage.save('product', { history_list: ids })
    runInAction(() => {
      state.history_list = list
    })
  }

  async loadHistory() {
    const storage_data = await storage.load('product')
    if (storage_data) {
      const history_list = storage_data.history_list || []
      if (history_list.length > 0) {
        await this.getHistoryList(history_list)
      }
    }
  }

  async getHistoryList(product_list = []) {
    const url = `${config.api}/v1/product/history`
    const resp = await http.put(url, { json: { product_list }})

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    const history_list = data || []
    runInAction(() => {
      state.history_list = history_list
    })
  }
}

export default new Product()
