import { runInAction } from 'mobx'
import BaseStore from './BaseStore'

import { error, http, brand } from '../utils'
import { config } from '../config'

let state
export class Brand extends BaseStore {
  constructor() {
    super()
    this.observable({
      loading: false,
      all_list: [],
      grouped: {},
      display: {
        character: '#',
        list: [],
      },
      filter_brand: {},
      brand_categories: [],
    })

    state = this
  }

  async getImageList(list = []) {
    const load_list = []
    for (const { img_url } of list) {
      if (img_url) {
        load_list.push(http.get(img_url))
      }

      if (load_list.length > 9) {
        await Promise.all(load_list)
      }
    }

    if (load_list.length > 0) {
      await Promise.all(load_list)
    }
  }

  async getList(params = {}) {
    const all_list = this.toJS().all_list
    if (all_list.length > 0) return all_list

    runInAction(() => {
      state.loading = true
    })
    const url = `${config.api}/v1/brand`
    const resp = await http.get(url)
    runInAction(() => {
      state.loading = false
    })

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isArray(data, { message: 'something incorrect' })

    const tmpGrouped = brand.groupByFirstCharacter(data)

    runInAction(() => {
      state.all_list = data
      state.grouped = tmpGrouped
      state.display = {
        character: 'All',
        list: data,
      }
    })

    return data
  }

  selectedCharacter(params={}){
    const { character } = params
    const { grouped = {}, all_list = [] } = this.toJS()

    runInAction(() => {
      state.display = {
        character: character,
        list: character === 'All' ? all_list : grouped[character]
      }
    })
  }

  async getCategoryByCode(cate_code, brand_code) {
    const all_list = this.toJS().all_list
    if (all_list.length <= 0)
      await this.getList()

    await this.getBrandCategories(brand_code)

    const { brand_categories = [] } = this.toJS()
    const category = brand_categories.find(it => it.code === cate_code)

    return category || {}
  }

  async getBrandByCode(code) {
    const { all_list = [] } = this.toJS()
    const brand = all_list.find(it => it.code === code)

    return brand || {}
  }

  async getBrandCategories(code){
    runInAction(() => {
      state.loading = true
    })
    const url = `${config.api}/v1/brand/category/${code}`
    const resp = await http.get(url)

    runInAction(() => {
      state.loading = false
    })

    error.isRespError(resp)

    const { data } = resp.body
    error.isArray(data, { message: 'พบข้อผิดพลาด' })

    const hitbrand = await this.getBrandByCode(code)

    runInAction(() => {
      state.filter_brand = hitbrand
      state.brand_categories = data
    })

    return data
  }
}

export default new Brand()
