import styled from 'styled-components'

const Footer = (props) => {
  const { onRegister, onLogin } = props

  return (
    <MainMenu>
      <Btn onClick={onRegister}>สมัครสมาชิก</Btn>
      <LoginBtn onClick={onLogin}>เข้าสู่ระบบ</LoginBtn>
    </MainMenu>
  )
}

const MainMenu = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Btn = styled.div`
  font-size: 18px;
  width: 150px;
  height: 58px;
  color: ${(p) => p.theme.color.green};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const LoginBtn = styled.div`
  font-size: 18px;
  width: 150px;
  height: 58px;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
`

export default Footer
