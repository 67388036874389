const color = {
  red: '#ED3034',
  yellow: '#EDAE30',
  blue: '#305FED',
  green: '#003e31',
  grey: '#414141',
  lightGrey: '#d0d1d3',

  success: '#00C767',
  error: '#ED3034',
  warning: '#EDAE30',
  action: '#305FED',
  disable: '#DCDCDC',
  backgroundColor: '#F5F6F9',
  border: '#d3d6db',
}

const input = {
  disable: '#F5F6F9',
}

const color_level = {
  red: {
    high: '#D70004',
    medium: '#ED3034',
    low: '#F55759',
  },

  yellow: {
    high: '#D78F00',
    medium: '#EDAE30',
    low: '#F5BF57',
  },

  blue: {
    high: '#134EE4',
    medium: '#305FED',
    low: '#577AF5',
  },

  green: {
    high: '#00B377',
    medium: '#00C767',
    low: '#13E44E',
  },

  grey: {
    dark: '#252525',
    high: '#494949',
    medium: '#8A8A8A',
    low: '#BBBBBB',
    light: '#DCDCDC',
  },
}

const media = {
  mobile_xs: '@media screen and (max-width: 350px)',
  mobile: '@media screen and (max-width: 465px)',
  tablet: '@media screen and (max-width: 800px)',
  pc: '@media screen and (max-width: 1024px)',
}

const theme = {
  max: '1440px',
  input,
  color,
  color_level,
  media,
}

export default theme
