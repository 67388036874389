import { runInAction } from 'mobx'
import BaseStore from './BaseStore'
import { cloneDeep } from 'lodash'

import { error, firebase, http, storage } from 'utils'
import { config } from 'config'

const original = {
  uid: '',
  provider: {},
  img_url: undefined,
  email: '',
  name: '',
  surname: '',
  role: '',
  tax_address_id: null,
}

let state
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      verifying: false,
      user: cloneDeep(original),
      firebase: {},
      email: '',
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.verifying = false
      state.user = cloneDeep(original)
      state.firebase = {}
    })
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged((user) => {
        resolve(user)
      })
    })
  }

  async checkLoginUser() {
    runInAction(() => {
      state.verifying = true
    })
    try {
      let user = await this.getCurrentUser()
      if (user && user.emailVerified) {
        runInAction(() => {
          state.firebase = user
        })
        await this.getUserProfile({ user })
      }
    } catch (e) {

    }
    runInAction(() => {
      state.verifying = false
    })
  }

  getProvider({ user }) {
    const list = user.providerData || []
    return list.map(item => item.providerId)
  }

  setUserProfile({ user, updated }) {
    if (user) {
      runInAction(() => {
        state.user = user
      })
    } else if (updated) {
      const old = this.toJS().user
      if (old.uid === updated.uid) {
        runInAction(() => {
          state.user = updated
        })
      }
    }
  }

  async getUserProfile(params = {}) {
    const { user } = params
    if (user.emailVerified === false) {
      error.lunch({ message: 'กรุณายืนยัน email ของท่าน' })
      return
    }

    const token = await user.getIdToken()
    await http.setToken(token)

    const url = `${config.api}/auth/login.customer`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.statusCode === 400 ? resp.body.message : 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
    })
    if (isErr) return

    const data = resp.body.data
    await this.setUserProfile({ user: data.user })
  }

  async uppdateInfo(params = {}) {
    const url = `${config.api}/v1/customer/user/info`
    const resp = await http.put(url, { json: params })

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const data = resp.body.data
    this.setUserProfile({ user: data })
  }

  isLogin() {
    return this.toJS().user.uid !== ''
  }

  async logout() {
    await firebase.auth().signOut()
    await http.setToken()
    await this.reset()
  }

  async checkEmail({ email = '', mode }) {
    let auth = firebase.auth()
    email = email.trim()
    let resp = await auth.fetchSignInMethodsForEmail(email)

    let have = resp.length > 0
    return have
  }

  async loginByEmail(params = {}) {
    let { remember, email, password } = params
    email = email.trim()
    const resp = await this.checkEmail({ email })
    if (resp === false) {
      error.lunch({ message: `This's email ${email} not found` })
      return
    }

    if (remember) {
      await storage.save('remember', { remember, email })
    } else {
      await storage.remove('remember')
    }

    const auth = firebase.auth()
    await auth.signInWithEmailAndPassword(email, password)
    const user = auth.currentUser
    return this.getUserProfile({ user })
  }

  async loginByGoogle({ code } = {}) {
    const auth = firebase.auth()
    // Start a sign in process for an unauthenticated user.
    const provider = new firebase.firebase_.auth.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')

    const result = await auth.signInWithPopup(provider)
    if (result) {
      const { user } = result
      if (user) {
        return await this.registerNewJoinByService({ user, code })
      }
    }

    return false
  }

  async singupByEmail(params = {}) {
    let { email = '', password } = params
    await this.logout()

    email = email.trim()
    const json = {
      email,
      password,
    }
    const url = `${config.api}/v1/public/customer/email`
    const resp = await http.post(url, { json })

    error.isRespError(resp)

    const {
      status,
    } = resp.body.data
    error.isError(status !== 'created', { message: 'อีเมลนี้เป็นสมาชิกอยู่แล้ว' })

    await this.resetPassword(email)

    runInAction(() => {
      state.email = email
    })
  }

  async checkActionCode(params = {}) {
    const { code } = params

    const auth = firebase.auth()
    await auth.checkActionCode(code)
  }

  async applyCodeToResetPassword(params = {}) {
    const { code, password } = params

    const auth = firebase.auth()
    await auth.verifyPasswordResetCode(code)
    await auth.confirmPasswordReset(code, password)
  }

  async applyCodeToVerifyEmail(params = {}) {
    const { code } = params

    const auth = firebase.auth()
    await auth.applyActionCode(code)
  }

  async registerNewJoinByService(params = {}) {
    let { user } = params

    if (!user) return

    let {
      email, uid,
      displayName, phoneNumber,
      photoURL,
    } = user

    const provider = this.getProvider({ user })

    const json = {
      email,
      uid,
      mobile: phoneNumber ? phoneNumber : '',
      name: displayName ? displayName : '',
      img_url: photoURL ? photoURL : '',
      provider,
    }
    const url = `${config.api}/v1/public/customer/service`
    const resp = await http.post(url, { json })

    const isErr = error.isRespError(resp,
      {
        url,
        message: resp.statusCode === 400 ? resp.body.message : 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
      })
    if (isErr) return false

    const newDoc = resp.body.data
    error.isNull(newDoc.customer_id, { url, res: resp })

    const token = await user.getIdToken()
    await http.setToken(token)

    runInAction(() => {
      state.user = newDoc
      state.firebase = user
    })

    return true
  }

  async resetPassword(email) {
    const auth = firebase.auth()
    await auth.sendPasswordResetEmail(email)
  }

  async resendRegister(email) {
    const auth = firebase.auth()
    await auth.sendPasswordResetEmail(email)
  }

  async getRemember() {
    const data = await storage.load('remember')
    return data || {}
  }

  async uppdatePassword({ password } = {}) {
    const url = `${config.api}/v1/customer/user/password`
    const resp = await http.put(url, { json: { password } })

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const { token } = resp.body.data || {}

    const { user: fbUser } = await firebase.auth().signInWithCustomToken(token)

    if (fbUser) {
      const tokenId = await fbUser.getIdToken()
      await http.setToken(tokenId)
    }
  }

  async deleteAccount() {
    const url = `${config.api}/v1/customer/user/delete`
    const resp = await http.put(url)

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const data = resp.body.data
    runInAction(() => {
      state.user = data
    })
  }

  async recoverAccount() {
    const url = `${config.api}/v1/customer/user/recover`
    const resp = await http.put(url)

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const data = resp.body.data
    runInAction(() => {
      state.user = data
    })
  }
}

export default new Member()
