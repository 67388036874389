import styled, { useTheme } from 'styled-components'

import { Remove } from 'icon'
import { format } from 'utils'

import Invalid from './Invalid'

const Input = (props) => {
  const theme = useTheme()
  const {
    id,
    name,
    label,
    value = '',
    tag,
    disabled = false,
    clear = false,
    pattern,
    charPattern,
    isNumber,
    limit = 900000,
    onChange = () => {},
    invalid,
  } = props

  const onValue = (evt) => {
    if (onChange) {
      let val = evt.target.value
      if (pattern) {
        val = format.clearPattern(val, charPattern)
      }

      if (isNumber && val !== '') {
        val = val.trim()
        const tmp = +val
        val = tmp === 0 || tmp ? val : value
      }

      if (val.length <= limit) {
        onChange(val, name, tag)
      }
    }
  }

  const onClear = () => {
    if (onChange) {
      onChange(isNumber ? '0' : '', name, tag)
    }
  }

  const invalidCss = invalid ? 'invalid' : ''
  const suffix =
    value === '' || clear === false ? undefined : (
      <Btn onClick={onClear}>
        <Remove color={theme.color_level.grey.high} />
      </Btn>
    )

  const text = pattern ? format.toPattern(value, pattern) : value + ''

  return (
    <>
      <Content className={disabled ? 'disabled' : ''}>
        <Form className={disabled && text.length > 0 ? 'active' : ''}>
          <InputValue
            id={id}
            className={invalidCss}
            type="text"
            autocomplete="false"
            required
            disabled={disabled}
            value={text}
            onChange={onValue}
          />
          <Label htmlFor="name" className="label-name">
            <ContentName className={`content-name ${invalidCss}`}>
              {label}
            </ContentName>
          </Label>
          {suffix}
        </Form>
      </Content>
      <Invalid invalid={invalid} />
    </>
  )
}

const Content = styled.div`
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  padding: 6px 10px;

  &.disabled {
    background-color: ${(p) => p.theme.input.disable};
  }
`

const Form = styled.div`
  position: relative;
  &.active input:disabled + .label-name .content-name,
  input:focus + .label-name .content-name,
  input:valid + .label-name .content-name {
    transform: translateY(-80%);
    color: ${(p) => p.theme.color_level.grey.medium};
    bottom: 2px;
  }
`

const InputValue = styled.input`
  width: 100%;
  height: 30px;
  padding: 0px;
  border: none;
  outline: none;
  line-height: 32px;

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }

  &:disabled {
    background-color: ${(p) => p.theme.input.disable};
  }
`

const Label = styled.label`
  position: absolute;
  bottom: 9px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-size: 14px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
  }
`

const ContentName = styled.span`
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: all 0.3s ease;
  color: ${(p) => p.theme.color_level.grey.high};

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }
`

const Btn = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 10px;
`

export default Input
