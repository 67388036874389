import { format, thbText } from 'utils'

const Body = (props) => {
  const { order = {} } = props
  const { product_list = [] } = order

  const RowItem = (props) => {
    const {
      index,
      item = {},
    } = props

    const {
      product,
      quantity,
    } = item

    const {
      original_price,
      discount_price,
    } = product

    const total = format.toMoney(discount_price * quantity)
    const css = { pageBreakInside: 'auto', verticalAlign: 'baseline' }
    const paddingRight = '5px'
    const css_right = { textAlign: 'right', pageBreakInside: 'auto', verticalAlign: 'baseline' }
    return (
      <tr style={{ fontSize: '12px', pageBreakInside: 'avoid' }}>
        <td style={css}>
          <div style={{ paddingRight, textAlign: 'right' }}>
            {index + 1}
          </div>
        </td>
        <td style={css}>
          {product.barcode}
        </td>
        <td style={css}>
          {product.name}
        </td>
        <td style={css_right}>
          <div style={{ paddingRight, textAlign: 'right' }}>
            {quantity}
          </div>
        </td>
        <td style={css}>
          {product.unit_type}
        </td>
        <td style={css_right}>
          {format.toMoney(original_price)}
        </td>
        <td style={css_right}>
          {product.discount}
        </td>
        <td style={css_right}>
          {total}
        </td>
      </tr>
    )
  }

  const rows = product_list.map((item, i) => {
    return <RowItem key={i} index={i} item={item} />
  })


  // Summary
  let price_total = 0
  product_list.forEach((item, i) => {
    const {
      quantity,
      product,
    } = item
    const sum = format.precision(product.discount_price * quantity)
    price_total += sum
  })

  const tax_cost = format.precision((price_total * 7) / 100)
  const total = format.precision(price_total + tax_cost)
  const dot = '............................'

  // css
  const css_summary_row = { height: '18px', verticalAlign: 'bottom' }
  const css_summary_cell = { textAlign: 'left' }

  const textAlign = 'right'
  const pageBreakInside = 'auto'
  const css = {
    no: { width: '5%', textAlign, pageBreakInside },
    code: { width: '15%', textAlign: 'left', pageBreakInside },
    name: { width: '29%', textAlign: 'left', pageBreakInside },
    num: { width: '9%', textAlign, pageBreakInside },
    unit: { width: '9%', textAlign: 'left', pageBreakInside },
    price: { width: '11%', textAlign, pageBreakInside  },
    discount: { width: '10%', textAlign, pageBreakInside  },
    total: { width: '12%', textAlign, pageBreakInside  }
  }
  return (

    <div className='body'
      style={{
        boxSizing: 'border-box',
        fontFamily: 'Arial',
        fontSize: '10px',
        color: '#000000',
        width: '100%',
        padding: '0px 10px'
      }}
    >
      <table
        style={{
          tableLayout: 'fixed',
          boxSizing: 'border-box',
          width: '100%',
          fontFamily: 'Arial',
          fontSize: '11px',
          borderCollapse: 'collapse',
          pageBreakAfter: 'auto',
        }}>
          <thead style={{ display: 'table-header-group', verticalAlign: 'bottom' }}>
            <tr style={{ borderBottom: '1px solid black', pageBreakInside: 'avoid' }}>
              <th style={{ ...css.no }}>
                ลำดับ
              </th>
              <th style={{ ...css.code }}>
                รหัสสินค้า
              </th>

              <th style={{ ...css.name }}>
                รายการ
              </th>
              <th style={{ ...css.num }}>
                จำนวน
              </th>

              <th style={{ ...css.unit }}>
                หน่วยนับ
              </th>
              <th style={{ ...css.price }}>
                ราคาขาย (บาท)
              </th>

              <th style={{ ...css.discount }}>
                ส่วนลด
              </th>
              <th style={{ ...css.total }}>
                จำนวนเงิน (บาท)
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
      </table>

      <table
        style={{
          boxSizing: 'border-box',
          width: '100%',
          fontFamily: 'Arial',
          fontSize: '12px',
          borderCollapse: 'collapse',
          marginTop: '50px',
          pageBreakInside: 'avoid',
          textAlign: 'right',
          verticalAlign: 'bottom'
        }}>

        <tr style={ css_summary_row }>
          <td colSpan='2'></td>
          <td>รวมราคาสินค้า:</td>
          <td style={{ borderBottom: '1px dotted black' }}>
            <b>{ format.toMoney(price_total) } บาท</b>
          </td>
        </tr>
        <tr style={ css_summary_row }>
          <td colSpan='2' style={ css_summary_cell }>
            <b>หมายเหตุุ</b>
          </td>
          <td>ภาษีมูลค่าเพิ่ม:</td>
          <td style={{ borderBottom: '1px dotted black' }}>
            <b>{ format.toMoney(tax_cost) } บาท</b>
          </td>
        </tr>
        <tr style={ css_summary_row }>
          <td colSpan='2' style={ css_summary_cell }>
            ({ thbText.toTextMoney(total) })
          </td>
          <td>จำนวนเงินรวมทั้งสิ้น:</td>
          <td style={{ borderBottom: '1px dotted black' }}>
            <b>{ format.toMoney(total) } บาท</b>
          </td>
        </tr>

        <tr style={ css_summary_row }>
          <td colSpan='4'></td>
        </tr>

        <tr style={ css_summary_row }>
          <td>ผู้เสนอราคา</td>
          <td style={ css_summary_cell }>{ dot }</td>
          <td>ผู้อนุมัติ</td>
          <td style={ css_summary_cell }>{ dot }</td>
        </tr>
        <tr style={ css_summary_row }>
          <td>วันที่</td>
          <td style={ css_summary_cell }>{ dot }</td>
          <td>วันที่</td>
          <td style={ css_summary_cell }>{ dot }</td>
        </tr>

        <tr style={ css_summary_row }>
          <td colSpan='4'></td>
        </tr>

        <tr style={ css_summary_row }>
          <td colSpan='4' style={{ fontSize: '10px', textAlign: 'center' }}>
            ** ใบเสนอราคานี้ยังไม่สามารถอ้างอิงราคาได้โปรด นำเลขที่ใบเสนอราคานี้มาติดต่อที่ไลน์: @PRATHAN เพื่อทำใบเสนอราคาพร้อมเช็คสต็อกก่อนทำการสั่งซื้อ
          </td>
        </tr>
      </table>

    </div>

  )
}

export default Body
