import React, { Component } from 'react'
import styled from 'styled-components'

import { Link } from '../components/link'

export default class Main extends Component {
  render() {
    return (
      <PageView>
        <Title>
          <Link to="/">
            ประธานการไฟฟ้า
          </Link>
        </Title>
        <Detail>
        <div>บริษัท ประธานการไฟฟ้า จำกัด</div>
        329,331 ถ.พระรามที่ 2 แขวงแสมดำ เขตบางขุนเทียน กทม. 10150 (ปากซอยพระราม2 67)
        </Detail>
      </PageView>
    )
  }
}

const PageView = styled.div`
  padding 0px 10px;
  padding-right: 40px;
  @media screen and (max-width: 768px) {
    padding 0px;
  }
`

const Title = styled.p`
  font-size: 22px;
  margin-bottom: 5px;
`

const Detail = styled.p`
  font-size: 14px;
`
