
export class Helper {
  sleep(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  isNull(val) {
    return val === null || val === undefined
  }

  notNull(val) {
    return val !== null && val !== undefined
  }

  isValue(val) {
    return val !== null && val !== undefined && val !== ''
  }

  notValue(val) {
    return val === null || val === undefined || val === ''
  }

  isEmpty(val) {
    let list = Object.keys(val)
    return val === null || val === undefined || val === '' || list === 0 || list.length === 0
  }

  toPercent(a, b) {
    if (a === 0 || b === 0) return 0

    return a / b
  }

  getFromWidth({ lg = '40%', md = '60%', xs = '90%'}) {
    const width = window.innerWidth
    if (width <= 450) return xs
    else if (width <= 800) return md

    return lg
  }

  random({ len, prefix = '', postfix = '', character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', }) {
    let need = len - (prefix.length + postfix.length)
    let max = character.length
    if (need < 0) return
    if (max === 0) return
    let resp = ''

    for (let i = 0; i < need; i++ ) {
      resp += character.charAt(Math.floor(Math.random() * max));
    }
    return `${prefix}${resp}${postfix}`
  }

  getUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      if (prefix === 'http' || prefix === 'data') return val
    }

    return undefined
  }

  isUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      return prefix === 'http'
    }

    return false
  }

  trim(data, list = []) {
    list.forEach(name => {
      let temp = data[name]
      if (temp) data[name] = temp.trim()
    })

    return data
  }

  toUrl(img_url = '') {
    const url = img_url === '' || img_url === null ? '/hlo.png' : img_url

    return url
  }

  upItem(index, list = []) {
    const current = list[index]
    const next = list[index - 1]

    list[index] = next
    list[index - 1] = current
    return list
  }

  downItem(index, list = []) {
    const current = list[index]
    const next = list[index + 1]

    list[index] = next
    list[index + 1] = current
    return list
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  getDistanceInKm(lat1,lon1,lat2,lon2) {
    const R = 6371 // Radius of the earth in km
    const dLat = this.deg2rad(lat2-lat1)  // deg2rad below
    const dLon = this.deg2rad(lon2-lon1)
    const a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    const d = R * c * 1000 // Distance in meter
    return d
  }

  getUser({ user_id = 0, member = {}, user_list = [] } = {}) {
    if (member.user_id === user_id) {
      return member
    }

    const resp = user_list.find(it => it.user_id === user_id)
    return resp || {}
  }

  checkValue(data = {}, name_list = []) {
    const invalid = {}
    const size = name_list.length
    for (let i = 0; i < size; i++) {
      const name = name_list[i]
      const val = data[name] || ''
      const value = val.trim()
      if (value === '') invalid[name] = true
    }

    const s = Object.keys(invalid).length
    return { invalid, isInvalid: s > 0 }
  }

  getError(isError, msg) {
    return isError ? msg : undefined
  }
}

export const helper = new Helper()
export default helper
