import numeral from 'numeral'

const MAX_POSITION = 6
const UNIT_POSITION = 0
const TEN_POSITION = 1

const PRIMARY_UNIT = 'บาท'
const SECONDARY_UNIT = 'สตางค์'
const WHOLE_NUMBER_TEXT = 'ถ้วน'

const NUMBER_TEXTS = 'ศูนย์,หนึ่ง,สอง,สาม,สี่,ห้า,หก,เจ็ด,แปด,เก้า,สิบ'.split(',')
const UNIT_TEXTS = 'สิบ,ร้อย,พัน,หมื่น,แสน,ล้าน'.split(',')

export class ThbText {
  getIntegerDigits = numberInput => parseInt(numberInput.split('.')[0], 10).toString()
  getFractionalDigits = numberInput => parseInt(numberInput.split('.')[1], 10).toString()

  hasFractionalDigits = numberInput => numberInput !== undefined && numberInput !== '0'

  isZeroValue = number => number === '0'
  isUnitPosition = position => position === UNIT_POSITION
  isTenPosition = position => position % MAX_POSITION === TEN_POSITION
  isMillionsPosition = position => (position >= MAX_POSITION && position % MAX_POSITION === 0)

  isLastPosition(position, lengthOfDigits) { 
    return position + 1 < lengthOfDigits
  }

  reverseNumber(number) {
    const numberStr = number.toString()
    return numberStr.split('').reverse().join('')
  }

  getBathUnit(position, number) {
    let unitText = ''

    if (!this.isUnitPosition(position)) {
      unitText = UNIT_TEXTS[Math.abs(position - 1) % MAX_POSITION]
    }

    if (this.isZeroValue(number) && !this.isMillionsPosition(position)) {
      unitText = ''
    }

    return unitText
  }

  getBathText(position, number, lengthOfDigits) {
    let numberText = NUMBER_TEXTS[number]

    if (this.isZeroValue(number)) {
      return ''
    }

    if (this.isTenPosition(position) && number === '1') {
      numberText = ''
    }

    if (this.isTenPosition(position) && number === '2') {
      numberText = 'ยี่'
    }

    if (this.isMillionsPosition(position) && this.isLastPosition(position, lengthOfDigits) && number === '1') {
      numberText = 'เอ็ด'
    }

    if (lengthOfDigits === 2 && this.isLastPosition(position, lengthOfDigits) && number === '1') {
      numberText = 'เอ็ด'
    }

    if (lengthOfDigits > 1 && this.isUnitPosition(position) && number === '1') {
      numberText = 'เอ็ด'
    }

    return numberText
  }

  // convert function without async
  convert(numberInput) {
    const numberReverse = this.reverseNumber(numberInput)
    let textOutput = ''
    // console.log('>', numberReverse.split(''))
    numberReverse.split('').forEach((number, i) => {
      textOutput = `${this.getBathText(i, number, numberReverse.length)}${this.getBathUnit(i, number)}${textOutput}`
    })
    return textOutput
  }

  parseFloatWithPrecision(number, precision = 2) {
    const numberFloatStr = parseFloat(number).toString().split('.')
    const integerUnitStr = numberFloatStr[0]
    const fractionalUnitStr = (numberFloatStr.length === 2) ? numberFloatStr[1].substring(0, precision) : '00'
    return parseFloat(`${integerUnitStr}.${fractionalUnitStr}`).toFixed(precision)
  }

  toTextMoney(number) {
    let money
    if (number === undefined || number === null || number === '') {
      money = '0'
    } else { 
      money = numeral(number).format('0.00')
    }

    const numberStr = this.parseFloatWithPrecision(money)
    
    const integerDigits = this.getIntegerDigits(numberStr)
    const fractionalDigits = this.getFractionalDigits(numberStr)
    
    const intTextOutput = this.convert(integerDigits)
    
    const textOutput = []
    if (intTextOutput) {
      textOutput.push(`${[intTextOutput, PRIMARY_UNIT].join('')}`)
    }
    if (intTextOutput && !this.hasFractionalDigits(fractionalDigits)) {
      textOutput.push(WHOLE_NUMBER_TEXT)
    }
    if (this.hasFractionalDigits(fractionalDigits) && this.convert(fractionalDigits)) {
      textOutput.push(`${[this.convert(fractionalDigits), SECONDARY_UNIT].join('')}`)
    }

    return textOutput.join('')
  }  
}

export const thbText = new ThbText()
export default thbText
