export class Brand {
  groupByFirstCharacter(brands_list){
    const regex = /^[a-zA-Z]*$/;
    let grouped = brands_list.reduce(function(acc, curr) {
      const brandName = curr.name
      let isSpecial = regex.test(brandName.charAt(0));

      if (!isSpecial) {
        acc['#'].push(curr)
      } else if (acc.hasOwnProperty(brandName.charAt(0))) {
        acc[brandName.charAt(0)].push(curr)
      } else {
        acc[brandName.charAt(0)] = [curr]
      }
      return acc;

    }, {
      '#': []
    })
    
    return grouped
  }

  selectFilter(display, filter, name_list, name) {
    const list = display[name_list]

    const index = list.findIndex(it => it.item[name] === filter[name])
    if (index === -1) return display

    const item = list[index]
    item.selected = !item.selected

    display[name_list] = list
    return this.setFilterProduct(display)
  }

  setFilterBrand(display = {}) {
    const {
      brand_list = [],
      category_list = [],
      group_list = [],
      original_list = [],
    } = display

    const brand_code_list = brand_list.filter(it => it.selected).map(it => it.item.code)
    const category_code_list = category_list.filter(it => it.selected).map(it => it.item.code)
    const group_name_list = group_list.filter(it => it.selected).map(it => it.item.name)

    display.list = this.filterProduct({
      original_list,
      brand_code_list,
      category_code_list,
      group_name_list,
    })

    return display
  }
}

export const brand = new Brand()
export default brand