import React from 'react'
import styled from 'styled-components'
import { Pagination as Pagina  } from 'antd'

import { format } from '../../utils/format'

const Pagination = (props) => {
  const onChange = (val) => {
    if (props.onChange) props.onChange(val)
  }

  const { style, text, page = {}} = props
  const {
    index = 1,
    total = 0,
    per_page = 1,
  } = page

  return (
    <Page style={style}>
      <Pagina
        showTotal={total => `${text} ${format.toDigi(total)}`}
        showQuickJumper
        current={index}
        pageSize={per_page}
        total={total}
        onChange={onChange} />
    </Page>
  )

}

const Page = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`

export default Pagination
