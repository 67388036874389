import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { KeyBtn, Btn } from 'components/button'

import BaseDialog from './BaseDialog'

const ConfirmDialog = (props) => {
  const { visible, title = '', message = '', onClose, onConfirm } = props

  const titleContent = title !== '' ? <Title>{title}</Title> : undefined
  return (
    <BaseDialog isHeader={false} visible={visible} onClose={onClose}>
      <Content>
        <Body>
          {titleContent}
          <Detail>{message}</Detail>
        </Body>

        <Row>
          <Col span={12} position="left">
            <Btn text="ยกเลิก" onClick={onClose} />
          </Col>
          <Col span={12} position="right">
            <KeyBtn text="ตกลง" onClick={onConfirm} />
          </Col>
        </Row>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 0px;

  ${(p) => p.theme.media.mobile_xs} {
    min-width: 240px;
  }
`

const Body = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 24px;
`

const Title = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`

const Detail = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 16px;
`

export default ConfirmDialog
