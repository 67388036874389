import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { Meta } from 'components/display'
import Loading from 'components/loading/Loading'
import { HtmlContent } from 'components/display'
import Breadcrumb from 'components/breadcrumb'

const Blog = (props) => {
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const { id } = useParams()
  const { doc = {} } = props.blog.toJS()

  const onGetDoc = useCallback(async (blog_id) => {
    try {
      setLoading(true)
      await props.blog.getBlog(blog_id)
    } catch (e) {
    }

    setLoading(false)
  }, [props.blog])

  useEffect(() => {
    onGetDoc(id)
  }, [onGetDoc, id])

  const { blog_id, title, short_content, content, img_url, status } = doc
  const { display } = queryString.parse(location.search)

  const menu = { link: '/blog', name: 'บทความทั้งหมด' }
  if (status === 'inactive' && !display || !blog_id) {
    return (
      <PageView>
        <Breadcrumb list={[menu]} name="ไม่พบบทความ" />
        <NotFound>ไม่พบบทความ</NotFound>
      </PageView>
    )
  }
  return (
    <PageView>
      <Meta loading={loading} title={title} description={short_content} url={img_url} />
      <Breadcrumb list={[menu]} name={title} />
      <Loading loading={loading} />
      <Title>{title}</Title>
      <HtmlContent content={content} />
    </PageView>
  )
}

const PageView = styled.div`
  padding: 24px;
  background-color: white;
  min-height: 80vh;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  padding: 24px 0px;
`

const NotFound = styled.div`
  font-size: 24px;
  text-align: center;
  color: grey;
  padding: 24px 0px;
`

export default inject('blog')(observer(Blog))
