import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Link } from 'components/link'
import { Btn } from 'components/button'
import { Input } from 'components/input'
import { format, product, ga } from 'utils'

const Card = (props) => {
  const [quantity, setQuantity] = useState(1)
  const { item = {}, prefix_url } = props

  const { name, product_code, unit_type, img_list = [] } = item
  const price = format.toMoney(item.price)

  const onChange = (val) => {
    setQuantity(val)
  }

  const onAddOrder = () => {
    props.order.addProduct(item, quantity)
    props.order.setVisible(true)
    ga.click(`add product by suggestion info ${quantity}`)
  }

  const link = `${prefix_url}/${product_code}?name=${name}`

  const img = img_list.length > 0 ? (
    <Link to={link}>
      <Image src={img_list[0]} />
    </Link>
  ) : undefined

  const isDisabled = product.isDisabled(item.status)
  const text = isDisabled ? 'สินค้าหมด' : 'เพิ่มลงรถเข็น'

  return (
    <BlockView>
      <CardView>
        {img}
        <Link to={link} isUnderline={true}>
          <Name>{name}</Name>
        </Link>
        <PriceLine>
          <Label>ราคา</Label>
          <Price>{price} บาท / {unit_type}</Price>
        </PriceLine>

        <Row align="bottom">
          <Col lg={8} md={8} xs={24}>
            <Label>จำนวน</Label>
            <Quantity>
              <Input value={quantity} onChange={onChange} isNumber={true} />
            </Quantity>
          </Col>
          <Col lg={16} md={16} xs={24}>
            <Btn text={text} disabled={isDisabled} onClick={onAddOrder} />
          </Col>
        </Row>
      </CardView>
    </BlockView>
  )
}

const BlockView = styled.div`
  ${(p) => p.theme.media.mobile} {
    width: 48%;
  }
`

const CardView = styled.div`
  padding: 16px;
  width: 220px;
  border: 1px solid #aaaaaa;
  background-color: white;

  ${(p) => p.theme.media.mobile} {
    width: 100%;
  }
`

const Name = styled.div`
  color: #454545;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  padding-top: 8px;
  margin-bottom: 8px;

  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
`

const Image = styled.img`
  width: 100%;
  max-height: 166px;
  border-radius: 4px;
  object-fit: contain;
`

const Label = styled.div`
  width: 40px;
  font-size: 14px;
  color: #6C6C6C;
`

const Quantity = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-right: 8px;

  ${(p) => p.theme.media.mobile} {
    padding-right: 0px;
    padding-bottom: 8px;
  }
`

const PriceLine = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Price = styled.div`
  width: calc(100% - 40px);
  font-size: 14px;
  font-weight: 700;
  color: #003e31;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default inject('order')(observer(Card))
