/* eslint-disable */
const Logo = (props) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M58.8437 52.0077L33.7364 8.06998C32.9686 6.72635 31.0312 6.72635 30.2634 8.06998L5.15614 52.0077C4.39425 53.341 5.35698 55 6.89263 55H57.1072C58.6428 55 59.6056 53.341 58.8437 52.0077ZM35.4729 7.0777C33.9373 4.39044 30.0625 4.39044 28.5269 7.0777L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H57.1072C60.1785 57 62.104 53.6821 60.5802 51.0154L35.4729 7.0777ZM29.9999 21C29.9999 19.8954 30.8954 19 31.9999 19C33.1045 19 33.9999 19.8954 33.9999 21V37C33.9999 38.1046 33.1045 39 31.9999 39C30.8954 39 29.9999 38.1046 29.9999 37V21ZM31.9999 48C33.6568 48 34.9999 46.6569 34.9999 45C34.9999 43.3431 33.6568 42 31.9999 42C30.3431 42 28.9999 43.3431 28.9999 45C28.9999 46.6569 30.3431 48 31.9999 48Z" fill="#494F60"/>
    </svg>

  )
}

// eslint-enable
export default Logo
