import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Btn } from 'components/button'
import { Col } from 'components/display'
import { Delete } from 'icon'
import { ConfirmDialog, Dialog } from 'dialog'
import { message } from 'utils'

import DisplayInfo from '../DisplayInfo'
import Line from '../Line'

import RemoveDialog from './RemoveDialog'

const UserInfo = (props) => {
  const [dialog, setDialog] = useState('')
  const [loading, setLoading] = useState(false)

  const { user } = props

  const onClose = () => setDialog('')
  const onConfirmRemove = () => setDialog('remove')
  const onConfirmRecover = () => setDialog('recover')

  const removeAccount = async () => {
    onClose()
    try {
      setLoading(true)
      await props.member.deleteAccount()
      setDialog('completed')
    } catch (e) {
      message.error({
        title: 'ลบบัญชี',
        message: e.message,
      })
    }
    setLoading(false)
  }

  const recoverAccount = async () => {
    onClose()
    try {
      setLoading(true)
      await props.member.recoverAccount()
    } catch (e) {
      message.error({
        title: 'กู้บัญชี',
        message: e.message,
      })
    }
    setLoading(false)
  }

  const icon = <Text><Delete />ลบบัญชี</Text>
  const isActive = user.status === 'active'
  const btn = isActive
    ? <Btn loading={loading} text={icon} onClick={onConfirmRemove} />
    : <Btn loading={loading} text="เก็บบัญชีไว้" onClick={onConfirmRecover} />

  const note = isActive ? '' : <Note>กำลังดำเนินการลบบัญชีของคุณ</Note>
  const msg = (
    <Msg>
      <div>บัญชีของคุณจะถูกลบภายใน 7 วัน ถ้าหากคุณต้องการ</div>
      <div>จะเปิดบัญชีนี้ต่อไป กรุณากดปุ่ม <span>เก็บบัญชีไว้</span> ที่หน้าบัญชีของฉัน</div>
    </Msg>
  )

  return (
    <PageView>
      <DisplayInfo>
        <Line label="อีเมล" value={user.email} />
      </DisplayInfo>

      <Space />
      <Row>
        <Col lg={6} md={8} xs={24}>
          {btn}
        </Col>
      </Row>
      {note}
      <RemoveDialog
        visible={dialog === 'remove'}
        onClose={onClose}
        onConfirm={removeAccount} />
      <Dialog
        visible={dialog === 'completed'}
        content={msg}
        onClose={onClose} />
      <ConfirmDialog
        visible={dialog === 'recover'}
        message="ต้องการเก็บบัญชีของคุณไว้ต่อไปหรือไม่?"
        onClose={onClose}
        onConfirm={recoverAccount} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  padding-top: 24px;
`

const Text = styled.span`
  svg {
    margin-right: 8px;
    top: 4px;
    position: relative;
  }
`

const Msg = styled.div`
  text-align: center;
  padding: 24px 0px;
  span {
    color: ${(p) => p.theme.color.green};
  }
`

const Note = styled.div`
  padding-top: 8px;
`

export default inject('member')(observer(UserInfo))
