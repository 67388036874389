import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Meta, Pagination } from 'components/display'
import Loading from 'components/loading/Loading'
import Breadcrumb from 'components/breadcrumb'

import Detail from './Detail'

const Blog = (props) => {
  const [loading, setLoading] = useState(false)

  const { display = {} } = props.blog.toJS()
  const list = display.list || []

  const onGetList = useCallback(async (c) => {
    try {
      setLoading(true)

      await props.blog.getCountList()
      await props.blog.getList()
    } catch (e) {
    }

    setLoading(false)
  }, [props.blog])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const onPage = async (index) => {
    try {
      setLoading(true)

      await props.blog.getList({ index })
    } catch (e) {
    }

    setLoading(false)
  }

  const content = list.map((it, i) => {
    return (
      <Col lg={8} md={12} xs={24} key={i}>
        <Detail item={it} />
      </Col>
    )
  })

  return (
    <PageView>
      <Meta title="บทความทั้งหมด" />
      <Breadcrumb name="บทความทั้งหมด" />

      <Title>บทความ</Title>
      <Loading loading={loading} />
      <Row>
        {content}
      </Row>
      <PaginationArea>
        <Pagination
          text="จำนวนทั้งหมด"
          page={display.page}
          onChange={onPage} />
      </PaginationArea>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 24px;
  background-color: white;
  min-height: 80vh;
`

const Title = styled.div`
  font-size: 24px;

  text-align: center;
  padding-bottom: 24px;
`
const PaginationArea = styled.div`
  padding-top: 32px;
`

export default inject('blog')(observer(Blog))
