import styled from 'styled-components'

import { InputArea } from 'components/input'

const Note = (props) => {
  const { order = {}, onRemark } = props

  return (
    <PageView>
      <InputArea
        label="บันทึกช่วยจำ"
        value={order.remark}
        onChange={onRemark} />
    </PageView>
  )
}

const PageView = styled.div``

export default Note
