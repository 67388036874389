import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { Meta, Col } from 'components/display'
import { message, ga } from 'utils'

import OrderInfo from './orderInfo'
import Note from './Note'
import Summary from './Summary'

const OrderList = (props) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const order = props.order.getCurrentOrder()
  const isLogin = props.member.isLogin()

  const onCheckout = async () => {
    try {
      setLoading(true)

      const code = await props.order.createOrder()
      setLoading(false)

      ga.click('checkout order')
      if (code) {
        navigate(`/user/order/${code}`)
      }

    } catch (e) {
      message.error({ message: e.message })
      setLoading(false)
    }
  }

  const onChange = (val, name) => {
    order[name] = val
    props.order.setCurrentOrder(order)
  }

  const onRemark = (remark) => {
    order.remark = remark
    props.order.setCurrentOrder(order)
  }

  return (
    <PageView>
      <Meta title="ตะกร้าสินค้า" />
      <Title>ตะกร้าสินค้า</Title>
      <Row>
        <Col { ...col.info }>
          <OrderInfo order={order} onChange={onChange} />
          <Note order={order} onRemark={onRemark} />
        </Col>
        <Col { ...col.summary }>
          <SummaryArea>
            <Summary loading={loading} isLogin={isLogin} order={order} onCheckout={onCheckout} />
          </SummaryArea>
        </Col>
      </Row>
    </PageView>
  )
}

const col = {
  info: {
    lg: { span: 16, order: 1 },
    md: { span: 14, order: 1 },
    xs: { span: 24, order: 1 },
  },
  summary: {
    lg: { span: 8, order: 2 },
    md: { span: 10, order: 2 },
    xs: { span: 24, order: 2 },
  },
}

const PageView = styled.div`
  width: 100%;
  padding: 40px 0px;
`

const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 16px;
`

const SummaryArea = styled.div`
  margin-left: 24px;

  ${(p) => p.theme.media.mobile} {
    margin-left: 0px;
  }
`

export default inject('member', 'order')(observer(OrderList))
