import styled from 'styled-components'

import { Link } from 'components/link'

const BlogDetail = (props) => {
  const { item } = props
  const { blog_id, title, short_content } = item
  const img_url = item.img_url || ''
  const img = img_url === '' ? <NoImg /> : <Img src={img_url} alt={title} />
  const link = `/blog/${blog_id}`
  return (
    <Link to={link}>
      <PageView>
        {img}
        <Title>{title}</Title>
        <Detail>{short_content}</Detail>
      </PageView>
    </Link>
  )
}

const PageView = styled.div`
  width: 95%;
  margin: 0 auto;
`

const Title = styled.div`
  font-weight: bold;
  padding-top: 16px;
  font-size: 24px;
`

const Detail = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 80px;
  font-size: 18px;
`

const Img = styled.img`
  width: 100%;
  object-fit: fill;
`

const NoImg = styled.div`
  height: 250px;
`

export default BlogDetail
