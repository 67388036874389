import numeral from 'numeral'
import moment from 'moment'

export class Format {
  isNull(val) {
    if (val === undefined || val === null || val === '') return true
    else return false
  }

  toInt(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0')
  }

  toDigi(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0')
  }

  precision(val) {
    const n = new Number(val).toFixed(2)
    return +n || 0
  }

  toMoney(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0.00')
  }

  getMoneyWord(val) {
    const v = val % 10
    switch (v) {
      case 1:
        return 'หนึ่ง'
      case 2:
        return 'สอง'
      case 3:
        return 'สาม'
      case 4:
        return 'สี่'
      case 5:
        return 'ห้า'
      case 6:
        return 'หก'
      case 7:
        return 'เจ็ด'
      case 8:
        return 'แปด'
      case 9:
        return 'เก้า'
      default:
        return 'ศูนย์'
    }
  }

  toTextMoney(val) {
    let money = +val || 0
    money = Math.floor(money * 100) / 100
    const list = ['บาท', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']
    let txt = ''
    let precision = +(`${money}`.split('.')[1]) || 0

    let tmp = Math.floor(money)
    let len = list.length
    let unit_val = 10
    for (let i = 0; i < len; i++) {
      const unit = list[i]
      const v = tmp % unit_val
      const t = this.getMoneyWord(v)

      txt = `${t}${unit}${txt}`
      tmp = Math.floor(tmp / 10)
      if (tmp <= 0) break
    }

    const precision_list = ['สตางค์', 'สิบ']
    len = precision_list.length
    let precision_txt = ''
    if (precision > 0) {
      for (let i = 0; i < len; i++) {
        const unit = precision_list[i]
        const v = precision % unit_val
        const t = this.getMoneyWord(v)

        precision_txt = `${t}${unit}${precision_txt}`
        precision = Math.floor(precision / 10)
        if (precision <= 0) break
      }
    }

    return txt + precision_txt
  }

  toText(val) {
    if (this.isNull(val)) return '-'
    else return val
  }

  toFullDT(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD MMM YYYY HH:mm')
  }

  toShortDay(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('DD')
  }

  toShortMonth(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('MM')
  }

  toDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD/MM/YYYY')
  }

  toNameDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD MMM YYYY')
  }

  toWeekDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('dddd')
  }

  toTime(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('HH:mm')
  }

  limitText(text, size = 100) {
    text = text ? text : ''
    if (text.length <= size) return text

    return text.substr(0, size - 3) + '..'
  }

  upperText(text = '') {
    if (text.length === 0) return text
    return text[0].toUpperCase() + text.substring(1)
  }

  displayName(user) {
    const { name = '', surname = '', nickname = '' } = user
    if (nickname && nickname !== '') {
      return `${name || ''} (${nickname})`
    } else {
      return `${name || ''} ${surname || ''}`
    }
  }

  toPattern(value, pattern) {
    if (!value) return ''

    let valueIndex = 0
    const list = String(pattern).split('')
    const newList = []

    for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
      if (list[i] === 'x') {
        newList.push(value[valueIndex++])
      } else {
        newList.push(list[i])
      }
    }

    return newList.join('')
  }

  clearPattern(text = '', ch = '-') {
    const value = (text || '').replaceAll(ch, '')

    return value
  }

  toMobile(mobile) {
    return this.toPattern(mobile, 'xxx-xxxx-xxxx')
  }
}

export const format = new Format()
export default format
