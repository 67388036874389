import styled from 'styled-components'

import { format } from 'utils'

import DisplayInfo from '../DisplayInfo'
import Line from '../Line'

const UserInfo = (props) => {
  const { user } = props

  const text = `${user.name} ${user.surname}`
  return (
    <PageView>
      <DisplayInfo title="ข้อมูลระบบ">
        <Line label="ชื่อ / นามสกุล" value={text} />
        <Line label="เบอร์โทรศัพท์" value={format.toMobile(user.mobile)} />
      </DisplayInfo>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default UserInfo
