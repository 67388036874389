import styled from 'styled-components'

import { Meta } from 'components/display'
import Brand from 'pages/home/brand'
import Category from 'pages/home/category'

const Home = (props) => {
  return (
    <PageView>
      <Meta title="ประธานการไฟฟ้า" description="จำหน่ายอุปรกณ์ไฟฟ้า" />
      <Category />
      <Brand />
    </PageView>
  )
}

const PageView = styled.div`
  text-align: center;
  padding: 24px 0px;
`

export default Home
