import styled from 'styled-components'
import { Breadcrumb } from 'antd'

import { Link } from 'components/link'

const BreadcrumbView = (props) => {
  const {
    list = [],
    name,
  } = props

  const menu = list.map((it, index) => {
    const { link, name } = it

    return (
      <Breadcrumb.Item key={index}>
        <Link to={link}>
          {name}
        </Link>
      </Breadcrumb.Item>
    )
  })


  return (
    <PageView>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {menu}
        <Breadcrumb.Item>{name}</Breadcrumb.Item>
      </Breadcrumb>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 5px 0px;
`

export default BreadcrumbView
