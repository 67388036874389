import styled from 'styled-components'

import { Plus, Minus } from 'icon'

const Quantity = (props) => {
  const { name, value, onChange, min = 1 } = props

  const onPlus = () => onChange(value + 1, name)
  const onMinus = () => onChange(value - 1, name)
  const onUpdate = (e) => {
    const val = e.target.value
    if (val === '') {
      onChange(0, name)
      return
    }

    const newValue = +val ? +val : value
    onChange(newValue, name)
  }

  const isMin = value <= min
  return (
    <View>
      <Btn className={isMin ? 'disabled' : ''} onClick={isMin ? undefined : onMinus}>
        <Minus color={isMin ? '#C8C8C8' : '#6C6C6C'} />
      </Btn>
      <Digi>
        <Input value={value} onChange={onUpdate} />
      </Digi>
      <Btn onClick={onPlus}>
        <Plus />
      </Btn>
    </View>
  )
}

const View = styled.div`
  font-size: 14px;
  display: flex;
`

const Btn = styled.div`
  font-size: 14px;
  width: 32px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C8C8C8;
  cursor: pointer;

  &.disabled {
    background-color: #F3F3F3;
  }
`

const Input = styled.input`
  font-size: 14px;
  width: 100%;
  border: 0px solid #AAAAAA;
  border-top-width: 1px;
  border-bottom-width: 1px;

  :focus {
    border: 0px solid #AAAAAA;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
`

const Digi = styled.div`
  font-size: 14px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Quantity
