import styled from 'styled-components'
import { color } from '../../definition'

const AddBtn = (props) => {
  const {
    text = 'เพิ่มลงรถเข็น',
    onClick,
    disabled,
  } = props

  const css = disabled ? 'disabled' : ''
  return (
    <PageView className={css} onClick={disabled ? undefined : onClick }>
      {text}
    </PageView>
  )
}

const PageView = styled.div`
  border-radius: 5px;
  color: white;
  background-color: ${color.bg};
  border: 1px solid ${color.bg};
  font-weight: 700;
  font-size: 14px;

  padding: 4px 10px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    background-color: #C8C8C8;
    border: 1px solid #C8C8C8;
    cursor: default;
  }
`

export default AddBtn
