import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { AddBtn } from 'components/button'
import { InputXs } from 'components/input'
import { Link } from 'components/link'
import { format, product, ga } from 'utils'

const ProductInfo = (props) => {
  const [quantity, setQuantity] = useState(1)
  const { item, prefix_url } = props

  const onChange = (val) => {
    setQuantity(val ? val : 1)
  }

  const onAddProduct = () => {
    props.order.addProduct(item, quantity)
    props.order.setVisible(true)
    ga.click(`add product by list ${quantity}`)
  }

  const getLine = (label, text) => {
    return (
      <Text>
        <label>{label}</label>
        {text}
      </Text>
    )
  }

  const {
    name,
    status,
    product_code,
    barcode,
    brand_name,
    unit_type,
  } = item

  const img_list = item.img_list || []
  const img_url = img_list.length > 0 ? img_list[0] : undefined
  const link = `${prefix_url}/${product_code}?name=${name}`
  const price = format.toMoney(item.price)

  const isDisabled = product.isDisabled(status)
  const text = isDisabled ? 'สินค้าหมด' : 'เพิ่มลงรถเข็น'
  const note = product.getStatusNote(status)
  const content = note === '' ? undefined : (
    <Note>หมายเหตุ: {note}</Note>
  )
  return (
    <PageView>
      <Row>
        <Col { ...col.img}>
          <Link to={link}>
            <Img src={img_url} />
          </Link>
        </Col>

        <Col { ...col.info}>
          <Link to={link} isUnderline={true}>
            <Name>{name}</Name>
          </Link>
          {getLine('Brand:', brand_name)}
          {getLine('barcode:', barcode)}
          <Link to={link}>
            <BtnView>
              ดูรายละเอียดสินค้า
            </BtnView>
          </Link>
        </Col>


        <Col { ...col.price}>
          <Label>ราคา</Label>
          <Price>
            {price} บาท / {unit_type}
          </Price>
          <Label>จำนวน</Label>
          <Row>
            <Col { ...col.input}>
              <InputXs value={quantity} onChange={onChange} isNumber={true} />
            </Col>
            <Col { ...col.btn}>
              <Btn>
                <AddBtn text={text} disabled={isDisabled} onClick={onAddProduct} />
              </Btn>
            </Col>
          </Row>
          {content}
        </Col>
      </Row>
    </PageView>
  )
}

const col = {
  img:   { xl: 4, lg: 4, md: 5, xs: 5 },
  info:  { xl: 12, lg: 12, md: 19, xs: 19 },
  price: { xl: 8, lg: 8, md: 24, xs: 24 },

  input:{ xl: 6, lg: 6, md: 10, xs: 10 },
  btn:  { xl: 14, lg: 14, md: 14, xs: 14 },
}

const PageView = styled.div`
  padding: 16px;
  border: 1px solid #003e31;
  font-size: 16px;
`

const BtnView = styled.div`
  padding-top: 16px;
  color: #003e31;
  font-weight: bold;
`

const Name = styled.div`
  font-weight: bold;
`

const Img = styled.img`
  width: 90%
`

const Label = styled.div`
  color: #6C6C6C;
`

const Price = styled.div`
  color: #003e31;
  font-weight: bold;
  padding-bottom: 16px;
`

const Btn = styled.div`
  padding-left: 16px;
`

const Text = styled.div`
  label {
    margin-right: 8px;
  }
`

const Note = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: #6C6C6C;
`

export default inject('order')(observer(ProductInfo))
