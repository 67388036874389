import styled from 'styled-components'
import { Steps } from 'antd'

const { Step } = Steps

const Process = (props) => {
  const { page = 1 } = props
  return (
    <PageView>
      <Steps size="small" current={page}>
        <Step title="ข้อมูลส่วนตัว" />
        <Step title="สมุดที่อยู่" />
      </Steps>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 0px;

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${(p) => p.theme.color.green};
    border-color: ${(p) => p.theme.color.green};
  }
`

export default Process
