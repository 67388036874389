import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { message } from 'utils'
import { Meta, Col } from 'components/display'

import col from '../col'
import Menu from '../Menu'
import Title from '../Title'
import Info from './Info'
import EditInfo from './EditInfo'

const UserInfo = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({})
  const [myAddress, setMyAddress] = useState({})

  const { user } = props.member.toJS()
  const { my_address, address } = props.user.toJS()

  const onGetAddress = useCallback(async (id) => {
    try {
      setLoading(true)
      await Promise.all([
        props.user.getAddress(id),
        props.master.getProvince(),
      ])
    } catch (e) {
      message.error({
        title: 'แสดงข้อมูลผู้ใช้งาน',
        message: e.message,
      })
    }

    setLoading(false)
  }, [props.user, props.master])

  useEffect(() => {
    onGetAddress(user.tax_address_id)
  }, [onGetAddress, user.tax_address_id])

  const onOpen = () => {
    setIsEdit(true)
    setData(my_address)
    setMyAddress(address)
  }
  const onClose = () => setIsEdit(false)
  const onEdit = async () => {
    try {
      setSaving(true)

      data.province = myAddress.province
      data.district = myAddress.district
      data.tumbon = myAddress.tumbon
      data.postcode = myAddress.postcode

      await props.user.uppdateAddress(data)
      onClose()
    } catch (e) {
      message.error({
        title: 'บันทึกที่อยู่ผู้ใช้งาน',
        message: e.message,
      })
    }
    setSaving(false)
  }

  const onChange = (val) => setData({ ...val })
  const onAddress = (val) => setMyAddress({ ...val })

  const content = isEdit ? (
    <EditInfo
      doc={data}
      address={myAddress}
      loading={saving}
      onEdit={onEdit}
      onClose={onClose}
      onChange={onChange}
      onAddress={onAddress} />
  ) : <Info doc={my_address} />

  const btn = isEdit ? undefined : 'แก้ไขข้อมูลออกใบกำกับภาษี'
  return (
    <PageView>
      <Meta title="สมุดที่อยู่" />
      <Row>
        <Col { ...col.menu}>
          <Menu selected="address" />
        </Col>
        <Col { ...col.content}>
          <Title title="สมุดที่อยู่" btn={btn} onClick={onOpen} />
          {content}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0px;
`

export default inject('member', 'user', 'master')(observer(UserInfo))
