import { useEffect, useState, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import queryString from 'query-string'
import { Row, Col } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

import { firebase } from 'utils'
import { color } from 'definition'
import { MessageBox } from 'components/display'
import { Password, Form, FormItem } from 'components/forms'
import { KeyBtn } from 'components/button'

const WebAction = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(undefined)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [validate, setValidate] = useState({ password: false, confirm: false })

  const navigate = useNavigate()
  const location = useLocation()
  const auth = firebase.auth()

  const { mode, oobCode } = queryString.parse(location.search)

  const onChange = (val, name) => {
    if (name === 'password')
      setPassword(val)
    else if (name === 'confirm')
      setConfirm(val)
  }

  const validating = (password, confirm) => {
    const valid = {}

    if (password === undefined || password === '') {
      valid['password'] = true
    } else {
      if (password !== confirm)
        valid['confirm'] = true
    }

    return valid
  }

  const onProcess = useCallback(async (auth, action, code) => {
    try {
      setLoading(true)

      // Handle the user management action.
      switch (action) {
        case 'resetPassword':
          // Display reset password handler and UI.

          const res_email = await auth.verifyPasswordResetCode(code)
          setEmail(res_email)

          //handleResetPassword(auth, actionCode, continueUrl, lang);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          //handleRecoverEmail(auth, actionCode, lang);
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          //handleVerifyEmail(auth, actionCode, continueUrl, lang);
          break;
        default:
          // Error: invalid mode.
          setMessage('Error')
      }

    } catch (e) {
      setMessage(e.message)
    }

    setLoading(false)
  }, [auth])

  useEffect(() => {
    onProcess(auth, mode, oobCode)
  }, [onProcess, auth, mode, oobCode])


  const savePasswordReset = async () => {
    try {
      const valid = validating(password, confirm)
      const size = Object.keys(valid).length
      if (size > 0) {
        setValidate(valid)
        return
      }

      setValidate({})
      setLoading(true)
      await auth.verifyPasswordResetCode(oobCode)
      await auth.confirmPasswordReset(oobCode, password)
      await props.member.loginByEmail({ email, password })

      setLoading(false)
      navigate('/')

    } catch (e) {
      setMessage(e.message)
      setLoading(false)
    }
  }

  const onHome = () => {
    navigate('/')
  }

  let content
  if (message) {
    content = (
      <ErrorMessage>
        ลิ้งค์ สำหรับตั้งรหัสผ่านหมดอายุ หรือ มีความผิดพลาดเกิดขึ้น โปรดลองใหม่อีกครับ <br/>ขออภัยในความไม่สะดวก
        <br/>
        <i>
          เพิ่มเติม
          <br/>
          {message}
        </i>
        <Link onClick={onHome}>
          กลับไปที่หน้าหลัก
        </Link>
      </ErrorMessage>
    )
  } else if (email)
    content = (
      <>
        <Title>รีเซ็ตรหัสผ่าน</Title>
        <Content>ตั้งรหัสผ่านสำหรับใช้งาน เว็ป prathan.co.th ของ <i>{email}</i></Content>
        <Form name='resetpassword' layout="vertical" colon={false}>
          <Row>
            <Col span={24} style={btn}>
              <FormItem
                name="password"
                label="ตั้งรหัสผ่านใหม่"
                hasFeedback
                error={'กรุณากรอกรหัสผ่านใหม่ของท่าน!'}
                status={validate.password}
              >
                <Password
                  name="password"
                  placeholder="ระบุรหัสผ่าน"
                  value={password}
                  onChange={onChange}
                  disabled={loading} />
              </FormItem>
            </Col>
            <Col span={24} style={btn}>
              <FormItem
                name="confirm"
                label="ยืนยันรหัสผ่าน"
                hasFeedback
                error={'กรุณากรอกรหัสผ่านใหม่ของท่านให้ตรงกัน!'}
                status={validate.confirm}
              >
                <Password
                  name="confirm"
                  placeholder="ยืนยันรหัสผ่าน"
                  value={confirm}
                  onChange={onChange}
                  disabled={loading}/>
              </FormItem>
            </Col>

          </Row>

          <KeyBtn
            loading={loading}
            text="บันทึก"
            onClick={savePasswordReset} />
        </Form>
      </>
    )

  return (
    <MessageBox size='s'>
      {content}
    </MessageBox>
  )
}

const Title = styled.h2`
  text-align: center;
`
const Content = styled.h4`
  text-align: center;
`

const ErrorMessage = styled.h3`
  text-align: center;
  color: red;

  i {
    padding-top: 20px;
  }
`

let btn = { marginBottom: '4px' }

const Link = styled.div`
  color: ${color.bg};
  font-size: 14px;
  margin-top: 50px;
  text-align: center;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`

export default inject('member')(observer(WebAction))
