const config = {
  mode: 'localhost',
  firebase: {
    apiKey: "AIzaSyBkNojpuI0vTEd8UzBN0nniyTrxdtS5ofs",
    authDomain: "prathan-web-test.firebaseapp.com",
    projectId: "prathan-web-test",
    storageBucket: "prathan-web-test.appspot.com",
    messagingSenderId: "270872665040",
    appId: "1:270872665040:web:20c37a3664940b050b37d5",
    measurementId: "G-0NT72K7RW4",
  },
  api: 'http://127.0.0.1:3000/api',
  host: 'http://localhost:3120',
  version: '1.0',
}

module.exports = config
