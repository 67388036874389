import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import { Close } from 'icon'
import { Btn } from 'components/button'

const ConsentView = (props) => {
  const { children } = props

  const navigate = useNavigate()
  const location = useLocation()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }

  return (
    <PageView>
      <Body>
        <Header>
          <span onClick={onBack}>
            <Close color="#003e31" />
          </span>
        </Header>
        {children}
        <Button>
          <Btn text="กลับ" onClick={onBack} />
        </Button>
      </Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
  background-color: white;
  padding: 40px 0px;
`

const Body = styled.div`
  min-height: 60vh;
  padding: 0px 24px;
`

const Header = styled.div`
  width: 100%;
  padding-bottom: 24px;
  span {
    float: right;
    cursor: pointer;
  }
`

const Button = styled.div`
  padding-top: 24px;
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
`

export default ConsentView
