import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const TagList = (props) => {
  const {
    list = [],
    color = 'black',
    backgroundColor= 'white',
    onRemove,
    onAdd,
    onSelected = () => {},
    selected,
  } = props

  const content = list.map((item, index) => {
    let icon
    let dbClick
    if (onRemove) {
      dbClick = () => onRemove(item, index)
      icon = (
        <span onClick={dbClick}>
          <CloseOutlined />
        </span>
      )
    } else if (onAdd) {
      dbClick = () => onAdd(item, index)
      icon = (
        <span onClick={() => onAdd(item, index)}>
          <PlusOutlined />
        </span>
      )
    }

    let border = '1px solid white'
    let ckSelect = () => onSelected(item, index)
    if (item.name === selected) {
      border = '1px solid #021346'
      ckSelect = dbClick
    }

    return (
      <Tag key={index} style={{ border, color, backgroundColor }}>
        <label onClick={ckSelect}>{item.name}</label>
        {icon}
      </Tag>
    )
  })
  return (
    <PageView>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
`

const Tag = styled.span`
  background-color: white;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 8px;
  display: inline-block;
  border-radius: 4px;

  label {
    margin-right: 4px;
    cursor: pointer;
  }

  span {
    margin-left: 4px;
    cursor: pointer;
  }
`

export default TagList
