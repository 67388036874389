import store from '../stores'
import { firebase } from './firebase'

const ga = firebase.analytics()

export class Errors {
  handle(res) {
    switch (res.statusCode) {
      case 401:
        store.member.reset()
        break
      default:
    }
  }

  lunch({ message }) {
    throw Error(message)
  }

  isError(val, { message }) {
    if (val === true) {
      throw Error(message)
    }
  }

  isRespError(resp, params = {}) {
    let { message } = params

    if (resp.statusCode === 200) {
      return false
    } else if (resp.statusCode === 401) {
      resp.body = {}
      message = 'กรุณาเข้าสู่ระบบใหม่'
      store.member.reset()
    } else if (resp.statusCode === 404) {
      resp.body = {}
      message = 'ระบบไม่พร้อมใช้งาน'
    }

    const msg = message ? message : resp.body.message

    this.lunch({ message: msg })
    this.log(resp)
    return true
  }

  isNull(val, params = {}) {
    if (val === '' || val === null || val === undefined) {
      const {
        message = 'sorry we fond some problem.',
      } = params

      this.lunch({ message })
    }
  }

  isArray(val, params = {}) {
    if (Array.isArray(val) === false) {
      const {
        message = 'sorry we fond some problem.',
      } = params

      this.lunch({ message })
    }
  }

  log(resp = { }) {
    const description = JSON.stringify()
    ga.logEvent('exception', {
      description,
      fatal: true
    })
  }
}

export const error = new Errors()
export default error
