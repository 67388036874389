const col = {
  code: { lg: 5, md: 5, xs: 12 },
  date: { lg: 5, md: 5, xs: 12 },
  quantity: { lg: 4, md: 5, xs: 12 },
  price: { lg: 5, md: 5, xs: 12 },
  remark: { lg: 4, md: 4, xs: 12 },
  next: { lg: 1, md: 4, xs: 24 },
}

export default col
