import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import BaseStore from './BaseStore'

import {
  error,
  http,
  timer,
  storage,
  format,
} from '../utils'
import { config } from '../config'

const original = {
  product_list: [],
  quantity: 0,
  total_price: 0,
  order_code: '',
  remark: '',
  address: {
    tax_type: 'personal',
    social_no: '',

    company_name: '',
    branch_type: 'main',
    branch_code: '',

    house_no: '',
    village_name: '',
    address: '',
    tumbon: '',
    district: '',
    province: '',
    postcode: '',
    mobile: '',
  },
}

let state
export class Order extends BaseStore {
  constructor() {
    super()
    this.observable({
      visible: false,
      current: cloneDeep(original),
      history: cloneDeep(original),
      my_history: {
        index: 0,
        total: 0,
        list: [],
      },
    })

    state = this
  }

  setVisible(val) {
    runInAction(() => {
      state.visible = val
    })
  }

  getTotalProduct(product_list = []) {
    let quantity = 0
    let total_price = 0
    product_list.map((item, i) => {
      const q = +item.quantity || 0
      const p = +item.product.discount_price || 0
      quantity += q
      total_price += format.precision(q * p)

      return i
    })

    return { quantity, total_price }
  }

  getCurrentOrder() {
    return this.toJS().current
  }

  getHistoryOrder() {
    return this.toJS().history
  }

  setCurrentOrder(doc) {
    runInAction(() => {
      state.current = doc
    })
  }

  getAttribute(it = {}) {
    const img_list = it.img_list || []
    const img_url = img_list.length > 0 ? img_list[0] : undefined
    const product = {
      img_url,
      barcode: it.barcode,
      brand_code: it.brand_code,
      category_code: it.category_code,
      discount: it.discount,
      discount_price: it.discount_price,

      group_code: it.group_code,
      name: it.name,
      original_price: it.original_price,
      price: it.price,

      product_code: it.product_code,
      product_id: it.product_id,
      unit_type: it.unit_type,
    }

    return product
  }

  setProduct(product, quantity = 1) {
    const current = this.getCurrentOrder()
    const {
      product_list = [],
    } = current

    const index = product_list.findIndex(it => it.product.product_id === product.product_id)
    if (index === -1) {

      product_list.push({
        product: this.getAttribute(product),
        quantity,
      })
    } else {
      product_list[index].quantity = quantity
    }

    const total = this.getTotalProduct(product_list)

    current.product_list = product_list
    current.quantity = total.quantity
    current.total_price = total.total_price

    runInAction(() => {
      state.current = current
      state.saveOrder(current)
    })
  }

  async addProduct(product, quantity = 1) {
    const current = this.getCurrentOrder()
    const {
      product_list = [],
    } = current


    const index = product_list.findIndex(it => it.product.product_id === product.product_id)
    if (index === -1) {

      product_list.push({
        product: this.getAttribute(product),
        quantity,
      })
    } else {
      product_list[index].quantity += +quantity
      const q = product_list[index].quantity
      product_list[index].quantity = q > 50000 ? 50000 : q
    }

    const total = this.getTotalProduct(product_list)

    current.product_list = product_list
    current.quantity = total.quantity
    current.total_price = total.total_price

    runInAction(() => {
      // state.visible = true
      state.current = current
      state.saveOrder(current)
    })
  }

  removeProduct(product, quantity = 1) {
    const current = this.getCurrentOrder()
    const {
      product_list = [],
    } = current

    const index = product_list.findIndex(it => it.product.product_id === product.product_id)
    if (index > -1) {
      product_list[index].quantity -= +quantity
      if (product_list[index].quantity <= 0) {
        product_list.splice(index, 1)
      }

      const total = this.getTotalProduct(product_list)

      current.product_list = product_list
      current.quantity = total.quantity
      current.total_price = total.total_price

      runInAction(() => {
        state.current = current
        state.saveOrder(current)
      })
    }
  }

  async getOrder(params = {}) {
    const { code } = params
    const url = `${config.api}/v1/order/${code}/information`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isNull(data.order_code, { message: 'ไม่พบรายการสินค้า' })

    runInAction(() => {
      state.history = data
    })
  }

  async getMyOrder(params = {}) {
    const { code } = params
    const url = `${config.api}/v1/customer/order/${code}/information`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isNull(data.order_code, { message: 'ไม่พบรายการสินค้า' })

    runInAction(() => {
      state.history = data
    })
  }

  async createOrder() {
    const current = this.getCurrentOrder()
    const url = `${config.api}/v1/customer/order`
    const resp = await http.post(url, {
      json: current,
    })

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    runInAction(() => {
      state.current = cloneDeep(original)
      state.visible = false
    })

    const { data = {} } = resp.body
    error.isNull(data.order_code, { message: 'สร้างรายการสินค้า ไม่สำเร็จ' })

    storage.remove('order')

    return data.order_code
  }

  async getOrderList(params = {}) {
    const {
      index = 1,
    } = params

    const my_history = this.toJS().my_history

    const url = `${config.api}/v1/customer/order/history?index=${index}&limit=20`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isArray(data, { message: 'something incorrect' })

    my_history.index = index
    my_history.list = data

    runInAction(() => {
      state.my_history = my_history
    })
  }

  async getOrderCount() {
    const url = `${config.api}/v1/customer/order/history/count`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data = 0 } = resp.body

    runInAction(() => {
      state.my_history = {
        index: 1,
        total: data,
        list: [],
      }
    })
  }

  clear() {
    storage.remove('order')
    runInAction(() => {
      state.current = cloneDeep(original)
    })
  }

  async checkProductUpdated(product_list = []) {
    //-------------------------
    if (product_list.length > 0) {
      const product_code_list = product_list.map(it => {
        const {
          product,
          quantity,
        } = it
        return {
          product: { product_code: product.product_code },
          quantity,
        }
      })

      const url = `${config.api}/v1/order/reorder`
      const resp = await http.post(url, { json: { product_list:[...product_code_list] }})

      error.isRespError(resp, {
        url,
        message: resp.body.message,
      })

      const { data } = resp.body
      error.isArray(data, { message: 'เกิดข้อผิดพลาด' })
      return data
    }
  }

  async checkOrder() {
    const storage_data = storage.load('order')
    if (storage_data) {
      const { order = {}, updated_at } = storage_data
      const {
        product_list = [],
      } = order

      if (timer.getDiffInDays(updated_at) <= 15) {
        const updated_product_list = await this.checkProductUpdated(product_list)
        const update_total = this.getTotalProduct(updated_product_list)
        const current = cloneDeep(original)
        current.quantity = update_total.quantity
        current.total_price = update_total.total_price
        current.product_list = updated_product_list

        runInAction(() => {
          state.current = current
        })
      } else {
        this.clear()
      }
    }
  }

  saveOrder(data) {
    const {
      product_list = [],
      quantity,
      total_price,
    } = data
    storage.save('order', {
      updated_at: timer.get().toISOString(),
      order: {
        product_list,
        quantity,
        total_price,
      },
    })
  }

  async reOrder() {
    const history = this.toJS().history
    const product_list = history.product_list.map(it => {
      const {
        product,
        quantity,
      } = it

      return {
        product: { product_code: product.product_code },
        quantity,
      }
    })

    const url = `${config.api}/v1/order/reorder`
    const resp = await http.post(url, { json: { product_list }})

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const { data } = resp.body
    error.isArray(data, { message: 'เกิดข้อผิดพลาด' })

    const total = this.getTotalProduct(data)

    const current = cloneDeep(original)
    current.product_list = data
    current.quantity = total.quantity
    current.total_price = total.total_price

    runInAction(() => {
      state.current = current
      state.visible = true
      state.saveOrder(current)
    })
  }

  async generatePdf({ code, json = {} }) {
    const url = `${config.api}/v1/order/${code}/report`
    const resp = await http.post(url, { json })
    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    if (isErr) {
      return { status: 'error', message: resp.body.message }
    }

    const { data = '' } = resp.body

    return { pdf_url: data }
  }

  async generateImage({ code, json = {} }) {
    const url = `${config.api}/v1/order/${code}/report/image`
    const resp = await http.post(url, { json })
    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    if (isErr) {
      return { status: 'error', message: resp.body.message }
    }

    const { data = '' } = resp.body

    return { image_url: data }
  }
}

export default new Order()
