import styled from 'styled-components'

const Info = (props) => {
  const { label, value } = props
  return (
    <Line>
      <Label>{label}</Label>
      <Text>{value}</Text>
    </Line>
  )
}

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
`

const Label = styled.div`
  width: 160px;
`

const Text = styled.div``

export default Info
