import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Drawer, Row, Col } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import { Close, Next, Back } from 'icon'
import logo from 'assets/PrathanA.png'

import Footer from './Footer'

const MobileMenu = (props) => {
  const [visible, setVisible] = useState(false)
  const [menuType, setMenuType] = useState('')
  const [categoryType, setCategoryType] = useState(undefined)
  const [categorySubType, setCategorySubType] = useState(undefined)
  const [charecter, setCharecter] = useState(undefined)

  const navigate = useNavigate()

  const isLogin = props.member.isLogin()

  const onOpen = () => setVisible(true)

  const onClose = () => {
    setVisible(false)
    setMenuType('')
    setCategoryType(undefined)
    setCategorySubType(undefined)
  }

  const onLogout = () => {
    props.member.logout()
    onClose()
  }

  const goTo = (link) => {
    navigate(link)
    onClose()
  }

  const onRegister = () => goTo('/register')
  const onLogin = () => goTo('/login')

  const getNextBtn = (i, text, click, isIcon = true) => {
    const icon = isIcon ? <Next color="white" /> : undefined
    return (
      <Btn key={i} onClick={click}>
        <TextBtn>{text}</TextBtn>
        {icon}
      </Btn>
    )
  }

  const getNextImgBtn = (i, text, img_url, click) => {
    return (
      <ImgBtn key={i} onClick={click}>
        <img src={img_url} alt={text} />
        <span>{text}</span>
      </ImgBtn>
    )
  }

  const getBackBtn = (text, click) => {
    return (
      <BackBtn key="back" onClick={click}>
        {text}
        <Back color="#003e31" />
      </BackBtn>
    )
  }

  const getMainMenu = () => {
    const main_list = menu.map((it, i) => {
      const { name, value } = it
      const click = () => setMenuType(value)

      return getNextBtn(i, name, click)
    })

    if (isLogin) {
      const um = user_menu.map((it, i) => {
        const { name, value } = it
        const click = value === 'logout' ? onLogout : () => goTo(value)

        return getNextBtn(`user-${i}`, name, click, false)
      })

      return main_list.concat(um)
    }

    return main_list
  }
  // Category
  const getCategoryMenu = () => {
    const { all_list = [] } = props.category.toJS()
    const category_list = all_list.map((it, i) => {
      const {
        code,
        name,
        sub_list = [],
      } = it

      const isLink = sub_list.length === 0
      const click = isLink
        ? () => goTo(`/product.list/${code}?name=${name}`)
        : () => setCategoryType(it)

      return getNextBtn(i, name, click, !isLink)
    })

    const onBack = () => setMenuType('')
    const back = getBackBtn('อุปกรณ์ทั้งหมด', onBack)
    category_list.unshift(back)
    return category_list
  }

  const getSubCategoryMenu = () => {
    const { sub_list = [] } = categoryType
    const submenu_list = sub_list.map((it, i) => {
      const { code, name } = it
      const catsub_list = it.sub_list || []
      const isLink = catsub_list.length === 0
      const click = isLink
        ? () => goTo(`/product.list/${code}?name=${name}`)
        : () => setCategorySubType(it)

      return getNextBtn(i, name, click, !isLink)
    })

    const onBack = () => setCategoryType(undefined)
    const back = getBackBtn('ย้อนกลับ', onBack)
    submenu_list.unshift(back)
    return submenu_list
  }

  const getSubItemCategoryMenu = () => {
    const { sub_list = [] } = categorySubType
    const submenu_list = sub_list.map((it, i) => {
      const { code, name } = it
      const click = () => goTo(`/product.list/${code}?name=${name}`)

      return getNextBtn(i, name, click, false)
    })

    const onBack = () => setCategorySubType(undefined)
    const back = getBackBtn('ย้อนกลับ', onBack)
    submenu_list.unshift(back)
    return submenu_list
  }
  // Brand
  const getCharBrandMenu = () => {
    const brand_list = ch_list.map((ch, i) => {
      const click = () => setCharecter(ch)
      return (
        <Col span={6} key={i}>
          <ChBtn onClick={click}>{ch}</ChBtn>
        </Col>
      )
    })

    const onBack = () => setMenuType('')
    const back = getBackBtn('ยี่ห้อทั้งหมด', onBack)
    return (
      <>
        {back}
        <ChContent>
          <Row>
            {brand_list}
          </Row>
        </ChContent>
      </>
    )
  }

  const processFilter = (list = [], isChar = false) => {
    return list.filter(it => {
      const { name } = it
      const c = name.length > 0 ? name[0] : '0'

      const result = c.toLowerCase().match(/[a-z]/i)
      return result === null
    })
  }

  const getFilter = (selected, all_list) => {
    if (selected === 'All') {
      return all_list
    } else if (selected === '#') {
      return processFilter(all_list, false)
    }

    const ch = selected.toLowerCase()
    return all_list.filter(it => {
      const { name } = it
      const c = name.length > 0 ? name[0] : '0'

      return c.toLowerCase() === ch
    })
  }

  const getBrandMenu = () => {
    const { all_list = [] } = props.brand.toJS()
    const filter_list = getFilter(charecter, all_list)
    const brand_list = filter_list.map((it, i) => {
      const { code, name, img_url } = it
      const click = () => goTo(`/brand.category/${code}?name=${name}`)

      return getNextImgBtn(i, name, img_url, click)
    })

    const onBack = () => setCharecter(undefined)
    const text = charecter === 'All' ? 'ยี่ห้อทั้งหมด' : `ยี่ห้อ (${charecter})`
    const back = getBackBtn(text, onBack)
    brand_list.unshift(back)
    return brand_list
  }

  const getCategoryContent = () => {
    if (categoryType !== undefined) {
      if (categorySubType !== undefined) {
        return getSubItemCategoryMenu()
      }

      return getSubCategoryMenu()
    }

    return getCategoryMenu()
  }

  const getBrandContent = () => {
    if (charecter !== undefined) {
      return getBrandMenu()
    }

    return getCharBrandMenu()
  }

  const getMenuContent = () => {
    switch (menuType) {
      case 'category':
        return getCategoryContent()
      case 'brand':
        return getBrandContent()
      default:
        return getMainMenu()
    }
  }

  const content = getMenuContent()
  const footer = !isLogin && menuType === '' ? <Footer onRegister={onRegister} onLogin={onLogin} /> : undefined
  return (
    <MainMenu>
      <BergerMenu onClick={onOpen}>
        <MenuOutlined />
      </BergerMenu>
      <Drawer
        className="drawer-menu"
        height="100%"
        placement={'bottom'}
        closable={false}
        onClose={onClose}
        visible={visible}>
        <Header>
          <CloseBtn onClick={onClose}>
            <Close />
          </CloseBtn>
          <Logo src={logo} alt="ประธานการไฟฟ้า" />
        </Header>
        <Body>
          {content}

          {footer}
        </Body>
      </Drawer>
    </MainMenu>
  )
}

const menu = [
  { name: 'อุปกรณ์ทั้งหมด', value: 'category' },
  { name: 'ยี่ห้อทั้งหมด', value: 'brand' },
]

const user_menu = [
  { name: 'ข้อมูลส่วนตัว', value: '/user/information' },
  { name: 'สมุดที่อยู่', value: '/user/address' },
  { name: 'บัญชีของฉัน', value: '/user/account' },
  { name: 'การสั่งซื้อ', value: '/order' },
  { name: 'ประวัติสั่งซื้อ', value: '/user/order' },
  { name: 'ออกจากระบบ', value: 'logout' },
]

const ch_list = [
  'All',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'Y',
  'Z',
  '#',
]

const MainMenu = styled.div``

const BergerMenu = styled.div`
  font-size: 24px;
`

const Header = styled.div`
  width: 100%;
  padding: 12px 24px;
  text-align: center;
  position: relative;
`

const Body = styled.div`
  width: 100%;
`

const CloseBtn = styled.span`
  left: 24px;
  top: 16px;
  position: absolute;
`

const Btn = styled.div`
  font-size: 18px;
  width: 100%;
  height: 60px;
  color: white;
  display: flex;
  align-items: center;
  border: 0px solid #C8C8C8;
  border-bottom-width: 1px;
  cursor: pointer;
  position: relative;
  padding: 0px 24px;
  line-height: 20px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    height: 35px;
    display: flex;
    align-items: center;
  }

  svg {
    position: absolute;
    right: 12px;
  }
`

const ChBtn = styled.div`
  font-size: 18px;
  width: 80%;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #C8C8C8;
  border-radius: 16px;
  cursor: pointer;
`

const ChContent = styled.div`
  padding: 16px 0px;
`

const TextBtn = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  height: 35px;
  display: flex;
  align-items: center;
`

const ImgBtn = styled.div`
  font-size: 18px;
  width: 100%;
  height: 60px;
  color: white;
  display: flex;
  align-items: center;
  border: 0px solid #C8C8C8;
  border-bottom-width: 1px;
  cursor: pointer;
  position: relative;
  padding: 0px 24px;
  line-height: 20px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    padding-left: 24px;
  }

  img {
    height: 45px;
    width: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
`

const BackBtn = styled.div`
  font-size: 18px;
  width: 100%;
  height: 60px;
  color: ${(p) => p.theme.color.green};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
    left: 2px;
  }
`

const Logo = styled.img`
  width: 55%;
`

export default inject('member', 'category', 'brand')(observer(MobileMenu))
