import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Meta } from 'components/display'
import { message, ga } from 'utils'

import Header from '../Header'
import ContentView from '../ContentView'

const Register = (props) => {
  const [loading, setLoading] = useState({
    email: false,
    google: false,
  })

  const [validate, setValidate] = useState({
    email: undefined,
    password: undefined,
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const onChange = (val, name) => {
    if (name === 'email') setEmail(val)
    else setPassword(val)

    setValidate({
      email: false,
      password: false,
    })
  }

  const onEmail = async () => {
    const valid = {
      email: false,
      password: false,
    }

    try {
      if (email === '') valid.email = 'กรอกอีเมล'
      if (password === '') valid.password = 'กรอกรหัสผ่าน'

      if (!valid.email && !valid.password) {
        loading.email = true
        setLoading({ ...loading })

        await props.member.singupByEmail({ email, password })
        ga.click('singup by email')

        navigate('/check.email')
        return
      } else {
        setValidate(valid)
      }
    } catch (e) {
      const m = e.message
      let msg = ''
      if (m.includes('improperly formatted')) {
        msg = 'รูปแบบ Email ไม่ถูกต้อง'
      } else if (m.includes('already in use by another account') || 'อีเมลนี้เป็นสมาชิกอยู่แล้ว') {
        msg = 'อีเมลนี้เป็นสมาชิกอยู่แล้ว'
      } else {
        msg = 'กรุณาลองใหม่อีกครั้ง'
        console.log('msg:', m)
      }

      message.error({ message: msg })
    }

    loading.email = false
    setLoading({ ...loading })
  }

  const onGmail = async (res) => {
    let completed = false
    try {
      loading.google = true
      setLoading({ ...loading })
      await props.member.loginByGoogle()
      completed = true
      ga.click('singup by gmail')
    } catch (e) {
      message.error({ message: 'กรุณาลองใหม่อีกครั้ง' })
    }
    loading.google = false
    setLoading({ ...loading })
    if (completed) {
      navigate('/user/information')
    }
  }

  return (
    <PageView>
      <Meta title="สมัครสมาชิก" />
      <Header title="สมัครสมาชิก" text="เป็นสมาชิกอยู่แล้ว?" btn="เข้าสู่ระบบ" detail="ที่นี่" link="/login" />
      <Content>
        <ContentView
          email={email}
          password={password}
          btn="สมัครสมาชิก"
          loading={loading}
          validate={validate}
          onEmail={onEmail}
          onGmail={onGmail}
          onChange={onChange} />
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  padding-top: 50px;
  width: 100%;
  max-width: 825px;
  margin: 0 auto;
`

const Content = styled.div`
  background-color: #F7F9FB;
  border-radius: 2px;
  padding: 32px;
`

export default inject('member')(observer(Register))
