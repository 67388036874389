import styled from 'styled-components'

import { Close } from 'icon'
import { Btn } from 'components/button'
import { HtmlContent } from 'components/display'

const Consent = (props) => {
  const { data = {}, onBack } = props
  return (
    <PageView>
      <Body>
        <Header>
          <span onClick={onBack}>
            <Close color="#003e31" />
          </span>
        </Header>
        <Title>{data.title}</Title>
        <HtmlContent content={data.content} />
        <Button>
          <Btn text="กลับ" onClick={onBack} />
        </Button>
      </Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const Title = styled.div`
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
`

const Body = styled.div`
  min-height: 60vh;
  padding: 0px 24px;
`

const Header = styled.div`
  width: 100%;

  span {
    float: right;
    cursor: pointer;
  }
`

const Button = styled.div`
  padding-top: 24px;
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
`

export default Consent
