import React from 'react'
import BadMessage from './BadMessage'

const NoProduct = (props) => {
  return (
    <BadMessage text="ไม่พบรายการสินค้า" />
  )
}

export default NoProduct
