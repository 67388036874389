import { Select } from 'antd'
import styled from 'styled-components'

const DropdownList = (props) => {
  const {
    menu = [],
    placeholder = 'Please Select',
    value,
    name,
    clear = true,
    disabled = false,
    readonly = false,
    onChange,
  } = props

  const onSelected = (selected) => {
    if (onChange && value !== selected) {
      const item = menu.find((item) => item.value === selected) || {}
      onChange(selected, name, item.tag)
    }
  }

  const choice = menu.map((item) => {
    return (
      <Select.Option key={item.value} disabled={item.disabled || false}>
        {item.name}
      </Select.Option>
    )
  })

  return (
    <PageView>
      <Select
        disabled={disabled || readonly}
        placeholder={placeholder}
        value={value}
        allowClear={clear}
        showArrow={true}
        onChange={onSelected}
      >
        {choice}
      </Select>
    </PageView>
  )
}

const PageView = styled.div`
  .ant-select {
    width: 100%;
  }

  .ant-select .ant-select-selector {
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    border: 0px;
    height: 52px;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    color: ${(p) => p.theme.color.blue};
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${(p) => p.theme.color.blue};
    border: 1px solid #aaaaaa;
  }
`

export default DropdownList
