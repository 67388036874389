import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { InputArea } from 'components/input'
import { KeyBtn, Btn } from 'components/button'
import { Col } from 'components/display'
import { Edit } from 'icon'
import { ConfirmDialog } from 'dialog'

const Note = (props) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isClear, setIsClear] = useState(false)
  const [note, setNote] = useState('')

  const { order = {}, onRemark } = props

  const onEdit = () => {
    setIsEdit(true)
    setNote(order.remark)
  }
  const onCloseEdit = () => setIsEdit(false)
  const onClear = () => setIsClear(true)
  const onCloseClear = () => setIsClear(false)
  const onNote = (val) => setNote(val)
  const onSave = () => {
    onCloseEdit()
    onRemark(note)
  }

  const onClearRemark = () => {
    onCloseClear()
    onRemark('')
  }

  if (isEdit) {
    return (
      <PageView>
        <Title>บันทึกช่วยจำ:</Title>
        <InputArea
          placeholder="บันทึกช่วยจำ"
          value={note}
          onChange={onNote} />

        <Space>
          <Row>
            <Col lg={6} md={8} xs={24} position="left-responsive">
              <KeyBtn text="บันทึก" onClick={onSave} />
            </Col>
            <Col lg={6} md={8} xs={24} position="right-responsive">
              <Btn text="ยกเลิก" onClick={onCloseEdit} />
            </Col>
          </Row>
        </Space>
      </PageView>
    )
  }

  return (
    <PageView>
      <Title>บันทึกช่วยจำ:</Title>{order.remark}

      <Button>
        <LinkBtn onClick={onEdit}><Edit /> แก้ไขบันทึก</LinkBtn>
        <LinkBtn onClick={onClear}>ลบบันทึก</LinkBtn>
      </Button>
      <ConfirmDialog
        visible={isClear}
        message="คุณต้องการที่จะลบบันทึกนี้ออก ใช่หรือไม่?"
        onClose={onCloseClear}
        onConfirm={onClearRemark} />
    </PageView>
  )
}

const PageView = styled.div`
  padding: 16px;
  border: 1px solid #AAAAAA;
  background-color: white;
  margin: 16px 0px;
`

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  margin-right: 8px;
`

const Space = styled.div`
  width: 100%;
  margin-top: 16px;
`

const Button = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  column-gap: 16px;
`

const LinkBtn = styled.span`
  color: ${(p) => p.theme.color.green};
  display: flex;
  column-gap: 8px;
  text-decoration: underline;
  cursor: pointer;
`

export default Note
