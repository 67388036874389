import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import queryString from 'query-string'

import { Find } from 'icon'

import Cart from './Cart'
import CategoryMenu from './CategoryMenu'
import BrandMenu from './BrandMenu'

const FindMenu = (props) => {
  const [text, setText] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  const params = queryString.parse(location.search)
  const { keyword } = params

  const onFind = () => navigate(`/find?keyword=${text}`)

  const onKeyDown = (e) => {
    if (e.key === 'Enter') onFind()
  }

  useEffect(() => {
    setText(keyword || '')
  }, [keyword])

  return (
    <PageView>
      <Content>
        <FindSection>
          <Row align="middle">
            <Col { ...col.menu }>
              <Menu>
                <Btn>
                  <CategoryMenu />
                </Btn>
                <Btn>
                  <BrandMenu />
                </Btn>
              </Menu>
            </Col>

            <Col { ...col.find}>
              <FindInput>
                <Input
                  placeholder="พิมพ์ชื่อสินค้าที่ต้องการ"
                  value={text}
                  onChange={(evt) => setText(evt.target.value)}
                  onKeyDown={onKeyDown} />
                <FindBtn onClick={onFind}>
                  <Find color="white" size={1.2} />
                </FindBtn>
              </FindInput>
            </Col>

            <Col { ...col.account }>
              <Account>
                <Cart />
              </Account>
            </Col>
          </Row>
        </FindSection>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  background-color: ${(p) => p.theme.color.grey};
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  height: 75px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`

const Menu = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding-right: 32px;
  column-gap: 8px;

  ${(p) => p.theme.media.tablet} {
    padding-right: 12px;
  }
`

const Account = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const FindInput = styled.div`
  width: 100%;
  display: inline-flex;
`

const FindSection = styled.div`
  width: 100%;
`

const Input = styled.input`
  width: calc(100% - 52px);
  height: 50px;
  border-radius: 2px 0px 0px 2px;
  color: black;
  border: 0px solid #D90202;
  padding: 0px 4px;
  text-indent: 4px;
  line-height: 55px;
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
`

const FindBtn = styled.div`
  width: 52px;
  height: 50px;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 0px 2px 2px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Btn = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
`

const col = {
  find: {
    xl: { span: 14, order: 1 },
    lg: { span: 12, order: 1 },
    md: { span: 12, order: 1 },
    sm: { span: 24, order: 1 },
    xs: { span: 24, order: 1 },
  },

  menu: {
    xl: { span: 6, order: 1 },
    lg: { span: 7, order: 0 },
    md: { span: 8, order: 0 },
    sm: { span: 0, order: 0 },
    xs: { span: 0, order: 0 },
  },

  account: {
    xl: { span: 4, order: 1 },
    lg: { span: 5, order: 2 },
    md: { span: 4, order: 2 },
    sm: { span: 0, order: 2 },
    xs: { span: 0, order: 2 },
  },
}

/*
<Row align="middle">
  <Col { ...col.menu }>
    <Menu>
      <PopupMenu label="ดูอุปกรณ์ทั้งหมด" />
      <PopupMenu label="ดูยี่ห้อทั้งหมด" />
    </Menu>
  </Col>

  <Col { ...col.find}>
    <FindInput>
      <Input
        placeholder="พิมพ์ชื่อสินค้าที่ต้องการ"
        value={text}
        onChange={(evt) => setText(evt.target.value)}
        onKeyDown={onKeyDown} />
      <FindBtn onClick={onFind}>
        <Find color="white" size={1.2} />
      </FindBtn>
    </FindInput>
  </Col>

  <Col { ...col.account }>
    <Account>
      <div />
      <Cart />
    </Account>
  </Col>
</Row>
*/

export default inject('member', 'order')(observer(FindMenu))
