import styled from 'styled-components'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'

const Header = (props) => {
  const { title, text, btn, detail, link } = props
  const navigate = useNavigate()

  const onClick = () => navigate(link)

  return (
    <PageView>
      <Row align="middle">
        <Col span={9}>
          <Title>{title}</Title>
        </Col>
        <Col span={15}>
          <Text>
            {text}
            <Link onClick={onClick}>
              {btn}
            </Link>
            {detail}
          </Text>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  margin-bottom: 16px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
`

const Text = styled.div`
  text-align: right;
`

const Link = styled.span`
  margin: 0px 4px;
  color: ${(p) => p.theme.color.green};
  font-weight: 700;
  cursor: pointer;
`

export default Header
