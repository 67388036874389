import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

const AddBtn = (props) => {
  let { onClick, text = 'Create' } = props

  return (
    <Page>
      <Button onClick={onClick}>
        <PlusOutlined />
        <span>{text}</span>
      </Button>
    </Page>
  )
}

const Page = styled.div`
  button {
    font-size: 12px;
    color: white;
    background-color: #042f4b;
    border-radius: 20px;
    padding: 0px 20px;
    width: 100%;
  }

  button > i {
    margin-right: 5px;
  }

  @media screen and (max-width: 430px) {
    button {
      width: 64px;
      height: 64px;
      bottom: 20px;
      right: 20px;

      z-index: 100;
      position: fixed;
      text-align: center;
      border-radius: 100%;
      border: 1px solid #F1FEFF;
      box-shadow: #C1C0C0 2px 2px 2px 0px;

      span {
        display: none;
      }

      i {
        font-size: 20px;
        padding-left: 2px;
      }
    }
  }
`

export default AddBtn
