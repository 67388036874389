import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'

import { Meta } from 'components/display'
import { Checker } from 'components/input'
import { ga } from 'utils'

import Header from '../Header'
import ContentView from '../ContentView'

const Singin = (props) => {
  const [loading, setLoading] = useState({
    email: false,
    google: false,
  })

  const [validate, setValidate] = useState({
    email: undefined,
    password: undefined,
  })

  const [remember, setRemember] = useState(true)
  const [email, setEmail] = useState('')
  const [err, setErr] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  useEffect( async () => {
    const result = await props.member.getRemember()
    setEmail(result.email || '')
    setRemember(result.remember || true)
  }, [props.member])

  const onChange = (val, name) => {
    if (name === 'email') setEmail(val)
    else setPassword(val)

    setValidate({
      email: false,
      password: false,
    })
  }

  const onEmail = async () => {
    const valid = {
      email: undefined,
      password: undefined,
    }
    let completed = false

    try {
      if (email === '') valid.email = 'กรอกอีเมล'
      if (password === '') valid.password = 'กรอกรหัสผ่าน'
      setErr('')
      if (!valid.email && !valid.password) {
        loading.email = true
        setLoading({ ...loading })

        await props.member.loginByEmail({ remember, email, password })
        completed = true
        ga.click('login by email')
      } else {
        setValidate(valid)
      }
    } catch (e) {
      let m = e.message

      let msg = ''
      if (m.includes('improperly formatted')) {
        msg = 'รูปแบบ Email ไม่ถูกต้อง'
      } else if (m.includes('password is invalid')) {
        msg = 'รหัสผ่านไม่ถูกต้อง'
      } else if (m.includes('email') && m.includes('not found')) {
        msg = 'ไม่พบอีเมลของท่าน'
      } else {
        msg = 'กรุณาลองใหม่อีกครั้ง'
        console.log('msg:', m)
      }

      setErr(msg)
    }
    loading.email = false
    setLoading({ ...loading })

    if (completed && window.location.pathname === '/login') {
      navigate('/')
    }
  }

  const onGmail = async (res) => {
    let completed = false
    try {
      setErr('')
      loading.google = true
      setLoading({ ...loading })
      await props.member.loginByGoogle()
      completed = true
      ga.click('login by gmail')
    } catch (e) {
      setErr('กรุณาลองใหม่อีกครั้ง')
    }

    loading.google = false
    setLoading({ ...loading })
    if (completed && window.location.pathname === '/login') {
      navigate('/')
    }
  }

  const onPassword = () => navigate('/password')
  const onRemember = (val) => setRemember(val)

  const msg = err === '' ? '' : <Error>{err}</Error>
  return (
    <PageView>
      <Meta title="เข้าสู่ระบบ" />
      <Header title="เข้าสู่ระบบ" text="ยังไม่ได้เป็นสมาชิก?" btn="สมัครสมาชิก" detail="ที่นี่" link="/register" />
      <Content>
        <ContentView
          email={email}
          password={password}
          btn="เข้าสู่ระบบ"
          loading={loading}
          validate={validate}
          onEmail={onEmail}
          onGmail={onGmail}
          onChange={onChange} />
        <Space />
        {msg}
        <Row align="middle">
          <Col lg={7} md={7} xs={12}>
            <Checker label="จดจำอีเมลนี้ไว้" value={remember} onChange={onRemember} />
          </Col>
          <Col lg={8} md={8} xs={12}>
            <Link onClick={onPassword}>
              ลืมรหัสผ่าน
            </Link>
          </Col>
        </Row>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  padding-top: 50px;
  width: 100%;
  max-width: 825px;
  margin: 0 auto;
`

const Space = styled.div`
  padding: 8px;
`

const Content = styled.div`
  background-color: white;
  border-radius: 2px;
  padding: 32px;
`

const Link = styled.div`
  color: ${(p) => p.theme.color.green};
  font-weight: 700;
  text-align: right;
  cursor: pointer;
`

const Error = styled.div`
  color: ${(p) => p.theme.color.error};
  padding-bottom: 16px;
`

export default inject('member')(observer(Singin))
