/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = 'white' } = props

  const width = `${Math.ceil(32 * size)}`
  const height = `${Math.ceil(32 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.8077 20.6264H10.2211L11.1544 18.714L26.6603 18.6857C27.1847 18.6857 27.6341 18.3089 27.7278 17.7875L29.8751 5.694C29.9313 5.37682 29.8471 5.05022 29.6411 4.80214C29.5392 4.68004 29.4123 4.58169 29.2689 4.5139C29.1255 4.44612 28.9692 4.41053 28.8108 4.40959L9.08504 4.34364L8.9165 3.54599C8.81038 3.03725 8.35469 2.66669 7.83658 2.66669H3.01437C2.72216 2.66669 2.44192 2.78348 2.2353 2.99137C2.02868 3.19927 1.9126 3.48123 1.9126 3.77524C1.9126 4.06924 2.02868 4.35121 2.2353 4.5591C2.44192 4.76699 2.72216 4.88379 3.01437 4.88379H6.94392L7.68052 8.40728L9.49392 17.2411L7.15928 21.0755C7.03804 21.2402 6.96502 21.4357 6.94847 21.6399C6.93192 21.8441 6.97251 22.0489 7.06565 22.2312C7.25292 22.6049 7.63058 22.8404 8.04881 22.8404H10.0089C9.59104 23.3988 9.36534 24.079 9.36595 24.778C9.36595 26.5555 10.8017 28 12.5683 28C14.3348 28 15.7706 26.5555 15.7706 24.778C15.7706 24.0777 15.5396 23.3962 15.1276 22.8404H20.1558C19.738 23.3988 19.5123 24.079 19.5129 24.778C19.5129 26.5555 20.9486 28 22.7152 28C24.4818 28 25.9175 26.5555 25.9175 24.778C25.9175 24.0777 25.6865 23.3962 25.2745 22.8404H28.8108C29.4163 22.8404 29.9126 22.3442 29.9126 21.7318C29.9108 21.4382 29.7936 21.1571 29.5866 20.95C29.3796 20.7429 29.0996 20.6266 28.8077 20.6264ZM9.54385 6.52934L27.5155 6.589L25.7552 16.5063L11.6382 16.5314L9.54385 6.52934ZM12.5683 25.7704C12.0252 25.7704 11.582 25.3244 11.582 24.778C11.582 24.2316 12.0252 23.7856 12.5683 23.7856C13.1113 23.7856 13.5546 24.2316 13.5546 24.778C13.5546 25.0412 13.4506 25.2936 13.2657 25.4797C13.0807 25.6658 12.8298 25.7704 12.5683 25.7704ZM22.7152 25.7704C22.1721 25.7704 21.7289 25.3244 21.7289 24.778C21.7289 24.2316 22.1721 23.7856 22.7152 23.7856C23.2583 23.7856 23.7015 24.2316 23.7015 24.778C23.7015 25.0412 23.5976 25.2936 23.4126 25.4797C23.2276 25.6658 22.9768 25.7704 22.7152 25.7704Z" fill={color}/>
    </svg>

  )
}
/* eslint-enable */
export default Logo
