import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format } from 'utils'

const OrderSummary = (props) => {
  const { order = {} } = props

  const { total_price = 0 } = order

  const tax = total_price * 0.07

  const getLine = (label, val) => {
    return (
      <Row>
        <Col { ...col.summary_label }>
          <Text>
            {label}
          </Text>
        </Col>

        <Col { ...col.summary_price }>
          <TextSummary>
            {format.toMoney(val)} บาท
          </TextSummary>
        </Col>
      </Row>
    )
  }

  return (
    <PageView>
      <Summary>
        {getLine('รวมราคาสินค้า', total_price)}
        {getLine('ภาษีมูลค่าเพิ่ม', tax)}
        {getLine('เงินรวมทั้งสิ้น', total_price + tax)}
      </Summary>
    </PageView>
  )
}

const col = {
  summary_label: { lg: 10, md: 10, xs: 12 },
  summary_price: { lg: 14, md: 14, xs: 12 },
}

const PageView = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const Summary = styled.div`
  font-size: 16px;
  width: 100%;
  max-width: 400px;
`

const Text = styled.div`
  text-align: right;
`

const TextSummary = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${(p) => p.theme.color.green};
  text-align: right;
`

export default OrderSummary
