import React from 'react'

import Message from './Message'

const MessageBox = (props) => {
  const { size } = props

  return (
    <Message
      size={size}
      text={props.children} />
  )
}

export default MessageBox
