import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { helper } from 'utils'
import { KeyBtn, Btn } from 'components/button'
import { Col } from 'components/display'

import DisplayInfo from '../DisplayInfo'
import EditAddress from './EditAddress'

const EditUserInfo = (props) => {
  const [validate, setValidate] = useState({})

  const { loading, doc = {}, address, onEdit, onClose, onChange, onAddress } = props

  const onSave = () => {
    const valid_list = [
      'address',
      'province',
      'district',
      'tumbon',
      'postcode',
    ]

    if (doc.tax_type === 'company') {
      valid_list.push('company_name')
      valid_list.push('social_no')
    } else if (doc.tax_type === 'personal') {
      valid_list.push('name')
    }

    if (doc.branch_type === 'sub') {
      valid_list.push('branch_code')
    }

    doc.province = address.province
    doc.district = address.district
    doc.tumbon = address.tumbon
    doc.postcode = address.postcode

    const { invalid, isInvalid } = helper.checkValue(doc, valid_list)
    if (isInvalid) {
      setValidate({ ...invalid })
      return
    }

    setValidate({})
    onEdit()
  }
  console.log('validate:', validate)
  return (
    <PageView>
      <DisplayInfo>
        <EditAddress
          loading={loading}
          doc={doc}
          address={address}
          validate={validate}
          onChange={onChange}
          onAddress={onAddress} />

        <Space />
        <Row>
          <Col lg={6} md={8} xs={24} position="left-responsive">
            <KeyBtn text="บันทึก" loading={loading} onClick={onSave} />
          </Col>
          <Col lg={6} md={8} xs={24} position="right-responsive">
            <Btn text="ยกเลิก" onClick={onClose} />
          </Col>
        </Row>
      </DisplayInfo>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  padding-top: 24px;
`

export default inject('master')(observer(EditUserInfo))
