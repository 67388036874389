import styled from 'styled-components'

const Info = (props) => {
  const { label, children } = props
  return (
    <Line>
      <Label>{label}</Label>
      <Text>{children}</Text>
    </Line>
  )
}

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
`

const Label = styled.div`
  width: 160px;
`

const Text = styled.div`
  width: calc(100% - 160px);
`

export default Info
