import { runInAction } from 'mobx'
import BaseStore from './BaseStore'

import { error, http } from '../utils'
import { config } from '../config'

let state
export class Master extends BaseStore {
  constructor() {
    super()
    this.observable({
      province: [],
      province_list: [],
    })

    state = this
  }

  async getProvince() {
    if (this.toJS().province_list.length > 0) {
      return
    }


    const url = `${config.api}/v1/province`
    const resp = await http.get(url)

    const isErr = error.isRespError(resp, {
      url,
      message: resp.body.message,
    })
    if (isErr) return

    const { data } = resp.body
    error.isArray(data, { message: 'province incorrect' })

    const province_list = data.map(province => {
      const {
        province_name,
      } = province

      const district_list = province.district_list.map(district => {
        const {
          district_name,
        } = district
        return {
          name: district_name,
          value: district_name,
        }
      })

      const tumbon_list = province.tumbon_list.map(tumbon => {
        const { district_name } = tumbon
        const list = tumbon.list.map(it => {
          return {
            name: it.tumbon_name,
            value: it.tumbon_name,
            tag: it,
          }
        })
        return {
          district_name,
          tumbon_list: list,
        }
      })

      return {
        name: province_name,
        value: province_name,
        tag: {
          district_list,
          tumbon_list,
        }
      }
    })

    runInAction(() => {
      state.province = data
      state.province_list = province_list
    })
  }


}

export default new Master()
