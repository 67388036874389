import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Phnom_Penh')

export class Timer {
  get(init, format) {
    if (init && format) return moment(init, format)
    else if (init) return moment(init)

    return moment()
  }

  getDate() {
    return moment().startOf('day')
  }

  getNoMinute() {
    return moment().minute(0).second(0)
  }

  format() {
    return moment().format()
  }

  toThatDate(val, time = false) {
    const date = moment(val)
    const text_date = date.format('DD/MM/') + `${date.year() + 543}`

    const text_time = time
    ? ` ${date.format('HH:mm')}`
    : ''
    return text_date + text_time
  }

  convertToMoment(val, format) {
    try {
      if (val === '' || val === undefined || val === null) return undefined
      else if (moment.isMoment(val)) return val

      return moment(val, format)
    } catch (e) {

    }

    return undefined
  }

  getDateNoHoliday(init = moment().startOf('day'), holiday_list = []) {
    let date = init.clone()
    for (let i = 0; i < 30; i++) {
      const result = this.isHoliday(date, holiday_list)
      if (result === false) return date

      date.add(1, 'days')
    }

    return date
  }

  isHoliday(current, holiday_list = []) {
    const year = current.year()
    const monthTxt = current.format('MMMM').toLowerCase()
    const dayOfWeek = current.weekday()
    const dateTxt = current.format('DD-MM-YYYY')

    let index = holiday_list.findIndex(item => item.holiday_year === year)
    if (index < 0) return false

    const configHoliday = holiday_list[index].data
    if (configHoliday.saturday && dayOfWeek === 6) return true
    if (configHoliday.sunday && dayOfWeek === 0) return true

    const configMonth = configHoliday[monthTxt]
    index = configMonth.findIndex(item => item.date === dateTxt)

    return index >= 0
  }

  getDiffInDays(val) {

    return moment().diff(moment(val), 'days')
  }
}

export const timer = new Timer()
export default timer
