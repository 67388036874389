import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { PopupMenu } from 'components/forms'
import Loading from 'components/loading/Loading'
import { Link } from 'components/link'

const Category = (props) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(undefined)
  const [menu, setMenu] = useState(undefined)
  const {
    loading = false,
    all_list = [],
  } = props.category.toJS()

  const onVisible = (val) => setVisible(val)
  const onClose = () => setVisible(false)

  const getSubMenu = (list) => {
    return list.map((it, i) => {
      const { code, name } = it
      return (
        <Link to={`/product.list/${code}?name=${name}`} key={i}>
          <SubItem onClick={onClose}>{name}</SubItem>
        </Link>
      )
    })
  }

  const onSetMenu = (item = {}) => {
    setSelected(item.category_id)
    const sub_list = item.sub_list || []
    const cols = sub_list.map((it, i) => {
      const { code, name } = it
      const sub_item_list = it.sub_list || []
      const sub = getSubMenu(sub_item_list)

      const header = sub_item_list.length === 0 ? (
        <Link to={`/product.list/${code}?name=${name}`}>
          <HeaderItem onClick={onClose} className="link">{name}</HeaderItem>
        </Link>
      ) : (
        <HeaderItem>{name}</HeaderItem>
      )
      return (
        <Col lg={6} md={12} xs={12} key={i}>
          {header}
          {sub}
        </Col>
      )
    })

    const m = (
      <Row>
        {cols}
      </Row>
    )
    setMenu(m)
  }

  const content = all_list.map((it, i) => {
    const css = selected === it.category_id ? 'active' : ''
    const click = () => onSetMenu(it)
    return (
      <ItemName className={css} key={i} onClick={click}>
        {it.name}
      </ItemName>
    )
  })

  if (menu === undefined && all_list.length > 0) {
    onSetMenu(all_list[0])
  }

  return (
    <PageView>
      <Loading loading={loading} />
      <PopupMenu label="อุปกรณ์ทั้งหมด" placement="bottomLeft" visible={visible} onVisible={onVisible}>
        <Menu>
          <MainMenu>
            {content}
          </MainMenu>
          <ContentMenu>
            {menu}
          </ContentMenu>
        </Menu>
      </PopupMenu>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Menu = styled.div`
  display: flex;
`

const MainMenu = styled.div`
  color: black;
  background-color: #EDFBF6;
  padding: 16px;
  width: 205px;
  max-height: 500px;
  overflow: auto;
`

const ItemName = styled.div`
  padding: 8px 0px;
  cursor: pointer;

  &.active {
    color: ${(p) => p.theme.color.green};
    font-weight: 700;
  }
`

const ContentMenu = styled.div`
  color: black;
  background-color: white;
  padding: 16px;
  width: 800px;
  max-height: 500px;
  overflow: auto;

  ${(p) => p.theme.media.tablet} {
    width: 400px;
  }
`

const HeaderItem = styled.div`
  color: ${(p) => p.theme.color.green};
  font-weight: 700;
  padding-bottom: 6px;

  &.link {
    cursor: pointer;
  }
`

const SubItem = styled.div`
  color: ${(p) => p.theme.color.grey};
  padding: 4px 0px;
`

export default inject('category')(observer(Category))
