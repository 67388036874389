import styled from 'styled-components'

import HeaderInfo from './Header'
import Product from './Product'

const OrderInfo = (props) => {
  const {
    title,
    order = {},
    readOnly = false,
  } = props

  const {
    product_list = [],
  } = order

  const content = product_list.map((it, index) => {
    return (
      <Product
        index={index}
        item={it}
        readOnly={readOnly}
        key={index} />
    )
  })

  const title_content = title ? (
    <Title>
      {title}
    </Title>
  ) : undefined

  return (
    <PageView>
      {title_content}
      <HeaderInfo readOnly={readOnly} />
      <ProductList>
        {content}
      </ProductList>
    </PageView>
  )
}

const PageView = styled.div``

const Title = styled.div``

const ProductList = styled.div`
  width: 100%;
  min-height: 200px;
  background-color: white;
  padding: 8px 16px;

  border: 1px solid #AAAAAA;
  border-top-width: 0px;
`

export default OrderInfo
