import request from 'request'

export class Http {
  constructor() {
    this.httpReq = request
    this.token = undefined
  }

  setToken(auth) {
    this.token = auth
  }

  getToken() {
    return this.token
  }

  process(options, { isJson = true } = {}) {
    return new Promise(resolve => {
      request(options, async (error, response, body) => {
        if (error) {
          const res = {
            req: options,
            statusCode: 400,
            body: error,
          }
          resolve(res)
        } else {
          let data = body
          try {
            if (isJson) {
              data = JSON.parse(body)
            }
          } catch(e) {

          }
          const res = {
            req: options,
            statusCode: response.statusCode,
            body: data,
          }
          resolve(res)
        }
      })
    })
  }

  async setOption(setting, options = {}) {
    const {
      token = true,
    } = options
    if (token && this.token) {
      setting.headers['authorization'] = `Bearer ${this.token}`
    }

    return setting
  }

  async get(url, options = {}) {
    let setting = {
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    setting = await this.setOption(setting, options)
    let resp = await this.process(setting, options)
    return resp
  }

  async post(url, options = {}) {
    let setting = {
      method: 'POST',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.json),
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting, options)
    return resp
  }

  async put(url, options = {}) {
    let setting = {
      method: 'PUT',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.json),
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting, options)
    return resp
  }

  download(url, body) {
    return new Promise((callback) => {
      fetch(url, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: 'POST',
        timeout: 30000,
      }).then(resp => {
        callback({ data: resp })
      }).catch((err) => {
        callback({ data: { status: 500, statusText: 'timeout' }})
      })
    })
  }
}

export const http = new Http()
export default http
