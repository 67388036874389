import { UnorderedListOutlined, TableOutlined, BorderHorizontalOutlined } from '@ant-design/icons'
import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

import Loading from 'components/loading/Loading'
import { Meta, NoProduct, HtmlContent } from 'components/display'
import BreadcrumbView from 'pages/Breadcrumb'
import { message, product } from 'utils'

import ProductGroup from 'pages/product.list/ProductGroup'
import ProductViewGroup from 'pages/product.list/ProductViewGroup'
import ProductView from 'pages/product.list/ProductView'

const BrandProductList = (props) => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({})
  const [isList, setIsList] = useState(false)
  const [categoryList, setCategoryList] = useState([])

  const { code, brandCode } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const query = queryString.parse(location.search)
  const { name, brand, group, view } = query

  const display = props.product.toJS().display
  const { category } = display

  const getViewType = useCallback((val) => {
    switch (val) {
      case 'list':
        return 'list'
      case 'product':
        return 'product'
      default:
        return 'table'
    }
  }, [])

  useEffect(() => {
    if (loading) return

    try {
      props.product.initSelection(query)
    } catch (e) {}
  }, [props.product, loading])

  useEffect(() => {
    if (view) {
      setIsList(getViewType(view))
    } else {
      setIsList(getViewType(category.view_type))
    }
  }, [category.view_type, view, getViewType])

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)

      const { filter_brand = {} } = props.brand.toJS()
      await props.product.getListByBrandAndCategory({
        b_code: filter_brand.code,
        c_code: code,
      }, [filter_brand])

    } catch (e) {
      message.error({
        title: 'แสดงรายการหมวดหมู่',
        message: e.message,
      })
    }

    setLoading(false)
  }, [props.product, props.brand, code])

  useEffect(async () => {
    const category = await props.brand.getCategoryByCode(code, brandCode)
    const { filter_brand ={} } = props.brand.toJS()

    setContent(category)
    setCategoryList([filter_brand, category])

    onGetList()
  }, [props.category, props.brand, code, onGetList])

  const setUrl = (group, brand, list, isView) => {
    const gq = group || ''
    const bq = brand || ''
    const fq = product.buildFilterQuery(list)

    let query = ''
    if (gq !== '') {
      query += `&group=${gq}`
    }

    if (bq !== '') {
      query += `&brand=${bq}`
    }

    if (fq !== '') {
      query += `${fq}`
    }

    query += `&view=${isView}`
    navigate(`/product.list/brand/${brandCode}/${code}?name=${name}${query}`)
  }

  const onSelect = (item, name) => {
    const list = props.product.select(item, name)
    setUrl(group, brand, list)
  }

  const onSelectBrand = (item) => {
    const list = props.product.selectBrand(item)
    const q = product.buildQuery(list, 'code')

    setUrl(group, q, display.filter_list)
  }

  const onSelectGroup = (item) => {
    const list = props.product.selectGroup(item)
    const q = product.buildQuery(list, 'name')

    setUrl(q, brand, display.filter_list)
  }

  const onDisplayFilter = () => {
    const filter = document.getElementById('filter-body')
    if (filter) {
      const display = filter.style.display
      filter.style.display = display === 'block' ? 'none' : 'block'
    }
  }

  const onViewType = (val) => {
    setIsList(val)
    setUrl(group, brand, list, val)
  }

  const getBtn = (name, icon, value) => {
    return (
      <Btn className={isList === value ? 'active' : undefined} onClick={() => onViewType(value)}>
        {name} {icon}
      </Btn>
    )
  }

  const list = display.list || []
  const product_content = loading === false && list.length > 0
  ? list.map((item, index) => {
    if (isList === 'product') {
      return (
        <ProductViewGroup
          key={index}
          item={item}
          isList={isList}
          prefix_url="/product/brand" />
      )
    } else {
      return (
        <ProductGroup
          key={index}
          item={item}
          isList={isList}
          prefix_url="/product/brand" />
      )
    }
  })
  : <NoProduct />

  return (
    <PageView>
      <Meta title={`ยี่ห้อ-${content.name}`} />
      <BreadcrumbView category_list={categoryList} by_brand={true} brand_code={brandCode}/>
      <Loading loading={loading} />
      <Title>
        <Text>
          {content.name}
        </Text>

        <Button>
          {getBtn('Grid', <BorderHorizontalOutlined />, 'product')}
          {getBtn('List', <UnorderedListOutlined />, 'list')}
          {getBtn('Table', <TableOutlined />, 'table')}
        </Button>
      </Title>
      <Detail>
        <HtmlContent content={content.detail} />
      </Detail>

      <ProductView
        display={display}
        onDisplayFilter={onDisplayFilter}
        onSelectGroup={onSelectGroup}
        onSelectBrand={onSelectBrand}
        onSelect={onSelect}>
        {product_content}
      </ProductView>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
`

const Title = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Text = styled.div`
  text-align: left;
  font-weight: bold;
`

const Button = styled.div`
  font-size: 14px;
`

const Btn = styled.span`
  padding: 0px 10px;
  cursor: pointer;

  &.active {
    color: #003e31;
  }
`

const Detail = styled.div`
  padding: 0px 10px;
`

export default inject('product', 'category', 'brand')(observer(BrandProductList))
