import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Meta } from 'components/display'
import { message } from 'utils'

import Process from './Process'
import EditInfo from './EditInfo'
import EditAddress from './EditAddress'
import Consent from './Consent'

const UserRegister = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(0)
  const [consent, setConsent] = useState('')

  const navigate = useNavigate()

  const { my_address, address } = props.user.toJS()
  const { user } = props.member.toJS()
  const { customer_id } = user

  const onLoad = useCallback(async (id) => {
    try {
      setLoading(true)

      await Promise.all([
        props.consent.getTerms(),
        props.consent.getPrivacy(),
        props.user.getAddress(id),
        props.master.getProvince(),
      ])
    } catch (e) {
      message.error({
        title: 'แสดงข้อมูลผู้ใช้งาน',
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.consent])

  useEffect(() => {
    onLoad(customer_id)
  }, [onLoad, customer_id])

  const onPrivacy = () => setConsent('privacy')
  const onTerms = () => setConsent('terms')
  const onBack = () => setConsent('')
  const onUserInfo = (val, name) => {
    user[name] = val
    props.member.setUserProfile({ user })
  }

  const onUserAddress = (val) => {
    props.user.setMyAddress({ ...val })
  }

  const onAddress = (val) => {
    props.user.setAddress(val)
  }

  const onSaveInfo = async () => {
    try {
      setSaving(true)
      await props.member.uppdateInfo(user)

      setPage(1)
    } catch (e) {
      message.error({
        title: 'บันทึกข้อมูลผู้ใช้งาน',
        message: e.message,
      })
    }
    setSaving(false)
  }

  const onSaveAddress = async () => {
    try {
      setSaving(true)

      my_address.province = address.province
      my_address.district = address.district
      my_address.tumbon = address.tumbon
      my_address.postcode = address.postcode

      await props.user.uppdateAddress(my_address, true)

      setSaving(false)
      navigate('/register-completed')
    } catch (e) {
      message.error({
        title: 'บันทึกที่อยู่ผู้ใช้งาน',
        message: e.message,
      })
      setSaving(false)
    }
  }

  const getProcess = () => {
    if (consent === 'privacy' || consent === 'terms') {
      return undefined
    }

    return <Process page={page} />
  }

  const getPage = () => {
    const { privacy, terms } = props.consent.toJS()
    if (consent === 'privacy') {
      return <Consent data={privacy} onBack={onBack} />
    } else if (consent === 'terms') {
      return <Consent data={terms} onBack={onBack} />
    }

    if (page === 1) {
      return (
        <EditAddress
          loading={saving}
          doc={my_address}
          address={address}
          onBack={() => setPage(0)}
          onNext={onSaveAddress}
          onChange={onUserAddress}
          onAddress={onAddress} />
      )
    }

    return (
      <EditInfo
        loading={saving}
        doc={user}
        onNext={onSaveInfo}
        onPrivacy={onPrivacy}
        onTerms={onTerms}
        onChange={onUserInfo} />
    )
  }

  const process = getProcess()
  const content = getPage()
  return (
    <PageView>
      <Meta title="กรอกข้อมูลสมัครสมาชิก" />
      {process}

      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0px;
`

export default inject('member', 'consent', 'user', 'master')(observer(UserRegister))
