import styled from 'styled-components'
import { Btn } from 'components/button'

const Title = (props) => {
  const { title, btn, onClick } = props

  const content = btn ? (
    <Button>
      <Btn text={btn} onClick={onClick} />
    </Button>
  ) : undefined
  return (
    <Line>
      <Text>{title}</Text>
      {content}
    </Line>
  )
}

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Text = styled.div`
  width: auto;
`

const Button = styled.div`
  width: 214px;
`

export default Title
