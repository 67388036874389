import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Contact = (props) => {
  const navigate = useNavigate()

  const goTo = (url) => {
    const src = window.location.pathname
    const search = window.location.search || ''

    if (src !== url) {
      navigate(`${url}?source=${src}${search}`)
    }
  }

  const onBlog = () => goTo(`/blog`)
  const onOrder = () => goTo(`/how-to-order`)
  const onContact= () => goTo(`/contact-us`)

  return (
    <Page>
      <Title>ช่วยเหลือ</Title>
      <Btn onClick={onBlog}>บทความ</Btn>
      <Btn onClick={onOrder}>วิธีการช้อปออนไลน์</Btn>
      <Btn onClick={onContact}>ติดต่อเรา</Btn>
    </Page>
  )
}

const Page = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
`

const Btn = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
`

export default Contact
