import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { message } from 'utils'
import { Meta, Col } from 'components/display'
import Loading from 'components/loading/Loading'

import Menu from '../Menu'
import Title from '../Title'
import col from '../col'
import Info from './Info'

const Order = (props) => {
  const [loading, setLoading] = useState(false)

  const onGetList = useCallback(async (index = 0) => {
    try {
      setLoading(true)
      await props.order.getOrderList({ index })
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }, [props.order])

  useEffect(() => {
    onGetList()
  }, [onGetList])


  const { my_history = {} } = props.order.toJS()

  return (
    <PageView>
      <Meta title="ประวัติสั่งซื้อ" />
      <Row>
        <Col { ...col.menu}>
          <Menu selected="order" />
        </Col>
        <Col { ...col.content}>
          <Title title="ประวัติสั่งซื้อ" />
          <Loading loading={loading}>
            <Info list={my_history.list} />
          </Loading>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0px;
`

export default inject('order')(observer(Order))
