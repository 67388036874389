import React from 'react'
import styled from 'styled-components'

const RemoveBtn = (props) => {
  const {
    onClick
  } = props

  return (
    <PageView onClick={onClick}>
      ลบ
    </PageView>
  )
}

const PageView = styled.div`
  border-radius: 5px;
  color: black;
  background-color: #FB8B8B;
  border: 1px solid #FB8B8B;
  
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;

  &:focus, &:hover {
    color: #FB8B8B;
    background-color: white;
    border: 1px solid #FB8B8B;
  }
`

export default RemoveBtn
