import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import Loading from 'components/loading/Loading'
import { Link } from 'components/link'
import { color } from 'definition'

const Category = (props) => {
  const {
    loading = false,
    all_list = [],
  } = props.category.toJS()

  const content = all_list.map((it, index) => {
    const {
      code,
      name,
      img_url,
      sub_list = [],
    } = it
    const link = sub_list.length === 0
    ? `/product.list/${code}?name=${name}`
    : `/sub.category/${code}?name=${name}`

    return (
      <Col key={index} xl={3} lg={4} md={6} xs={8}>
        <Link to={link}>
          <Content>
            <Img src={img_url} />
            <Name>
              {name}
            </Name>
          </Content>
        </Link>
      </Col>
    )
  })
  return (
    <PageView>
      <Title>ประเภทสินค้า</Title>
      <Loading loading={loading} />
      <Row>
        {content}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding-bottom: 10px;
`

const Title = styled.h2`
  text-align: left;
  font-weight: bold;
`

const Content = styled.div`
  color: black;
  background-color: white;
  padding: 10px;
  border: 1px solid ${color.border};
  cursor: pointer;
  text-align: center;
  height: 100%;

  :hover {
    border: 2px solid ${color.bg};
    padding: 9px;
  }
`

const Img = styled.img`
  width: 100%;
  min-height: 60px;
`

const Name = styled.div`
  margin-top: 5px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 44px;
`

export default inject('category')(observer(Category))
