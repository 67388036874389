import { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { KeyBtn } from 'components/button'
import { format } from 'utils'
import { ConfirmDialog } from 'dialog'

const Summary = (props) => {
  const [isConfirm, setIsConfirm] = useState(false)

  const { loading, isLogin, order = {}, onCheckout } = props

  const {
    total_price = 0,
    quantity = 0,
  } = order

  const onClose = () => setIsConfirm(false)
  const onConfirm = () => setIsConfirm(true)
  const createOrder = () => {
    onClose()
    onCheckout()
  }

  const tax = total_price * 0.07

  const remark = isLogin ? undefined : <Remark>กรุณาเข้าสู่ระบบเพื่อดำเนินการต่อ</Remark>
  return (
    <PageView>
      <Title>สรุปการสั่งซื้อ</Title>

      <ProductList>
        <Row>
          <Col { ...col.text }>
            <Text>รวมราคาสินค้า ({format.toDigi(quantity)})</Text>
          </Col>

          <Col { ...col.value }>
            <Line>
              <Value>
                {format.toMoney(total_price)} บาท
              </Value>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col { ...col.text }>
            <Text>ภาษีมูลค่าเพิ่ม</Text>
          </Col>

          <Col { ...col.value }>
            <Line>
              <Value>
                {format.toMoney(tax)} บาท
              </Value>
            </Line>
          </Col>
        </Row>
      </ProductList>

      <Note>
      ** ใบเสนอราคานี้ยังไม่สามารถอ้างอิงราคาได้โปรด นำเลขที่ใบเสนอราคานี้มาติดต่อที่ไลน์: @PRATHAN เพื่อทำใบเสนอราคาพร้อมเช็คสต็อกก่อนทำการสั่งซื้อ
      </Note>

      <Row>
        <Col { ...col.text }>
          <TextSummary>
            ยอดรวมทั้งสิ้น
          </TextSummary>
        </Col>
        <Col { ...col.value }>
          <ValueSummary>
            {format.toMoney(total_price + tax)} บาท
          </ValueSummary>
        </Col>
      </Row>
      <Space />
      <KeyBtn
        loading={loading}
        disabled={!isLogin}
        text="สร้างใบเสนอราคา"
        onClick={onConfirm} />
      {remark}
      <ConfirmDialog
        visible={isConfirm}
        message="คุณต้องการที่จะสร้างใบเสนอราคา ใช่หรือไม่?"
        onClose={onClose}
        onConfirm={createOrder} />
    </PageView>
  )
}

const col = {
  text: { lg: 10, md: 10, xs: 10 },
  value: { lg: 14, md: 14, xs: 14 },
}

const PageView = styled.div`
  padding: 16px;
  border: 1px solid #AAAAAA;
  background-color: white;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
`

const ProductList = styled.div`
  padding: 16px 0px;
`

const Line = styled.div`
  padding-bottom: 6px;
`

const Note = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 16px;
`

const Remark = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color.green};
  font-size: 14px;
  margin-top: 16px;
`

const Text = styled.div`
  width: 100%;
  font-weight: bold;
`

const Value = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: right;
`

const TextSummary = styled.div`
  width: 100%;
  font-weight: bold;
`

const ValueSummary = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${(p) => p.theme.color.green};
  text-align: right;
`

const Space = styled.div`
  padding-top: 16px;
`

export default Summary
