import { Button } from 'antd'
import styled from 'styled-components'

import { color } from 'definition'

const DownloadBtn = (props) => {
  const {
    disabled,
    loading,
    icon,
    text,
    onClick,
  } = props

  return (
    <PageView>
      <Button
        disabled={disabled}
        loading={loading}
        onClick={onClick}>
        <span>{icon}</span>
        {text}
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  button, button:focus {
    width: 100%;
    height: 48px;
    color: ${color.font};
    background-color: ${color.bg};
    border: 1px solid ${color.border};
    border-radius: 6px;
  }

  button:hover {
    color: ${color.bg};
    background-color: ${color.font};
    border: 1px solid ${color.border};
  }

  span {
    margin-right: 5px;
  }
`

export default DownloadBtn
