import React from 'react'
import { Form } from 'antd'
import styled from 'styled-components'

const FormItem = (props) => {
  let {
    label,
    children,
    status = '',
    help,
    hasFeedback,
    error,
    extra,
  } = props

  if (label === undefined) label = ' '
  let text
  if (status) text = error

  return (
    <PageView>
      <Form.Item
        colon={false}
        label={label}
        validateStatus={status === true ? 'error' : status}
        help={text || help}
        hasFeedback={hasFeedback}
        extra={extra}>
        {children}
      </Form.Item>
    </PageView>
  )
}

const PageView = styled.div`

`

export default FormItem
