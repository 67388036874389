import styled from 'styled-components'

import { Radio } from 'icon'

import Invalid from './Invalid'

const RadioInput = (props) => {
  const {
    name,
    label,
    value = '',
    tag,
    gap,
    menu = [],
    onChange = () => {},
    invalid,
  } = props

  const onValue = (val) => {
    onChange(val, name, tag)
  }

  const content = menu.map((it, i) => {
    const color = it.value === value ? undefined : 'white'
    return (
      <Btn onClick={() => onValue(it.value)} key={i}>
        <Radio color={color} />
        <label>{it.name}</label>
      </Btn>
    )
  })

  const title = label ? <Title>{label}</Title> : undefined
  const style = gap ? { columnGap: `${gap}px` } : undefined
  return (
    <>
      <PageView>
        {title}
        <ContentView style={style}>{content}</ContentView>
      </PageView>
      <Invalid invalid={invalid} />
    </>
  )
}

const PageView = styled.div`
  display: flex;
  align-items: center;
`

const ContentView = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  label {
    margin-left: 15px;
  }

  svg {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
    border-radius: 50%;
  }
`

const Title = styled.span`
  margin-right: 20px;
`

export default RadioInput
