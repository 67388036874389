const col = {
  seq: { lg: 1, md: 2, sm: 2, xs: 3 },
  name: { lg: 10, md: 6, sm: 6, xs: 14 },
  quantity: { lg: 2, md: 3, sm: 3, xs: 6 },
  unit: { lg: 2, md: 2, sm: 3, xs: 4 },
  price: { lg: 3, md: 3, sm: 4, xs: 6 },
  discount: { lg: 2, md: 3, sm: 4, xs: 6 },
  total_price: { lg: 4, md: 5, sm: 6, xs: 6 },
}

export default col
