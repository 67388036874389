import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Input } from 'antd'
import styled from 'styled-components'

import Block from './Block'

const Filter = (props) => {
  const [filter, setFilter] = useState('')
  const {
    filter_list = [],
    onSelect,

    brand_list = [],
    onSelectBrand,

    category_list,
    onSelectCategory,

    group_list = [],
    onSelectGroup,
  } = props

  const onSearch = (evt) => {
    setFilter(evt.target.value)
  }

  const brand = props.brand.toJS()
  const category = props.category.toJS()

  const text = filter.toLowerCase()

  const group_content = onSelectBrand
  ? (
    <Block
      title="กลุ่มสินค้า"
      text={text}
      list={group_list}
      onSelect={onSelectGroup} />
  ) : undefined

  const brand_content = onSelectBrand
  ? (
    <Block
      title="ยี่ห้อ"
      text={text}
      loading={brand.loading}
      list={brand_list}
      onSelect={onSelectBrand} />
  ) : undefined

  const category_content = onSelectCategory
  ? (
    <Block
      title="ประเภทสินค้า"
      text={text}
      loading={category.loading}
      list={category_list}
      onSelect={onSelectCategory} />
  ) : undefined

  const content = filter_list.map((it, i) => {
    return (
      <Block
        key={i}
        title={it.title}
        text={text}
        list={it.list}
        tag={it.name}
        onSelect={onSelect} />
    )
  })

  return (
    <PageView>
      <Input
        placeholder="ค้นหาตัวกรอก"
        allowClear
        onChange={onSearch}
        value={filter}
      />

      {group_content}
      {brand_content}
      {category_content}

      {content}
    </PageView>
  )
}

const PageView = styled.div`
  padding-right: 10px;
  margin-bottom: 20px;

  input {
    text-indent: 4px;
  }
`

export default inject('brand', 'category')(observer(Filter))
