import styled from 'styled-components'
import { Modal } from 'antd'

import { Close } from 'icon'

const Dialog = (props) => {
  const { visible, children, onClose } = props

  return (
    <Modal
      title=""
      footer=""
      visible={visible}
      className="order-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%" >
      <PageView>
        <PageArea>
          <Header>
            <Title>รายการสินค้า</Title>
            <span onClick={onClose}><Close /></span>
          </Header>
          {children}
        </PageArea>
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 150px;

  ${(p) => p.theme.media.pc} {
    padding-top: 100px;
  }

  ${(p) => p.theme.media.tablet} {
    padding-top: 60px;
  }

  ${(p) => p.theme.media.mobile} {
    padding-top: 40px;
  }
`

const PageArea = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  ${(p) => p.theme.media.pc} {
    max-width: 90%;
  }

  ${(p) => p.theme.media.tablet} {
    max-width: 90%;
  }

  ${(p) => p.theme.media.mobile} {
    max-width: 90%;
  }
`

const Header = styled.div`
  padding: 24px;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  font-size: 20px;
  margin: 0 auto;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`

const Title = styled.div`
  width: 100%;
`

export default Dialog
