import styled from 'styled-components'

import HeaderInfo from './Header'
import Product from './Product'

const OrderInfo = (props) => {
  const { order = {} } = props

  const {
    product_list = [],
  } = order

  const last = product_list.length - 1
  const content = product_list.map((it, index) => {
    return (
      <Product
        index={index}
        item={it}
        key={index}
        isLast={index === last} />
    )
  })

  return (
    <PageView>
      <HeaderInfo />
      <ProductList>
        {content}
      </ProductList>
    </PageView>
  )
}

const PageView = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border: 0px solid #AAAAAA;
  border-bottom-width: 1px;
`

const ProductList = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
`

export default OrderInfo
