import styled from 'styled-components'
import { Row, Col } from 'antd'

import { color } from 'definition'

import col from './col'

const ProductHeader = (props) => {
  return (
    <PageView>
      <Row>
        <Col { ...col.name}>
          <Name>ชื่อสินค้า</Name>
        </Col>

        <Col { ...col.brand}>
          ยี่ห้อ
        </Col>

        <Col { ...col.price}>
          <Price>ราคา</Price>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
  border-radius: 5px;
  color: white;
  background-color: ${ color.green };
  font-size: 13px;
`

const Name = styled.div`
  padding-left: 12px;
`

const Price = styled.div`
  @media screen and (max-width: 500px) {
    padding-left: 12px;
  }
`

export default ProductHeader
