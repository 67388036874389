import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import BaseStore from './BaseStore'

import member from './Member'
import { error, http } from '../utils'
import { config } from '../config'

const original = {
  company: {
    tax_type: 'company',
    social_no: '',

    company_name: '',
    name: '',
    branch_type: 'main',
    branch_code: '',
  },
  main_address: {
    house_no: '',
    village_name: '',
    address: '',
  },
  address: {
    address_id: undefined,
    tumbon: '',
    district: '',
    province: '',
    postcode: '',
    mobile: '',
  }
}

let state
export class User extends BaseStore {
  constructor() {
    super()
    this.observable({
      address: cloneDeep(original.address),
      my_address: cloneDeep(original.address),
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.address = cloneDeep(original.address)
      state.my_address = cloneDeep(original.address)
    })
  }

  toAddress(data = {}) {
    const company = {
      tax_type: data.tax_type,
      social_no: data.social_no,

      company_name: data.company_name,
      name: data.name,
      branch_type: data.branch_type,
      branch_code: data.branch_code,
    }

    const main_address = {
      house_no: data.house_no,
      village_name: data.village_name,
      address: data.address,
    }

    const address = {
      address_id: data.address_id,
      tumbon: data.tumbon,
      district: data.district,
      province: data.province,
      postcode: data.postcode,
      mobile: data.mobile,
    }

    return {
      company,
      main_address,
      address
    }
  }

  async getAddress(id) {
    if (id) {
      const url = `${config.api}/v1/customer/user/address/${id}`
      const resp = await http.get(url)

      const isErr = error.isRespError(resp, {
        url,
        message: resp.body.message,
      })
      if (isErr) return

      const { data } = resp.body

      const app = this.toAddress(data)
      runInAction(() => {
        state.address = app.address
        state.my_address = data
      })
    } else {
      this.reset()
    }
  }

  setAddress(doc) {
    runInAction(() => {
      state.address = doc
    })
  }

  setMyAddress(doc) {
    runInAction(() => {
      state.my_address = doc
    })
  }

  async uppdateAddress(params = {}, isCompleted) {
    const url = isCompleted ? `${config.api}/v1/customer/user/address/completed` : `${config.api}/v1/customer/user/address`
    const resp = await http.put(url, { json: params })

    error.isRespError(resp, {
      url,
      message: resp.body.message,
    })

    const data = resp.body.data
    const { detail, address } = data

    error.isNull(detail.customer_id, { message: 'ข้อมูลลูกค้าไม่ถูกต้อง' })
    error.isNull(address.address_id, { message: 'ที่อยู่ไม่ถูกต้อง' })

    const app = this.toAddress(address)
    runInAction(() => {
      state.address = app.address
      state.my_address = address
    })
    member.setUserProfile({ user: detail })
  }
}

export default new User()
