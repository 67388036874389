import styled from 'styled-components'

const Invalid = (props) => {
  const { invalid } = props

  const invalidCss = invalid ? 'invalid' : ''

  return <View className={invalidCss}>{invalid || ''}</View>
}

const View = styled.div`
  color: ${(p) => p.theme.color.error};
  margin-top: 4px;
  font-size: 14px;
  text-align: left;
  display: none;

  &.invalid {
    display: block;
  }
`

export default Invalid
