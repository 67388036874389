import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import Loading from 'components/loading/Loading'
import { Link } from 'components/link'
import { Down, Up } from 'icon'
import { helper } from 'utils'

const Start = 24

const Brand = (props) => {
  const [isAll, setIsAll] = useState(false)
  const [index, setIndex] = useState(Start)

  const {
    loading = false,
    all_list = [],
  } = props.brand.toJS()

  const len = all_list.length

  const onOpen = async () => {
    setIsAll(true)
    if (index === Start) {
      for (let i = Start; i < len + 1; i += 2) {
        setIndex(i + 1)
        await helper.sleep(40)
      }
    }
  }

  const onClose = () => setIsAll(false)

  const displayed_brands = !isAll && len > Start
  ? all_list.slice(0, Start)
  : all_list

  const content = displayed_brands.map((it, i) => {
    const {
      code,
      name,
      img_url,
    } = it

    const isDisplay = i <= index
    const c = isDisplay ? <Img src={img_url} alt={name} /> : <Img src="https://pecom.sgp1.digitaloceanspaces.com/img/brand/default-brand.jpg" />
    return (
      <Col key={i} xl={2} lg={3} md={4} xs={6}>
        <Link to={`/brand.category/${code}?name=${name}`}>
          <Content>
            {c}
          </Content>
        </Link>
      </Col>
    )
  })

  const btn = !isAll
    ? <Btn onClick={onOpen}>ดูยี่ห้อทั้งหมด<Down size={0.8} color="#003e31" /></Btn>
    : <Btn onClick={onClose}>ปิดการแสดง<Up size={0.8} color="#003e31" /></Btn>
  return (
    <PageView>
      <Title>ยี่ห้อสินค้า</Title>
      <Loading loading={loading} />
      <Body>
        <Row>
          {content}
        </Row>
        {btn}
      </Body>
    </PageView>
  )
}

const PageView = styled.div`
  padding-top: 20px;
`

const Title = styled.h2`
  padding: 2px;
  text-align: left;
  font-weight: bold;
`

const Body = styled.div`
  background-color: white;
  border-radius: 2px;
`

const Content = styled.div`
  color: black;
  background-color: white;
  padding: 10px;
  margin: 2px;
  cursor: pointer;
  text-align: center;
  height: 99%;
`

const Img = styled.img`
  width: 100%;
  min-height: 60px;
`

const Btn = styled.div`
  width: 140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px 0px;
  font-weight: 700;
  color: ${(p) => p.theme.color.green};
  svg {
    margin-left: 4px;
  }
`

export default inject('brand')(observer(Brand))
