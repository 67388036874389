import styled from 'styled-components'
import { Row, Col } from 'antd'

import { FilterBtn } from 'components/button'
import FilterProduct from 'pages/filter'

const ProductView = (props) => {
  const {
    display = {},
    onDisplayFilter,
    onSelectGroup,
    onSelectBrand,
    onSelect,
    children
  } = props

  return (
    <PageView>
      <Row>
        <Col xl={5} lg={7} md={6} xs={24}>
          <Filter>
            <FilterBtn
              className="filter-btn"
              onClick={onDisplayFilter} />

            <div id="filter-body" >
              <FilterProduct
                filter_list={display.filter_list}
                onSelect={onSelect}

                group_list={display.group_list}
                onSelectGroup={onSelectGroup}

                brand_list={display.brand_list}
                onSelectBrand={onSelectBrand} />
            </div>
          </Filter>
        </Col>

        <Col xl={19} lg={17} md={18} xs={24}>
          {children}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 0px;
`

const Filter = styled.div`
  .filter-btn {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .filter-btn {
      display: block;
      margin-bottom: 10px;
    }

    #filter-body {
      display: none;
    }
  }
`

export default ProductView
