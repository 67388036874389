import styled from 'styled-components'

import Section from './Section'

const Suggestion = (props) => {
  const { data = {} } = props

  const {
    suggestion_list = [],
    replacement_list = [],
    pair_list = [],
  } = data

  return (
    <PageView>
      <Section id="suggestion" title="สินค้าที่เกี่ยวข้อง" list={suggestion_list} />
      <Section id="replacement" title="สินค้าทดแทน" list={replacement_list} />
      <Section id="pair" title="สินค้าที่ใช้คู่กัน" list={pair_list} />
    </PageView>
  )
}

const PageView = styled.div`
  padding-top: 24px;
  padding-right: 8px;

  @media screen and (max-width: 500px) {
    padding-right: 0px;
  }
`

export default Suggestion
