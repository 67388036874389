import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'

import DisplayInfo from '../DisplayInfo'
import OrderItem from './OrderItem'
import col from './col'

const Info = (props) => {
  const { list = [] } = props

  const content = list.map((it, i) => {
    return <OrderItem key={i} item={it} />
  })

  return (
    <DisplayInfo>
      <Header>
        <Row>
          <Col { ...col.code }>
            เลขที่ใบเสนอราคา
          </Col>
          <Col { ...col.date }>
           วันที่
          </Col>
          <Col { ...col.quantity }>
            จำนวนสินค้า
          </Col>
          <Col { ...col.remark }>
            บันทึกช่วยจำ
          </Col>
          <Col { ...col.price }>
            ราคารวม
          </Col>
        </Row>
      </Header>

      {content}
    </DisplayInfo>
  )
}

const Header = styled.div`
  width: 100%;
  color: #6C6C6C;
  padding-bottom: 8px;
`

export default Info
