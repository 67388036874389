import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import LoginPage from './pages/access/login'
import UserInfo from './pages/user/information/user'
import UserAddress from './pages/user/information/address'
import UserPassword from './pages/user/information/password'
import UserOrder from './pages/user/information/order'
import UserRegister from './pages/user/register'
import Order from './pages/user/order'

const App = (props) => {
  const { user } = props.member.toJS()
  if (user.uid === '') {
    return (
      <Routes>
        <Route path="/*" element={<LoginPage />} />
      </Routes>
    )
  }

  if (!user.tax_address_id) {
    return (
      <Routes>
        <Route path="/*" element={<UserRegister />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/information" element={<UserInfo />} />
      <Route path="/address" element={<UserAddress />} />
      <Route path="/account" element={<UserPassword />} />
      <Route path="/order" element={<UserOrder />} />
      <Route path="/order/:code" element={<Order />} />
    </Routes>
  )
}

export default inject('member')(observer(App))
