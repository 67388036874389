import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Meta } from 'components/display'
import { Link } from 'components/link'

const RegisterCompleted = (props) => {
  const { user } = props.member.toJS()

  return (
    <PageView>
      <Meta title="สมัครสมาชิกเรียบร้อยแล้ว" />
      <Title>คุณ {user.name} ได้สมัครสมาชิกเรียบร้อยแล้ว</Title>
      <LinkText>
        <Link to="/">กลับไปที่หน้าหลัก</Link>
      </LinkText>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0px;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
  font-size: 18px;
`

const LinkText = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
  font-weight: 700;
  color: ${(p) => p.theme.color.green};
`

export default inject('member')(observer(RegisterCompleted))
