import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'antd'

import Loading from 'components/loading/Loading'
import { Link } from 'components/link'
import { Meta, HtmlContent } from 'components/display'
import BreadcrumbView from 'pages/Breadcrumb'
import { color } from 'definition'
import { format } from 'utils'

const SubCategory = (props) => {
  const [content, setContent] = useState({})
  const [categoryList, setCategoryList] = useState([])
  const [subContent, setSubContent] = useState([])

  const { code } = useParams()
  const {
    loading = false,
  } = props.category.toJS()

  useEffect(() => {
    let sub_content = []
    let find_category
    const {
      all_list = [],
    } = props.category.toJS()

    find_category = props.category.findCategoryList(code, all_list)

    if (find_category) {
      const { sub_list = [] } = find_category.category || {}
      sub_content = sub_list.map((it, index) => {
        const tmp = it.sub_list || []
        const {
          code,
          name,
        } = it
        const link = tmp.length === 0
          ? `/product.list/${code}?name=${name}`
          : `/sub.category/${code}?name=${name}`
        return (
          <Col key={index} xl={4} lg={6} md={8} xs={12}>
            <Link to={link}>
              <Category>
                <Img src={it.img_url} />
                <Name>
                  {format.limitText(name, 50)}
                </Name>
              </Category>
            </Link>
          </Col>
        )
      })
    }

    const { category = {}, original_list = [] } = find_category
    setContent(category)
    setSubContent(sub_content)
    setCategoryList(original_list)
  }, [props.category, code, loading])

  return (
    <PageView>
      <Meta loading={loading} title={content.name} />
      <Loading loading={loading} />
      <BreadcrumbView category_list={categoryList} />
      <Title>
        {content.name}
      </Title>
      <Detail>
        <HtmlContent content={content.detail} />
      </Detail>
      <Line />
      <Row>
        {subContent}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
`

const Title = styled.h2`
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Category = styled.div`
  color: black;
  background-color: white;
  padding: 20px 10px;
  border: 1px solid ${color.border};
  cursor: pointer;
  text-align: center;
  height: 100%;

  :hover {
    border: 2px solid ${color.bg};
    padding: 19px 9px;
  }
`

const Img = styled.img`
  width: 100%;
  min-height: 60px;
`

const Name = styled.div`
  margin-top: 5px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
`
const Detail = styled.div`
`

const Line = styled.div`
  border: 0px solid ${color.border};
  border-bottom-width: 1px;
  margin: 20px 0px;
`

export default inject('category')(observer(SubCategory))
