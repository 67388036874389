import { timer } from 'utils'

const Header = (props) => {
  const { order = {}, size } = props
  const font = size === 'xl' ? FontSizeXL : FontSize
  const { created_at, order_code } = order

  const toText = (val) => {
    return val ? val : ''
  }

  const getAddress = () => {
    const {
      address,
      district,
      tumbon,
      province,
      postcode,
    } = order.address

    let txt = toText(address)
    txt += ` ${toText(tumbon)}`
    txt += ` ${toText(district)}`
    txt += ` ${toText(province)}`
    txt += ` ${toText(postcode)}`
    return txt
  }

  const text_date = timer.toThatDate(created_at)

  const {
    tax_type,
    branch_type,
    branch_code,
    company_name ,
    name,
    surname,
    social_no,
    mobile,
  } = order.address
  let customer_name = ``
  let info = getAddress()
  let branch = ''
  if (tax_type === 'personal') {
    customer_name = `${ toText(name) } ${ toText(surname) }`
  } else {
    customer_name = toText(company_name)
    branch = branch_type === 'main'
    ? `[X] สำนักงานใหญ่ [ ] สาขา`
    : `[ ] สำนักงานใหญ่ [X] สาขา ${ toText(branch_code) }`
  }

  const mobile_as_text = toText(mobile)
  //const p = toText(phone)
  const text_phone = mobile_as_text === ''
  ? `-`
  : `${ mobile_as_text }`

  const css_left = { fontSize: font.normal, width: '100%' }
  const css_right = { fontSize: font.normal, marginRight: '15px', float:'right' }
  const css_header_left = { width: '100%' }
  const css_header_right = { marginRight: '15px', float:'right' }

  return (
    <div
      className='header-pdf'
      style={{
        fontFamily: 'Arial',
        fontSize: font.normal,
        color: '#000000',
        width: '100%',

        display: 'block',
        marginLeft: '10px',
      }}
    >
      <div style={ css_header_right }>
        <span className='pageNumber' /> of <span className='totalPages' />
      </div>

      <div style={{ fontSize: font.normal, fontWeight: 'bold', width: '100%' }}>
        บริษัท ประธานการไฟฟ้า จำกัด (สำนักงานใหญ่)
      </div>

      <div style={ css_header_left }>
        329,331 ถ.พระรามที่ 2 แขวงแสมดำ เขตบางขุนเทียน กทม. 10150 (ปากซอยพระราม2 67)
      </div>

      <div style={ css_header_left }>
        เลขประจำตัวผู้เสียภาษีอากร 0105544005213
      </div>

      <div style={ css_header_left }>
        โทร.02-892-9389 //แฟ็กซ์ 02-892-7947 //ID LINE: @PRATHAN //E-mail: sales@prathan.co.th
      </div>

      <div
        style={{
          fontSize: font.xl,
          fontWeight: 'bold',
          width: '100%',
          marginTop: '8px',
          marginBottom: '8px',
          textAlign: 'center'
        }}>
        ใบเสนอราคา
      </div>

      <div style={ css_right }>เลขที่: { order_code }</div>
      <div style={ css_left }>ลูกค้า: { customer_name }</div>

      <div style={ css_right }>วันที่: { text_date }</div>
      <div style={ css_left }>ที่อยู่: { info }</div>

      <div style={ css_right }>เงื่อนไขการชำระ: <b>เงินสด</b></div>
      <div style={ css_left }>เบอร์โทร: { text_phone }</div>

      <div style={ css_left  }>เลขประจำตัวผู้เสียภาษี { toText(social_no) } { branch }</div>
    </div>
  )
}

const FontSize = {
  small: '',
  normal: '9px',
  xl: '14px',
}

const FontSizeXL = {
  small: '',
  normal: '11px',
  xl: '14px',
}

export default Header
