import './assets'

import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'

import ScrollToTop from 'components/ScrollToTop'
import theme from 'theme'
import store from 'stores'

import App from './App'

ReactDOM.render((
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  </Provider>
), document.getElementById('root'))
