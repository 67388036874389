import React from 'react'
import styled from 'styled-components'

const LineBtn = (props) => {
  return (
    <Line>
      {props.children}
    </Line>
  )
}

const Line = styled.div`
  display: inline-flex;
  div {
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`

export default LineBtn
