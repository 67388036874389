const col = {
  name:         { lg: 10, md: 24, xs: 24 },
  quantity:     { lg: 3, md: 12, xs: 12 },
  price:        { lg: 3, md: 8, xs: 9 },
  total_price:  { lg: 3, md: 8, xs: 9 },
  unit:         { lg: 3, md: 12, xs: 12 },
  discount:     { lg: 2, md: 8, xs: 6 },
}

export default col
